import { useFormik } from "formik";
import {
  BackButton,
  CancelButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import * as yup from "yup";
import useAddFormData from "../../../../Components/Hooks/useAddFormData";
import { useEffect, useState } from "react";
import Input, {
  NuDataTime,
  NuDate,
  NuInput,
  NuSelect,
  NuTextArea,
  NuUpload,
} from "../../../../Components/Utils/Input";
import FormikSelectTwo from "../../../../Components/Utils/Select";
import axiosInstance from "../../../../Services/axiosCommon";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import { useParams } from "react-router-dom";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import DropDownAddMetaData from "../../../../Components/Utils/selectAddMetaData";

const AddReceivableAmount = () =>{
  const {value:PaymentMethod,getData:refPaymentMethod } = useFetchMataData(`/dropdown/dropdown-details/`,'PaymentMethod_Recevable')
  // const [loading, setLoading] = useState(false);

  const { amountReceivableId } = useParams();
//   console.log("ddd",amountPayableId);
  const currentDate = new Date().toISOString().split('T')[0];
  const { Oncancel, addFormData, isLoading } = useAddFormData();
  const [createNew, setCreateNew] = useState({
    receivedAmount: "",
    paymentMethod: "Online",
    receivedOn: currentDate || "",
    comments: "",
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      // setLoading(true)
      addFormData(`/receivable/receive-amount/${amountReceivableId}`, value, resetForm);
      // setLoading(true)
    },
  });

    return(
        <div className="w-full h-full px-4 py-2 bg-[#F1F2FB]">
      <div className="flex justify-between">
      <Breadcrumb
         items={
          [
            { name: "Payment",
            link: "/payments/receivables" },  
          {
            name: "Payable",
            link: "/payments/receivables"
          },
          {
            name: "View Receivables",
            // link: `/payments/payables/edit/${amountPayableId}`
          },
          {
            name: "Add Receivable Amount"
          },
        ]
         }
        />
        <BackButton />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-150px)] bg-white rounded-lg overflow-hidden mt-4">
          <div className=" w-full h-full flex py-2 justify-start content-start flex-wrap  overflow-y-auto">
            <NuInput
              type="number"
              label="Received Amount"
              placeholder="Received Amount"
              formik={formik}
              name="receivedAmount"
              width="w-full md:w-1/2 lg:w-1/3"
            />
              <DropDownAddMetaData
              label="Payment Method"
              placeholder="Choose"
              Options={PaymentMethod}
              formik={formik}
              name="paymentMethod"
              width="w-full md:w-1/2 lg:w-1/3"
              getFunc={refPaymentMethod}
              displayProperty={"Payment Method"}
              propertyName={"PaymentMethod_Recevable"}
            />
            {/* <FormikSelectTwo
              label="Payment Method"
              placeholder="Choose"
              Options={[
                {value:"Online",label:"Online"},
                {value:"Offline",label:"Offline"},
              ]}
              // defValue={"online"}
              formik={formik}
              name="paymentMethod"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
            <NuDataTime
              label="Received On"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="receivedOn"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuTextArea
              label="Comments"
              placeholder="comments"
              formik={formik}
              name="comments"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton
                  handleClick={() => {
                    Oncancel(-1);
                  }}
                />
                <SubmitButton isLoading={isLoading}/>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    )
}

export default AddReceivableAmount;