

import React, { useState, useEffect } from 'react';
import FormikSelectTwo from '../../../../../Components/Utils/Select';
import { TextMediumSM } from '../../../../../Components/Text/MediumText';
import PieCharts from '../../../../../Components/Charts/PieChart';
import colorCodes from '../../../../../Components/Utils/ColorCode';
import UseGetDashBoardData from '../../../../../Components/Hooks/useDashboardData';
import UseGetDashBoardWithParams from '../../../../../Components/Hooks/useDashboardDataParams';
import capitalizeFirstLetter from '../../../../../Components/Utils/Funcs';
import { Sector } from 'recharts';

function QuotesSubmittedByProcurmentTeamProDash() {
  const [params, setParams] = useState({
    period:"monthly",

  });

  const [selectedPeriod, setSelectedPeriod] = useState( 'Monthly');

  const { data, loading } = UseGetDashBoardWithParams(`/dashboard/QuotesSubmittedByProcurement`,params);
 
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (data?.customer_request_salesman_wise) {
      const formattedData = data?.customer_request_salesman_wise.map(item => ({
        name: item.procurementMember,
        assign:item.assigned,
        value: item.submitted,
      }));
      setChartData(formattedData);
    }
  }, [data]);

  const hanChange=(e)=>{
    setParams({
      period:e.target.value
    })
    setSelectedPeriod(e.target.value)
  }

  const periods = [
    { value: 'monthly', option: 'Monthly' },
    { value: 'quarterly', option: 'Quarterly' },
    { value: 'halfyearly', option: 'Half Yearly' },
    { value: 'yearly', option: 'Yearly' },
  ];

  return (
    <div className="col-span-2 bg-white h-[40vh] w-full p-4  rounded-lg drop-shadow-lg mb-4">
          <div className="flex justify-between items-center mb-1">
      <p className="text-xs 2xl:text-sm text-[#4F4768] font-medium">Quotes Submitted </p>
          <div className="flex items-center">
            <select
              value={selectedPeriod}
              onChange={hanChange}
              className="border rounded-md p-1 text-xs text-[#ACACAC]  font-normal"
            >
              {periods.map((period) => (
                <option key={period.value} value={period.value}>
                {period.option}
              </option>
              ))}
            </select>
          </div>
        </div>
          
          
           <div className="border-t border-[1px] border-slate-200/60 border-solid w-full "></div>

      {loading ? (
        <div className="flex justify-center items-center h-full">
          <div className="animate-pulse space-y-4 w-full">
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
          </div>
        </div>
      ) : (
        <div className='w-full flex h-[89%]'>
          <div className="w-1/2 flex justify-center items-center">
            <PieCharts
              data={chartData}
              label={true}
              labelValue={chartData.reduce((acc, cur) => acc + cur.value, 0)}
              COLORS={colorCodes.slice(0, chartData.length)}
              height={140}
              // activeShape={(props) => (
              //   <Sector {...props} outline="none" />
              // )}
            />
          </div>
          <div className="w-1/2 overflow-y-scroll">
          <div className='w-full font-medium sticky top-0 bg-white shadow-sm flex justify-start gap-2 pt-2  text-xs 2xl:text-sm'>
          <p className='w-[35%]'>Name</p>
          <p className='w-[25%]'>Assign</p>
          <p className='w-[25%]'>Submit</p>
</div>

            {chartData.map((data, idx) => {
              const color = colorCodes[idx];
              return (
                <div key={idx} className='w-full flex justify-start gap-2 mt-2  text-xs '>
                  <div
                    className={`w-2 h-2 rounded-full mt-1.5`}
                    style={{ backgroundColor: color }} 
                  ></div>
                 
                  <p className='w-[35%] font-thin'>{capitalizeFirstLetter(data?.name)}</p>
                  <p className='w-[25%] font-thin'>{data?.assign}</p>

                  <p className='w-[25%] font-thin'>  {data?.value}</p>
                </div>
               
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default QuotesSubmittedByProcurmentTeamProDash;
