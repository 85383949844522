import { TextMediumSM } from "../../../../../Components/Text/MediumText"
import ApprovalStatusPR from "./ApprovalStatusPR";
import Costcode from "./Costcode";
import Revision from "./Revision";
import RFQStatus from "./RfqStatus";




const PurchaseRequest = () =>{
    return(
        <>
        <TextMediumSM content="YPR" />
        <div className='w-full flex flex-wrap justify-center md:justify-start  items-center overflow-y-scroll'>
         {/* <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <Revision/>
         </div> */}
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <RFQStatus/>
         </div>
         {/* <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <Costcode/>
         </div> */}
         {/* <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <ApprovalStatusPR/>
         </div> */}
         </div>
         </>
    )
}

export default PurchaseRequest;