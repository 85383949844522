import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import { TextMediumSM } from '../../../../../Components/Text/MediumText';
import UseGetDashBoardData from '../../../../../Components/Hooks/useDashboardData';
import colorCodes from '../../../../../Components/Utils/ColorCode';
import { useNavigate } from 'react-router-dom';

const Industry = () => {
  const { data, loading } = UseGetDashBoardData(`/dashboard/customers-by-industry`);
  const [chartData, setChartData] = useState([]);
  const [sumValue,setSumValue] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (data?.customers_by_industry) {
      const formattedData = data.customers_by_industry.map(item => ({
        name: item.industry,
        value: item.count,
      }));
      formattedData.sort((a, b) => b.value - a.value);
      setChartData(formattedData);
      let sum = formattedData.reduce((acc, curr) => (acc + curr?.value), 0);
      setSumValue(sum);
           
    }
  }, [data]);

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="max-w-[250px] bg-white shadow-[rgba(13,_38,_76,_0.19)_0px_9px_20px] rounded-lg p-[5px]">
          <p className="text-xs font-medium">{`${payload[0].name || 'None'} : ${payload[0].value}`}</p>
        </div>
      );
    }
    return null;
  };

  const handleSliceClick = (entry) => {
    const path = "/sales/customers";
navigate(path, { state: { industry: entry.name } });
};

  return (
    <div className="col-span-2 bg-white h-[40vh] p-4 pb-10 rounded-lg shadow-lg overflow-hidden mb-4">
      <div className="flex justify-between items-center mb-2">
        <TextMediumSM content="Customer by Industry" color="text-[#4F4768]" />
      </div>

      {loading ? (
        <div className="flex justify-center items-center h-full">
          <div className="animate-pulse space-y-4 w-full">
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
          </div>
        </div>
      ) : (
        <div className='w-full flex h-[89%]'>
          <div className="w-1/2 flex justify-center items-center">
          {
            sumValue > 0 ? (
              <ResponsiveContainer width="100%" height={150}>
              <PieChart>
                <Pie
                  data={chartData}
                  cx="50%"
                  cy="50%"
                  innerRadius={30}
                  outerRadius={60}
                  dataKey="value"
                  style={{ cursor: 'pointer' }}
                 onClick={(entry) => handleSliceClick(entry)}
                     
                >
                  {chartData.map((entry, index) => (
                    // <Cell key={`cell-${index}`} fill={colorCodes[index % colorCodes.length]} />
                    <Cell
  key={`cell-${index}`}
  fill={colorCodes[index % colorCodes.length]}
  onFocus={(e) => e.target.blur()} 
/>

                  ))}
                </Pie>
                <Tooltip content={<CustomTooltip />} />
              </PieChart>
            </ResponsiveContainer>
            ):(
              <ResponsiveContainer width="100%" height={150}>
              <PieChart>
                <Pie
                  data={[
                    {
                      name:"No Data",
                      value:100
                    }
                  ]}
                  cx="50%"
                  cy="50%"
                  innerRadius={30}
                  outerRadius={60}
                  dataKey="value"
                  fill='#E8E8E8'
                >
                </Pie>
              </PieChart>
            </ResponsiveContainer>
            )
          }
          </div>
          <div className="w-1/2 overflow-y-scroll">
            <div className='w-full sticky top-0 font-medium py-1 bg-white shadow-sm flex justify-start gap-2 pt-2 text-xs 2xl:text-sm'>
              <p className='w-[60%] '>Industry</p>
              <p className='w-[40%] text-center'>Count</p>
            </div>
            {chartData?.map((item, index) => (
              <div key={index} className="flex items-center text-xs mb-1">
                <span
                  className="inline-block w-2 h-2 rounded-full mr-2"
                  style={{ backgroundColor: colorCodes[index % colorCodes.length] }}
                ></span>
                <p className='w-[60%] font-extralight'>{item.name || 'None'}</p>
                <p className='w-[40%] font-extralight text-center'>{item.value || 0}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Industry;
