import React, { useEffect, useState } from 'react';
import DataTable from '../../../../Components/Others/DataTable';
import { CancelButton, CreateButton, FilterButton, ImportButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { Link, useLocation } from 'react-router-dom';
import SearchBar from '../../../../Components/Utils/SearchBar';
import UseGetData from '../../../../Components/Hooks/useFetchData';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import MoreOptions from '../../../../Components/Utils/MoreOptions';
import CustomDataTable from '../../../../Components/Others/CustomDataTable';
import * as yup from 'yup';
import UseGetDataSearch from '../../../../Components/Hooks/useFetchDataBySearch';
import FormikSelectTwo from '../../../../Components/Utils/Select';
import { useFormik } from 'formik';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
import { NuDate } from '../../../../Components/Utils/Input';
import Importdoc from './Importdoc';
import ModalContainer from '../../../../Components/Others/ModalContainer';
import MultiEditGRN from './MultiEditGRN';
import { GetAccess } from '../../../../Components/Utils/roles';
import useFetchMDIndex from '../../../../Components/Hooks/useFetchMDIndex';

function GRNIndex() {

    const [page,setPage]=useState(1)
    const [pageCount,setPageCount]=useState(localStorage.getItem("perPageView")||20)
    const location = useLocation();
    const state = location.state;
    const [filter, setFilter] = useState(state?.status?true:false);
    const [showFilterDialog, setShowFilterDialog] = useState(false);
    
  const {value:GrnStatus} = useFetchMDIndex(`/dropdown/dropdown-details/`,'GrnStatus',showFilterDialog)
  const {value:PurchaseType} = useFetchMDIndex(`/dropdown/dropdown-details/`,'GrnpurchaseType',showFilterDialog)
    const [filterProps, setFilterProps] = useState({
      status:"",
purchaseType:"",
receivedDateFrom:"",
receivedDateTo:"",
    });
    const [params, setParams] = useState({
      page: page,
      per_page: pageCount,
      search:"",
      status:state?.status? state.status :"",

      purchaseType:"",
      receivedDateFrom:"",
      receivedDateTo:"",
    });
  
    const clearFilter = ()=> {
      setParams({...params,status:"",
        purchaseType:"",
        receivedDateFrom:"",
        receivedDateTo:"",})
        setFilter(false)
        formik.values.status=""
        formik.values.purchaseType=""
        formik.values.receivedDateFrom=""
        formik.values.receivedDateTo=""

    }
  
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: filterProps,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
        // email: yup.string().required('Email is required!'),
      }),
      onSubmit: async (value, { resetForm }) => {
        console.log("ffff",value);
        setParams({...params,status:value.status, purchaseType:value.purchaseType,receivedDateFrom:value.receivedDateFrom,receivedDateTo:value.receivedDateTo})
        closeFilterDialog();
        setFilter(true)
        setFilterProps({
          status:"",
purchaseType:"",
receivedDateFrom:"",
receivedDateTo:"",
        });
      }
    })
  
    const { data, totalCount, getData,dataLoading} = UseGetDataSearch('/grn/', params);
  
    const defaultHeader = [
      // {
      //   title: "ID",
      //   width: "5%",
      //   field: "id",
      //   textAlign: "center",
      //   rowAlign: "center",
      // },
      {
        title: "YPR",
        width: "10%",
        isLink: true,
        id: "id",
        link: '/store/grn/view/',
        field: "prRef",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "GRN No.",
        width: "10%",
        isLink: true,
        id: "id",
        link: '/store/grn/view/',
        field: "ref",
        textAlign: "center",
        rowAlign: "center",
      },
      
      {
        title: "PO No.",
        width: "10%",
        field: "poRef",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Supplier",
        width: "10%",
        field: "vendorName",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "DN No.",
        width: "10%",
        field: "dnRef",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Busy GRN No.",
        width: "10%",
        field: "busyGrnNo",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Recvd Date",
        width: "10%",
        field: "receivedDate",
        type:"date",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Status",
        width: "10%",
        field: "status",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Action",
        width: "5%",
        field: "action",
        textAlign: "center",
        rowAlign: "center",
             contents:{
          // fetcher:getData,
          id:"id",
          removeValue:"poRef",
          url:`/grn/`,
          moduleName:'GRN',
          from:`/store/grn`,
          editLink:'/store/grn/edit/',
          viewLink:'/store/grn/view/',
          
        }
      },
    ];
    const availableHeaders = [
      // {
      //   title: "ID",
      //   width: "5%",
      //   field: "id",
      //   textAlign: "center",
      //   rowAlign: "center",
      // },
      {
        title: "YPR",
        width: "10%",
        isLink: true,
        id: "id",
        link: '/store/grn/view/',
        field: "prRef",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "GRN No.",
        width: "10%",
        isLink: true,
        id: "id",
        link: '/store/grn/view/',
        field: "ref",
        textAlign: "center",
        rowAlign: "center",
      },
      
      {
        title: "PO No.",
        width: "10%",
        field: "poRef",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Supplier",
        width: "10%",
        field: "vendorName",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "DN No.",
        width: "10%",
        field: "dnRef",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Busy GRN No.",
        width: "10%",
        field: "busyGrnNo",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Recvd Date",
        width: "10%",
        field: "receivedDate",
        type:"date",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Status",
        width: "10%",
        field: "status",
        textAlign: "center",
        rowAlign: "center",
      },
      // {
      //   title: "Approved By",
      //   width: "10%",
      //   field: "approvedByName",
      //   textAlign: "center",
      //   rowAlign: "center",
      // },
      // {
      //   title: "Reviewed By",
      //   width: "10%",
      //   field: "reviewedByName",
      //   textAlign: "center",
      //   rowAlign: "center",
      // },
      {
        title: "Verified By",
        width: "10%",
        field: "verifiedByName",
        textAlign: "center",
        rowAlign: "center",
      },
      // {
      //   title: "Prepared By",
      //   width: "10%",
      //   field: "preparedByName",
      //   textAlign: "center",
      //   rowAlign: "center",
      // },
      
      {
        title: "Action",
        width: "5%",
        field: "action",
        textAlign: "center",
        rowAlign: "center",
             contents:{
          // fetcher:getData,
          id:"id",
          removeValue:"poRef",
          url:`/grn/`,
          moduleName:'GRN',
          from:`/store/grn`,
          editLink:'/store/grn/edit/',
          viewLink:'/store/grn/view/',
          
        }
      },
    ];
    useEffect(()=>{
      setParams({...params,page:page,per_page:pageCount})
     },[page,pageCount])
  
        const closeFilterDialog = () => {
          setShowFilterDialog(false);
        };

        const [showImportModal, setShowImportModal] = useState(false);

        const handleImportClick = () => {
          setShowImportModal(true);
        };
      
        const closeModal = () => {
          setShowImportModal(false);
        };
        const [showEdit, setShowEdit] = useState(false)
        const [showDelete, setShowDelete] = useState(false)
        const [listofNumbers, setListofNumbers] = useState([])
        const [fullList, setFullList] = useState([])
      
        const selectOneFunction = (value) =>{ 
          let NewId;
          if(listofNumbers && listofNumbers.some(Item=>Item ===value)){
            NewId = listofNumbers.filter(Temp=> Temp !== value);
            setListofNumbers(NewId)
          }else{
            setListofNumbers([...listofNumbers,value])
          }
        }
      
        const selectMultipleFunction = (e) =>{ 
      console.log(e.target.checked);
      if(e.target.checked){
        setListofNumbers(fullList)
      }else{
        setListofNumbers([])
      }
      
        }
      
        useEffect(()=>{
          let tempArr = []
          data && data.forEach(i=>{
            console.log("I",i?.id);
            tempArr.push(i?.id)
          });
          setFullList(tempArr)
        },[data])
    

    return (
      <div className='w-full h-full px-4 py-2'>
      <div className='w-full flex justify-between items-center'>
      <Breadcrumb 
        items={[
          { name: "Store" },
      
          { name: "GRN"
         },
            
      
        
      ]} /> 
      <div className=' flex gap-2'>
      <SearchBar
              handleChange={(e) =>{
                setParams({ ...params, search: e.target.value, page:1 });
                setPage(1)
              }}
            />     <FilterButton  handleClick={()=>setShowFilterDialog(true)} />
          {filter && <FilterButton  handleClick={()=>clearFilter()} label='Clear'/>}
        
          {/* <ImportButton handleClick={handleImportClick} /> */}
          {GetAccess("grn_create") &&  <Link to="/store/grn/create"><CreateButton label='GRN'/></Link> }
      </div>
      </div>
  
      <CustomDataTable 
      fetcher={getData}
        data={data} 
        availableHeaders={availableHeaders} 
        defaultHeader={defaultHeader}
        bodyHeight='max-h-[calc(100vh-260px)]'
        height='h-[calc(100vh-145px)]'
        total={totalCount}
        pageIndex={page}
        setPageIndex={setPage}
        dataCount={pageCount}
        setDataCount={setPageCount}
        title='GRN List'
        storageName="GrnTable"
        selectOneFunction={selectOneFunction}
        setShowDelete={setShowDelete}
        showDelete={showDelete}
        setShowEdit={setShowEdit}
        showEdit={showEdit}
        listofNumbers={listofNumbers}
        hasMultiEdit={true}
        selectMultipleFunction={selectMultipleFunction}
        fullList={fullList}
        getById='id'
        editAccess="grn_edit"      
        deteleAccess="grn_delete" 
        dataLoading={dataLoading}
        />
        {showFilterDialog && (
             
             
             <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
               <div className="animate-scale-in min-w-[40%] max-w-[80%] max-h-[80vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
                 <h2 className="text-xl mb-4">Filter GRN</h2>
                 <form onSubmit={formik.handleSubmit}> 
                    <div className="flex flex-col mb-4">
                    <FormikSelectTwo
              type="text"
              label="Purchase Type"
              placeholder="Purchase Type"
              Options={PurchaseType}
              formik={formik}
              name="purchaseType"
              width="w-full "
            />
                    <p className="py-2 px-6 text-m">Received Date</p>
                    <div className="flex justify-between  w-full ">
             <NuDate
              label="From "
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="receivedDateFrom"
              width="w-full "
            />
           <NuDate
              label="To "
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="receivedDateTo"
              width="w-full "
            />
            </div>
                       <FormikSelectTwo
              type="text"
              label="Status"
              placeholder="Status"
              Options={GrnStatus}
              formik={formik}
              name="status"
              width="w-full "
            />
                    </div>
                   <div className="flex justify-end gap-2">
                     <CancelButton handleClick={closeFilterDialog} />
                     <SubmitButton name='Apply' />
                   </div>
                  </form> 
               </div>
             </div>
           )}
        {showImportModal && (<Importdoc show={showImportModal} onClose={closeModal} getData={getData} />)}
        {showEdit && (<MultiEditGRN data={listofNumbers} getData={getData} onClose={()=>setShowEdit(false)}/>)}
        <ModalContainer
                visiable={showDelete}
                title="Warning"
                content={`Are you sure, All selected datas will be removed from YPR List!`}
                handleClick={() =>{}}
                closeModal={() => setShowDelete(false)}
            />
    </div>
    )
  }

export default GRNIndex