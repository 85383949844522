import React, { useState } from "react";
import Video from "../Icons/Video";
import Doc from "../Icons/Doc";
import Xls from "../Icons/Xls";
import Txt from "../Icons/Txt";
import Pdf from "../Icons/Pdf";
import ModalContainer from "../Others/ModalContainer";
import CloseIcon from "../Icons/Close";

function ViewCommentFiles({ data = [] }) {
  const videotype = ["mp4", "mov", "wmv", "avi", "flv", "mkv"];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [fileExtention, setFileExtention] = useState("");

  const handleImageClick = (image) => {
    console.log("aaaa", image);
    const Ext = image?.split(".").reverse()[0];
    setFileExtention(Ext);
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  return (
    <>
      <div className="flex items-center gap-1 flex-wrap">
        {Array.isArray(data) &&
          data.map((item, index) => {
            const Ext = item?.file.split(".").reverse()[0];
            return (
              <div
                className="w-7 h-7 my-[2px] rounded overflow-hidden cursor-pointer"
                key={index}
                onClick={() => handleImageClick(item.file)}
              >
                {videotype.includes(Ext) ? (
                  <Video width="24" height="25" />
                ) : null}
                {["docx", "doc", "txt"].includes(Ext) ? (
                  <Doc color="#537188" />
                ) : Ext === "xlsx" || Ext === "xls" ? (
                  <Xls color="#537188" />
                ) : Ext === "txt" ? (
                  <Txt color="#537188" />
                ) : Ext === "pdf" ? (
                  <Pdf color="#537188" />
                ) : Ext === "pptx" ? (
                  <>No file</>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    className="w-6 h-6 "
                    fill="#537188"
                  >
                    <path d="M448 80c8.8 0 16 7.2 16 16l0 319.8-5-6.5-136-176c-4.5-5.9-11.6-9.3-19-9.3s-14.4 3.4-19 9.3L202 340.7l-30.5-42.7C167 291.7 159.8 288 152 288s-15 3.7-19.5 10.1l-80 112L48 416.3l0-.3L48 96c0-8.8 7.2-16 16-16l384 0zM64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32zm80 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z" />
                  </svg>
                )}
              </div>
            );
          })}
      </div>
      <ModalContainer
        content={
          <div className="relative w-[calc(100vw-100px)] h-[calc(100vh-100px)] bg-white">
            <button
              onClick={() => {
                setIsModalOpen(false);
                setSelectedImage(null);
              }}
              className="absolute top-2 right-2"
            >
              <CloseIcon className="w-6 h-6" />
            </button>
            <div className="w-full h-full">
              {fileExtention === ("docx" || "doc" || "txt") ? (
                <iframe
                  src={selectedImage} // PDF URL or Blob URL
                  style={{ width: "100%", height: "100%" }}
                  frameBorder="0"
                />
              ) : fileExtention === ("xlsx" || "xls") ? (
                <iframe
                  src={selectedImage} // PDF URL or Blob URL
                  style={{ width: "100%", height: "100%" }}
                  frameBorder="0"
                />
              ) : fileExtention === "txt" ? (
                <iframe
                  src={selectedImage} // PDF URL or Blob URL
                  style={{ width: "100%", height: "100%" }}
                  frameBorder="0"
                />
              ) : fileExtention === "pdf" ? (
                <iframe
                  src={selectedImage} // PDF URL or Blob URL
                  style={{ width: "100%", height: "100%" }}
                  frameBorder="0"
                />
              ) : fileExtention === "PDF" ? (
                <iframe
                  src={selectedImage} // PDF URL or Blob URL
                  style={{ width: "100%", height: "100%" }}
                  frameBorder="0"
                />
              ) : fileExtention === "pptx" ? (
                <>No file</>
              ) : (
                <img
                  src={selectedImage}
                  alt="Selected"
                  className="object-contain h-full w-full"
                />
              )}
            </div>
          </div>
        }
        visiable={isModalOpen}
        closeModal={() => {
          setIsModalOpen(false);
          setSelectedImage(null);
        }}
        hasButton={false}
      />
    </>
  );
}

export default ViewCommentFiles;
