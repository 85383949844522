import React, { useState, useEffect } from 'react';
import { PieChart, Cell, Pie, Tooltip, Label, ResponsiveContainer } from 'recharts';
import UseGetDashBoardWithParams from '../../../../../Components/Hooks/useDashboardDataParams';
import { TextMediumSM } from '../../../../../Components/Text/MediumText';
import capitalizeFirstLetter from '../../../../../Components/Utils/Funcs';
import { useNavigate } from 'react-router-dom';

export default function MonthWiseProcurment() {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  const navigate = useNavigate();

  const [params, setParams] = useState({
    month: currentMonth,
    year: currentYear,
  });
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const { data, loading } = UseGetDashBoardWithParams(`/new-dashboard/procurement/ypr-received`, params);

  const handleMonthChange = (e) => {
    const newMonth = e.target.value;
    setParams({ ...params, month: newMonth });
    setSelectedMonth(newMonth);
  };

  const handleYearChange = (e) => {
    const newYear = e.target.value;
    setParams({ ...params, year: newYear });
    setSelectedYear(newYear);
  };

  const months = [
    { name: 'January', value: 1 },
    { name: 'February', value: 2 },
    { name: 'March', value: 3 },
    { name: 'April', value: 4 },
    { name: 'May', value: 5 },
    { name: 'June', value: 6 },
    { name: 'July', value: 7 },
    { name: 'August', value: 8 },
    { name: 'September', value: 9 },
    { name: 'October', value: 10 },
    { name: 'November', value: 11 },
    { name: 'December', value: 12 },
  ];
  

  
  const salesmenData = data?.salesmen?.map((salesman) => ({
    name: salesman.name,
    catA: salesman['Category A'] || 0,
    catB: salesman['Category B'] || 0,
    catC: salesman['Category C'] || 0,
    total: salesman.total,
  })) || [];

  

  const COLORS = ['#605678', '#4D869C', '#C96868'];

  const chartData = [
    { name: 'Category A', value: data?.customers?.['Category A'] || 0, label: 'A' },
    { name: 'Category B', value: data?.customers?.['Category B'] || 0, label: 'B' },
    { name: 'Category C', value: data?.customers?.['Category C'] || 0, label: 'C' },
  ].filter((entry) => entry.value > 0); 
  const renderCustomLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, index }) => {
    if (!chartData[index] || chartData[index].value === 0) return null; 
  
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
  
    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor="middle"
        dominantBaseline="central"
        fontSize={18}
        fontWeight="semibold"
      >
        {chartData[index].label}
      </text>
    );
  };
  
  const handleSliceClick = (label) => {
       const path = '/procurement/purchase-requests';
   
    
    navigate(path , {state: { category : label } });
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="max-w-[250px] bg-white shadow-md rounded-lg p-[5px]">
          <p className="text-xs font-medium">{`${payload[0].name} : ${payload[0].value}`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="col-span-2 bg-white h-[40vh] w-full p-4 pb-10 rounded-lg drop-shadow-lg mb-4">
      <div className="w-full px-1 py-[1px] rounded-md flex justify-between">
        <TextMediumSM content={"YPR Assigned"} color="text-[#4F4768]" />
        
        <div className="text-center text-xs text-[#304463] flex space-x-2">
          <select
            value={selectedYear}
            onChange={handleYearChange}
            className="border rounded-md p-1 text-xs text-[#ACACAC] font-normal"
          >
            {[...Array(10)].map((_, index) => (
              <option key={index} value={currentYear - 5 + index}>
                {currentYear - 5 + index}
              </option>
            ))}
          </select>
          <select
            value={selectedMonth}
            onChange={handleMonthChange}
            className="border rounded-md p-1 text-xs text-[#ACACAC] font-normal"
          >
            {months.map((month) => (
              <option key={month.value} value={month.value}>
                {month.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="border-t border-[1px] border-slate-200/60 border-solid w-full "></div>

      {loading ? (
        <div className="p-4 max-w-sm w-full flex flex-col justify-center items-center h-full">
          <div className="animate-pulse flex space-x-4 w-full">
            <div className="flex-1 space-y-3 py-1">
              <div className="h-3 bg-slate-200 rounded"></div>
              <div className="space-y-3">
                <div className="grid grid-cols-3 gap-4">
                  <div className="h-3 bg-slate-200 rounded col-span-2"></div>
                  <div className="h-3 bg-slate-200 rounded col-span-1"></div>
                </div>
                <div className="h-3 bg-slate-200 rounded"></div>
                <div className="grid grid-cols-3 gap-4">
                  <div className="h-3 bg-slate-200 rounded col-span-1"></div>
                  <div className="h-3 bg-slate-200 rounded col-span-2"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full flex h-[89%]">
          {/* { > 0? ( */}
          <div className="w-[40%] flex justify-center items-center">
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={chartData}
                  cx="50%"
                  cy="50%"
                  innerRadius={35}
                  outerRadius={60}
                  fill="#8884d8"
                  dataKey="value"
                  label={renderCustomLabel}
                  labelLine={false}
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => handleSliceClick(e.label)}

                >
                  {chartData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}   onFocus={(e) => e.target.blur()} 
/>
                  ))}
                  <Label
                    value={data?.customers?.total || '0'}
                    position="center"
                    className="text-center"
                    style={{ fontSize: '12px', fontWeight: 'bold' }}
                  />
                </Pie>
                <Tooltip content={<CustomTooltip />} />
                </PieChart>
            </ResponsiveContainer>
          </div>
          {/* ):(
            <NODataChart/>
          )} */}
          <div className="w-[60%] overflow-y-scroll mt-4">
            <div className="w-full sticky top-0 bg-white font-semibold mb-1 shadow-sm flex justify-start gap-2 pt-2 text-xs">
              <p className="w-[40%]">Buyer</p>
              <p className="w-[15%] text-center">Cat A</p>
              <p className="w-[15%] text-center">Cat B</p>
              <p className="w-[15%] text-center">Cat C</p>
              <p className="w-[15%] text-center">Total</p>
            </div>
            {salesmenData.map((item, index) => (
              <div key={index} className="flex items-center font-extralight text-xs mb-1">
               <p className="w-[40%]">{capitalizeFirstLetter(item.name)}</p>

                <p className="w-[15%] text-center">{item.catA}</p>
                <p className="w-[15%] text-center">{item.catB}</p>
                <p className="w-[15%] text-center">{item.catC}</p>
                <p className="w-[15%] text-center">{item.total}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export function NODataChart() {
  return (
    <div className=" w-[40%]">
      {/* <Doughnut data={chartData} /> */}
      <div className="w-full flex justify-center">
        <ResponsiveContainer width="100%" height={150}>
          <PieChart>
            <Pie
              data={[
                {
                  name: "No Data",
                  value: 100,
                },
              ]}
              cx="50%"
              cy="50%"
              innerRadius={35}
              outerRadius={60}
              fill="#E8E8E8"
              dataKey="value"
              // paddingAngle={0}
            >
              <Label
                value={0}
                position="center"
                className="text-center"
                style={{ fontSize: "12px", fontWeight: "bold" }}
              />
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
