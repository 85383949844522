import React, { useEffect, useState } from "react";
import DataTable from "../../../../Components/Others/DataTable";
import {
  CancelButton,
  CreateButton,
  FilterButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import { Link } from "react-router-dom";
import SearchBar from "../../../../Components/Utils/SearchBar";
import UseGetData from "../../../../Components/Hooks/useFetchData";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import MoreOptions from "../../../../Components/Utils/MoreOptions";
import CustomDataTable from "../../../../Components/Others/CustomDataTable";
import UseGetDataSearch from "../../../../Components/Hooks/useFetchDataBySearch";
import FormikSelectTwo from "../../../../Components/Utils/Select";
import { useFormik } from "formik";
import * as yup from 'yup';
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import useFetchMetaStar from "../../../../Components/Hooks/useFetchMetaStar";
import Importdoc from "./Importdoc";
import { GetAccess } from "../../../../Components/Utils/roles";
import useFetchMDIndex from "../../../../Components/Hooks/useFetchMDIndex";
import useFetchMetaStarIndex from "../../../../Components/Hooks/useFetchMetaStarIndex";

function LeadsIndex() {
 
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(localStorage.getItem("perPageView")||20);
  const [filter, setFilter] = useState(false);
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const {value:Industry} = useFetchMDIndex(`/dropdown/dropdown-details/`,'Industry_leads',showFilterDialog)
  const {value:LeadStatus} = useFetchMDIndex(`/dropdown/dropdown-details/`,'lead_Status',showFilterDialog)
  const {value:LeadsRatings} = useFetchMetaStarIndex(`/dropdown/dropdown-details/`,'leadsRating',showFilterDialog)

  const [filterProps, setFilterProps] = useState({
    ratings:"",
    industry:"",
    status:""
  });
  const [params, setParams] = useState({
    page: page,
    per_page: pageCount,
    search:"",
    ratings:"",
    industry:"",
    status:""
  });
  const clearFilter = ()=> {
    setParams({...params,
       ratings:"",
    industry:"",
    status:""})
    formik.values.status=""
    formik.values.industry=""
    formik.values.ratings=""

      setFilter(false)
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterProps,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      console.log("ffff",value);
      setParams({...params,status:value.status, industry:value.industry, ratings:value.ratings})
      closeFilterDialog();
      setFilter(true)
      setFilterProps({
        ratings:"",
        industry:"",
        status:""
      });
    }
  })

  const { data, totalCount, getData,dataLoading } = UseGetDataSearch( "/leads/",params);
 
  const defaultHeader = [
    {
      title: "ID",
      width: "5%",
      field: "leadId",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Name",
      width: "9%",
      isLink: true,
      id: "leadId",
      link: '/marketing/leads/view/',
      field: "name",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Lead Source",
      width: "9%",
      field: "leadSource",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Industry",
      width: "9%",
      field: "industry",
      textAlign: "center",
      rowAlign: "center",
    },

    {
      title: "Action",
      width: "5%",
      field: "action",
      textAlign: "center",
      rowAlign: "center",
      contents: {
        // fetcher: getData,
        id: "leadId",
        removeValue: "name",
        url: "/leads/",
        moduleName: "Leads",
        from: "/marketing/leads",
        editLink: "/marketing/leads/edit/",
        viewLink: "/marketing/leads/view/",
      },
    },
  ];

  let availableHeaders = [
    {
      title: "ID",
      width: "5%",
      field: "leadId",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Name",
      width: "9%",
      isLink: true,
      id: "leadId",
      link: '/marketing/leads/view/',
      field: "name",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Email",
      width: "10%",
      field: "email",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Phone ",
      width: "10%",
      field: "mobile.display",
      // subField: "display",
      textAlign: "center",
      rowAlign: "center",
    },
    // {
    //   title: "Lead Owner",
    //   width: "9%",
    //   field: "leadOwner",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    {
      title: "Industry",
      width: "9%",
      field: "industry",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Company Address",
      width: "9%",
      field: "companyAddress",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Company Size",
      width: "9%",
      field: "companySize",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Conversion Probabilities",
      width: "9%",
      field: "conversionProbabilities",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Description",
      width: "9%",
      field: "description",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Lead Source",
      width: "9%",
      field: "leadSource",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Ratings",
      width: "9%",
      field: "ratings",
      textAlign: "center",
      rowAlign: "center",
    },
    // {
    //   title: "Comments",
    //   width: "9%",
    //   field: "comments",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },

    {
      title: "Action",
      width: "5%",
      field: "action",
      textAlign: "center",
      rowAlign: "center",
      contents: {
        // fetcher: getData,
        id: "leadId",
        removeValue: "name",
        url: "/leads/",
        moduleName: "Leads",
        from: "/marketing/leads",
        editLink: "/marketing/leads/edit/",
        viewLink: "/marketing/leads/view/",
      },
    },
  ];

  useEffect(()=>{
    setParams({...params,page:page,per_page:pageCount})
   },[page,pageCount])

   const closeFilterDialog = () => {
    setShowFilterDialog(false);
  };
  const [showImportModal, setShowImportModal] = useState(false);


  const handleImportClick = () => {
    setShowImportModal(true);
  };

  const closeModal = () => {
    setShowImportModal(false);
  };
  return (
    <div className="w-full h-full px-4 py-2">
      <div className="w-full flex justify-between items-center">
        <Breadcrumb
          items={[
            {
              name: "Lead",
            },
          ]}
        />
        <div className=" flex gap-2">
        <SearchBar
              handleChange={(e) =>{
                setParams({ ...params, search: e.target.value, page:1 });
                setPage(1)
              }}
            />  <FilterButton  handleClick={()=>setShowFilterDialog(true)} />
          {filter && <FilterButton  handleClick={()=>clearFilter()} label='Clear'/>}
        
          {/* <CreateButton label='Import' handleClick={handleImportClick} /> */}

          {GetAccess("leads_create") &&  <Link to="/marketing/leads/create/">
            <CreateButton label="Lead" />
          </Link>}
        </div>
      </div>

      <CustomDataTable
      fetcher={getData}
        data={data}
        availableHeaders={availableHeaders}
        defaultHeader={defaultHeader}
        bodyHeight="max-h-[calc(100vh-260px)]"
        height="h-[calc(100vh-145px)]"
        total={totalCount}
        pageIndex={page}
        setPageIndex={setPage}
        dataCount={pageCount}
        setDataCount={setPageCount}
        title="Leads List"
        storageName="LeadsTable"
        editAccess="leads_edit"      
        deteleAccess="leads_delete" 
        dataLoading={dataLoading}
      />
        {showFilterDialog && (
             
             
             <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
               <div className="animate-scale-in min-w-[40%] max-w-[80%] max-h-[80vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
                 <h2 className="text-xl mb-4">Filter Lead</h2>
                 <form onSubmit={formik.handleSubmit}> 
                    <div className="flex flex-col mb-4">
                    <FormikSelectTwo
                type="text"
                label="Industry"
                placeholder="Choose"
                Options={Industry}
                formik={formik}
                name="industry"
                width="w-full "
              />
               {/* <FormikSelectTwo
              Options={LeadsRatings}
              label="Performance Ratings"
              placeholder="Ratings"
              formik={formik}
              name="ratings"
              width="w-full "
            /> */}
                     <FormikSelectTwo
                type="text"
                label="Status"
                placeholder="Choose"
                Options={LeadStatus}
                formik={formik}
                name="status"
                width="w-full "
              />
            
                    </div>
                   <div className="flex justify-end gap-2">
                     <CancelButton handleClick={closeFilterDialog} />
                     <SubmitButton name='Apply' />
                   </div>
                  </form> 
               </div>
             </div>
           )}
        {showImportModal && (<Importdoc show={showImportModal} onClose={closeModal} getData={getData} />)}

    </div>
  );
}
export default LeadsIndex;
