import React, { useEffect, useState } from "react";
import { FaClipboardCheck, FaHourglassHalf } from "react-icons/fa";
import axiosInstance from "../../../../Services/axiosCommon";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts";
import { NoData } from "./PrFromCustomer";
import { useNavigate } from "react-router-dom";

// Define colors for the statuses
const COLORS = ["#FFBB28", "#6A5ACD"]; // Colors for Pending and Verified

function DeliveryNotesApproval() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  async function getData() {
    setLoading(true);
    try {
      await axiosInstance.get(`/dashboard/DN-pending`).then((res) => {
        setLoading(false);
        setData(res?.data || []);
      });
    } catch (error) {
      setLoading(false);
    
    }
  }

  useEffect(() => {
    getData();
  }, []);

  // Prepare chart data
  const chartData = [
    { name: "Pending", value: data?.pending || 0 ,status:'Pending' },
    { name: "Verified", value: data?.verified || 0,status:'Verified' },
  ];
  const handleSliceClick = (status) => {
    const path = '/procurement/purchase-requests';    
  navigate(path , {state: { status : status } });
};
  const total = (data?.total || 0).toLocaleString();

  return (
    <div className="flex-1 flex-wrap p-2  rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)]  overflow-y-scroll my-1 mr-1">
     <div className="flex items-center justify-between mb-2">
        <p className="text-[#4F4768] text-xs 2xl:text-sm font-semibold">
          Delivery Notes
        </p>
      </div>
      <div className="relative">
        {total > 0 ? (
          <ResponsiveContainer width="100%" height={90}>
            <PieChart>
              <Pie
                data={chartData}
                cx="50%"
                cy="50%" // Center the pie chart
                innerRadius={27}
                outerRadius={32}
                fill="#8884d8"
                paddingAngle={0}
                dataKey="value"
              >
                {chartData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip contentStyle={{ fontSize: "12px", padding: "1px" }} />
            </PieChart>
          </ResponsiveContainer>
        ) : (
          <NoData />
        )}

        {
          <div className="absolute inset-0 flex items-center justify-center text-[10px] font-semibold text-[#333333]">
            {total || 0}
          </div>
        }
      </div>
      <div className="flex gap-4  mt-2 justify-between">
        <div className="flex items-center text-[11px]">
          {/* <FaHourglassHalf className="text-yellow-500 mr-1 text-[8px]" />
          <span>Pending: {(data?.pending || 0).toLocaleString()}</span> */}
                    <p className="text-yellow-500">Pending<span className="text-[#4F4768]"> : {(data?.pending || 0).toLocaleString()}</span></p>

        </div>

        <div className="flex items-center text-[11px]">
          {/* <FaClipboardCheck className="text-purple-500 mr-1 text-[8px]" />
          <span>Verified: {(data?.verified || 0).toLocaleString()}</span> */}
                    <p className="text-purple-500">Verified<span className="text-[#4F4768]"> : {(data?.verified || 0).toLocaleString()}</span></p>

        </div>
      </div>
    </div>
  );
}

export default DeliveryNotesApproval;
