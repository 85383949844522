import { Link } from "react-router-dom";
import { TextMediumSM } from "../../../../Components/Text/MediumText";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import { BackButton, CreateButton } from "../../../../Components/Utils/Buttons";
import { useEffect, useState } from "react";
import UseGetData from "../../../../Components/Hooks/useFetchData";
import DataTable from "../../../../Components/Others/DataTable";
import MoreOptions from "../../../../Components/Utils/MoreOptions";
import UserCard from "./UserCard";
import List from "../../../../Components/Icons/list";
import Card from "../../../../Components/Icons/card";
import Pagination from "../../../../Components/Utils/Pagenation";
import ViewMore from "./ViewMore";


const MainUserSettingsIndex = (id) => {
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(localStorage.getItem("perPageView")||20);
  const { data, totalCount, getData } = UseGetData(
    page,
    pageCount,
    "/user/all-users"
  );
  const [viewMode, setViewMode] = useState("list");
  
  let header = [
    {
      label: "ID",
      width: "w-[5%]",
    },
    {
      label: "Name",
      width: "w-[20%]",
    },
    {
      label: "Email",
      width: "w-[15%]",
    },
    {
      label: "Phone",
      width: "w-[15%]",
    },
    {
      label: "Division",
      width: "w-[15%]",
    },
    {
      label: "Status",
      width: "w-[12%]",
    },
    {
      label: "Action",
      width: "w-[8%]",
    },
  ];

  useEffect(() => {
    getData();
    localStorage.setItem("viewMode", viewMode);
  }, [page, pageCount, viewMode]);
  return (
    <div className="w-full h-full px-4 py-2">
      <div className="w-full flex justify-between items-center">
        <Breadcrumb
          items={[
            {
              name: "Settings",
            },
            {
              name: "User",
            },
          ]}
        />
        <BackButton />
      </div>
      <div className="w-full h-[calc(100vh-145px)] bg-white mt-3 mx-auto  p-2 rounded-md">
        <div className=" w-full flex justify-between items-center">
             <p className='text-sm pl-3 font-semibold text-[#111537] text-left'>Users : {totalCount}</p>

          <div className="flex gap-2">
          <div className='flex gap-2'>
            {/* <div onClick={() => setViewMode("list")} className={viewMode === "list" ? "bg-baseColor-primary h-8 w-10 rounded-md flex justify-center items-center text-white" : "bg-white h-8 w-10 rounded-md flex justify-center items-center cursor-pointer"}><List/></div>
            <div onClick={() => setViewMode("card")} className={viewMode === "card" ? "bg-baseColor-primary h-8 w-10 rounded-md flex justify-center items-center text-white" : "bg-white h-8 w-10 rounded-md flex justify-center items-center cursor-pointer"}><Card/></div> */}
          </div>
          <Link to="/settings/user/create">
            <CreateButton label="User" />
          </Link>
          </div>
        </div>
      {viewMode === "list" ? (
        <DataTable
          headers={header}
          total={totalCount}
          pageIndex={page}
          setPageIndex={setPage}
          dataCount={pageCount}
          setDataCount={setPageCount}
          height="h-[calc(100vh-210px)]"
          bodyHeight="max-h-[calc(100vh-280px)]"
        //    bodyHeight="max-h-[calc(100vh-260px)]"
        // height="h-[calc(100vh-145px)]"
        >
          {data &&
            data.map((item, index) => (
              <tr
                className="odd:bg-white even:bg-slate-50 hover:text-baseColor-primary hover:shadow-md hover:scale-y-105"
                key={index}
              >
                <td className="py-3 px-[8px] text-center w-[5%]">
                  {[index+1] || "#"}
                </td>
                <td className="py-3 px-[8px] text-center w-[20%]">
                <Link className='hover:text-baseColor-primary text-mainColor-400'  to={`/settings/user/view/${item?.userId}`} state={{data:item}}>              
                 {item?.name || "#"}</Link>
 
                </td>
                <td className="py-3 px-[8px] text-center w-[15%]">
                  {item?.email || "#"}
                </td>
                <td className="py-3 px-[8px] text-center w-[15%]">
                  {item?.phone?.display || "#"}
                </td>
                <td className="py-3 px-[8px] text-center w-[15%]">
                  {item?.division || "#"}
                </td>
                <td className="py-3 px-[8px] text-center w-[12%]">
                  {item?.status==1?"Active":"Inactive" || "#"}
                </td>
                <td className="py-3 px-[8px] text-center w-[8%]">
                  {/* <MoreOptions
                    data={item}
                    fetcher={getData}
                    id={item?.userId|| ""}
                    removevalue={item?.name || "undefined"}
                    url={"/user/remove-user/"}
                    moduleName="User"
                    from={"/settings/user/"}
                    editLink={"/settings/user/edit/"}
                    viewLink={"/settings/user/view/"}
                   /> */}
                   <ViewMore  fetcher={getData} getData={getData}    removevalue={item?.name || "undefined"} data={item} id={item?.user?.id} editLink={"/settings/user/edit/"}  from={"/settings/user/"}/>
                </td>
              </tr>
            ))}
        </DataTable>
      ) : (
        <>
        <div className='w-full h-[calc(100vh-220px)] rounded-md bg-white p-3  relative overflow-y-scroll'>
      
          <div className='w-full flex justify-start flex-wrap md:gap-x-5 lg:gap-x-6 gap-y-6 '>
            {data && data.map((item, idx) => (
              <UserCard fetcher={getData} item={item} key={idx} />
            ))}
          </div>
        </div>
        <div className="w-full h-[20px] rounded-b-md bg-white">
          <Pagination
            total={totalCount}
            pageIndex={page}
            setPageIndex={setPage}
            dataCount={pageCount}
            setDataCount={setPageCount}
          />
        </div>
        </>
      )}
      </div>
    </div>
  );
};

export default MainUserSettingsIndex;
