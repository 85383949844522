import React, { useState } from 'react'
import { BackButton} from '../../../../Components/Utils/Buttons';
import FormikSelectTwo, { TwoNEW } from '../../../../Components/Utils/Select';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import UseDropDownPR from '../../../../Components/Hooks/UserddNewPr';
function CreateCustomerQuotes() {
  
  const location = useLocation();
  let pathFile = location.pathname;

  const [search, setSearch] = useState("");
  
  // const { data: PR } = UseDropDown('/PR/drop-down');
  const { data: PR  } = UseDropDownPR(`/customer-quotations/PR-drop-down?search=${search}`);

  const formik = useFormik
    ({
      enableReinitialize: true,
      initialValues: {prId:''},
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
        // email: yup.string().required('Email is required!'),
      }),
      onSubmit: async (value, { resetForm }) => {
      }
    });
   
  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between ">
        <Breadcrumb
          items={[
            // { name: "Sales",
            // link: "/sales/quotes" },
            {
              name: "Customer Quotation",
              link: "/sales/quotes",
            },
            {
              name: "Create Customer Quotation",
            },
          ]}
        />
        <BackButton />
      </div>
      <TwoNEW
              label="YPR"
              placeholder="Choose"
              Options={PR}
              formik={formik}
              name="prId"
              setSearch={setSearch}
              FixPosition={false}
              PortalTarget={false}
              width="w-full md:w-1/2 lg:w-1/3"
        />
      <div className=" w-full h-8 flex items-center px-1 ">
      <>
          <Link
            to={`/sales/quotes/create`}
            state={{data:{prId:formik.values.prId}}}
            className={`mx-3 pb-1 text-xs 2xl:text-sm font-medium text-baseColor-primary ${
              `/sales/quotes/create` == pathFile
                ? "border-b-2 border-baseColor-primary"
                : "border-b-2 border-transparent"
            }   `}
          >
            {"Create Quote"}
          </Link>
        </>
      <>
          <Link
            to={`/sales/quotes/create/viewqoutes`}
            state={{data:{prId:formik.values.prId}}}
            className={`mx-3 pb-1 text-xs 2xl:text-sm font-medium text-baseColor-primary ${
              `/sales/quotes/create/viewqoutes` == pathFile
                ? "border-b-2 border-baseColor-primary"
                : ""
            }   `}
          >
            {"Quote Comparison"}
          </Link>
        </>
       
      </div>
      <div className=' w-full h-[calc(100vh-250px)] '>
        <Outlet context={{ prId: formik.values.prId }} />
      </div>
    </div>
  );
}

export default CreateCustomerQuotes;