import React, { useEffect, useState } from 'react'
import { NormalHeading } from '../../../../Components/Utils/Heading';
import { BackButton, CancelButton, CreateButton, EditButton } from '../../../../Components/Utils/Buttons';
import { Link, useLocation, useParams } from 'react-router-dom';
import Dashboard from '../../../../Components/Icons/Dasboard';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import Modal from '../../../../Components/Utils/AttachmentView';
import NuDate from '../../../../Components/Utils/NuDate';
import PdfLinkDn from './Pdf/PdfLinkDn';
import Viewpdf from './Viewpdf';
import ApprovalComponent from '../../../../Components/Utils/approvalComponent';
import DNPdfOthers from '../../ViewPdfTemplate/DNPdfOthers';
import DNPdfNu from '../../ViewPdfTemplate/DNPdfNu';
import axiosInstance from '../../../../Services/axiosCommon';
import PDFViewer from '../../../../Components/Utils/PDFViewer';
import FilesView from '../../../../Components/Utils/FilesView';
import { GetAccess, GetRole } from '../../../../Components/Utils/roles';

function ViewDeliveryNotes() {
    const { deliveryNoteViewId } = useParams();
    const location = useLocation();
    const [data,setData] = useState(location.state.data);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [filePathList, setFilePathList] = useState([]);
    const [showPdfComponent, setShowPdfComponent] = useState(false);
  
    useEffect(()=>{
      if (
        data?.attachments !== null &&
        data?.attachments !== "None" &&
        data?.attachments !== "[]"
      ) {
        setFilePathList(data?.attachments);
        // console.log('image',createNew?.attachments);
        // setRemoveFilePathList(data?.productImage);
        // console.log('images',filePathList);
      }
  },[data])
  
  function UpdateAttachmentList(item) {
  let filterList = filePathList.filter((itemList) => itemList.file !== item);
  console.log('llll',filterList);
  setFilePathList(filterList);
  }
  
  function handleImageClick(img) {
  setSelectedImage(img);
  setIsModalOpen(true);
  }
  const handlePdfIconClick = () => {
    setShowPdfComponent(true);
  };
  const closeFilterDialog = () => {
    setShowPdfComponent(false);
  };
  const id = deliveryNoteViewId; 
  const getUrl = `/delivery-notes/get-status`;
  const putUrl = `/delivery-notes/edit-status`; 
  const statusUrl = '/delivery-notes/all-status';


  const downloadPDF = async () => {
    try {
        const response = await axiosInstance.get(`/delivery-notes/download-pdf/${deliveryNoteViewId}`, {
            responseType: 'blob' // Important to specify the response type as blob
        });

        // Create a Blob from the response
        const pdfBlob = new Blob([response.data], { type: 'application/pdf' });

        // Create a link element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(pdfBlob);
        link.download = `${data.ref}.pdf`; // The filename you want for the downloaded file

        // Append the link to the body (required for Firefox)
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);

    } catch (error) {
        console.error('Error downloading the PDF:', error);
    }
};


const [viewPDF,setViewPDF] = useState(null);

const ViewPDF = async () => {
  try {
      const response = await axiosInstance.get(`/delivery-notes/download-pdf/${deliveryNoteViewId}`, {
          responseType: 'blob' // Important to specify the response type as blob
      });

      // Create a Blob from the response
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });

      // Create a link element
      let Viewlink = window.URL.createObjectURL(pdfBlob);
      setViewPDF(Viewlink);

  } catch (error) {
      console.error('Error downloading the PDF:', error);
  }
};

useEffect(() => {
  ViewPDF()
},[])

const EditAccess = "delivery_notes_edit"


  return (
    <div className="w-full h-full py-2 px-4 ">
    <div className="flex justify-between ">
      <Breadcrumb 
        items={[
          { name: "Delivery Note",
            link:"/store/delivery-note"},
             { name: "View"},
        
      ]} />
        <div className=" flex gap-2">
          <Link to={'/store/delivery-note'}><BackButton link={true}/></Link>
          <CreateButton svg={false} handleClick={downloadPDF} label="Download as PDF" />

          {/* <PdfLinkDn
            data={data}
            fileName={`Delivery_Note_${data.ref}_${NuDate({
              value: Date(),
              format: "DD-MMM-YYY",
            })}.pdf`}
          /> */}
          {data?.editable === false ? (
            <>
            {GetRole() && (
          <Link to={`/store/delivery-note/edit/${deliveryNoteViewId}`} 
          state={{data: data, from:`/store/delivery-note/view/${deliveryNoteViewId}`}}
          ><EditButton height='8'/></Link>
          )}
          </>
        ) : (
        <>
        {GetAccess(EditAccess) && (
        <Link to={`/store/delivery-note/edit/${deliveryNoteViewId}`} 
        state={{data: data, from:`/store/delivery-note/view/${deliveryNoteViewId}`}}
        ><EditButton height='8'/></Link>
        )}
        </>
        )
      }
        </div>
      </div> 
      <div className="w-full mx-auto h-[calc(100vh-125px)]  rounded-md overflow-hidden p-2   flex gap-2 text-[#111537]">
        <div className="w-[70%]  bg-white rounded-lg drop-shadow-lg py-1 overflow-hidden ">
        <div className="w-[98%]  bg-white px-2 mx-2 border border-gray py-2 rounded-lg flex justify-between items-center  z-10">  
        <div className="grid  items-center px-2">
        <div className="flex text-[14px] mb-1 text-[#111537] font-semibold w-[90%]">
            <p className="">{data.ref}</p>
            {/* <p className=""> : {data?.description || 'None'}</p> */}
            </div>
            <div className="flex gap-4">
            <p className="text-xs text-[#111537]">Date : {data?.createdAt ? (
    <NuDate value={data?.createdAt} format="DD-MMM-YYYY, hh:mm A"  /> 
  ) : (
    "N/A"
  )}
         </p>
         </div>
         </div>
         {/* <p className="text-xs text-[#111537]"> Created By : {data.preparedByName || 'None'}</p> */}
         {/* <p className="bg-[#579BB1] text-white items-center rounded-md text-xs p-[2px]"> {data.deliveryStatus || 'None'}</p> */}
         <div className="border rounded-md mr-3 border-[#579BB1] text-xs inline-flex w-auto  flex-shrink-0  justify-center">
            <p className="  bg-white  text-black  p-1 rounded-l-md  ">
              Status  </p><p className="bg-[#579BB1] text-white p-1 items-center rounded-r-md"> {data.deliveryStatus || 'None'}</p>
             
              </div>
        
         </div>
             {/* <div className="flex justify-between items-center">
   
   <div className='flex gap-3 items-center'>
 
      <NormalHeading title={"Delivery Note"} />  : <p className="text-xs grid pt-5"> {data.ref} 
              <span className="font-light text-[11px] ">{data?.date ? (
                  <NuDate value={data?.date} format="DD-MMM-YYYY" />
                ) : (
                  "N/A"
                )}</span> </p>   </div>
                 <p className=" p-2  bg-[#F0DE36] text-white items-center rounded-md text-xs h-8 ">
                {data.deliveryStatus || 'None'}
              </p></div> */}
        <div className="h-[calc(100vh-200px)] overflow-y-auto p-2 pb-8 ">

<div className=" w-full flex flex-wrap  px-2 border border-gary mt-2 rounded-lg mb-2">

          <div className="w-full grid grid-cols-2 gap-x-8 ">
            {/* <div className='w-full flex px-2'>
                  <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Sales Order</p>
                  <p className='w-[65%] py-2 pl-2 text-xs  text-[#606279]'>: {data.soRef}</p>
                </div> 
                <div className='w-full flex px-2'>
                  <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Customer </p>
                  <p className='w-[65%] py-2 pl-2 text-xs  text-[#606279]'>: {data.customerName}</p>
                </div> */}
                <div className='w-full flex px-2'>
                  <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Days Elapsed </p>
                  <p className='w-[65%] py-2 pl-2 text-xs  text-[#606279]'>: {data.daysElapsed}</p>
                </div> 
                {/* <div className='w-full flex px-2'>
                  <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Date</p>
                  <p className='w-[65%] py-2 pl-2 text-xs  text-[#606279]'>: {data.date ? <NuDate value={data?.date} format="DD-MMM-YYYY hh:mm a" /> : '-'}</p>
                </div>  */}
                <div className='w-full flex px-2'>
                  <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Id Number</p>
                  <p className='w-[65%] py-2 pl-2 text-xs  text-[#606279]'>: {data.idNumber}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Id Type</p>
                  <p className='w-[65%] py-2 pl-2 text-xs  text-[#606279]'>: {data.idType}</p>
                </div> 
                <div className='w-full flex px-2'>
                  <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Mobile</p>
                  <p className='w-[65%] py-2 pl-2 text-xs  text-[#606279]'>: {data.mobile.display}</p>
                </div> 
                <div className='w-full flex px-2'>
                  <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Delivery Person </p>
                  <p className='w-[65%] py-2 pl-2 text-xs ftext-[#606279]'>: {data.deliveryPersonName}</p>
                </div> 
                <div className='w-full flex px-2'>
                  <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Delivery Vehicle Type</p>
                  <p className='w-[65%] py-2 pl-2 text-xs ftext-[#606279]'>: {data.deliveryVechicleType}</p>
                </div> 
                <div className='w-full flex px-2'>
                  <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>WayBill No</p>
                  <p className='w-[65%] py-2 pl-2 text-xs  text-[#606279]'>: {data.wayBillNo}</p>
                </div> 
                <div className='w-full flex px-2'>
                  <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Delivered On</p>
                  <p className='w-[65%] py-2 pl-2 text-xs  text-[#606279]'>: {data.deliveredOn? (
    <NuDate value={data?.deliveredOn} format="DD-MMM-YYYY " /> 
  ) : (
    "N/A"
  )}</p>
                </div>    
                 {/* <div className='w-full flex px-2'>
                <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Approval Status</p>
                <p className='w-[65%] py-2 pl-5 text-xs text-[#606279]'>: {data.approvalStatus}</p>
              </div>  */}        
                {/* <div className='w-full flex px-2'>
                  <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Delivery Status</p>
                  <p className='w-[65%] py-2 pl-2 text-xs  text-[#606279]'>: {data.deliveryStatus}</p>
                </div>  */}
                <div className='w-full flex px-2'>
                  <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Comments</p>
                  <p className='w-[65%] py-2 pl-2 text-xs  text-[#606279]'>: {data.comments}</p>
                </div> 
          </div>
        </div>
        <div className='flex'>

        {/* <div onClick={handlePdfIconClick} className="cursor-pointer m-2 border border-gary p-4 rounded-lg w-[10%]">
          <svg
            width="30"
            height="30"
            fill="#048178"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 144-208 0c-35.3 0-64 28.7-64 64l0 144-48 0c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z" />
          </svg>
        </div> */}
         <div className="w-[50%] ">
          <p className="  text-xs font-medium text-[#111537] p-1">
          Delivery-Notes Attachments
            </p>
            <div className=" border border-gary  overflow-y-scroll h-28 rounded-lg p-2">

{/* <NormalHeading title={"DeliveryNote Attachement"} /> */}
{/* <div className="md:flex justify-between mt-5 px-3 w-full"> */}
<div className="w-full flex gap-2 items-center flex-wrap mt-1 ">
{data?.attachments &&
                                    filePathList.map((imgs, idx) => {
                                        console.log('img', imgs);
                                        return (
                    <FilesView fileView={imgs} key={idx}/>

                                      //     <div
                                      //     className=" w-10 h-11 relative flex justify-center items-center rounded-lg"
                                      //     key={idx}
                                      //     style={{ border: "1px solid gray", borderColor: "rgb(148 163 184 / var(--tw-bg-opacity))" }}
                                      // >
                                      //   <div className=" w-28 h-11 flex justify-center items-center rounded-lg overflow-hidden cursor-pointer" onClick={() => handleImageClick(imgs)}>
                                      //               {/* {imgs && <ViewFiles data={imgs} key={idx} />} */}
                                      //               <img src={imgs?.file} alt={imgs?.file} />
                                      //   </div>
                                      //   </div>
                                                  );
                                                })}
            
                            </div>
    </div>
</div>

        </div>
       {/* <div className="w-full h-0.5 opacity-30 bg-[#C4C2C2] my-6 mx-auto"></div> */}

       <div className="w-full bg-white  border border-gary  mt-4 rounded-lg ">
       
  <div className="w-full py-2 bg-baseColor-primary flex justify-between rounded-lg">
  <p className="w-[5%] pl-2 text-left  text-white text-xs font-medium">S.N. </p>

  <p className="w-[40%]  text-left text-white text-xs font-medium">Product</p>
                <p className="w-[10%] text-center text-white text-xs font-medium">Unit</p>
                <p className="w-[15%] text-center text-white text-xs font-medium">Ordered Qty</p>
                <p className="w-[15%] text-center text-white text-xs font-medium">Packed Qty</p>
                <p className="w-[15%] text-center text-white text-xs font-medium">Remaining Qty</p>
               </div>
  {data?.products?.map((List, index) => {
    return (
      <div key={index} className="w-full py-2 flex justify-between odd:bg-white even:bg-slate-100">
       <p className="w-[5%]  text-left pl-2   text-xs font-light ">
                                    {index + 1}
                                </p>
        <p className="w-[40%] text-left  text-xs font-light ">
          {List?.product_name}
        </p>
        <p className="w-[10%] text-center  text-xs font-light ">
          {List?.product_unit}
        </p>
        <p className="w-[15%] text-center  text-xs font-light ">
          {List?.orderedQty}
        </p>
        <p className="w-[15%] text-center  text-xs font-light ">
          {List?.packedQty}
        </p>
        <p className="w-[15%] text-center  text-xs font-light ">
          {List?.remainingQty}
        </p>
      </div>
    );
  })}
</div>
</div>
</div>
{/* <div className="w-full h-0.5 opacity-30 bg-[#C4C2C2] my-6 mx-auto"></div> */}
<div className="w-[30%] bg-white rounded-lg drop-shadow-lg py-1">
        <div className="h-[98%] overflow-y-scroll p-2 ">

        <ApprovalComponent id={id} getUrl={getUrl} putUrl={putUrl} statusUrl={statusUrl} refSetData={setData} data={data} type={"DN"} preUrlName={"delivery-notes"}/>

         
          <div className="w-full grid border border-gary mt-4  rounded-lg">
          {/* <p className="text-xs text-[#111537] font-medium">Customer Detail</p> */}
          <div className="bg-baseColor-primary  p-2 rounded-t-lg">
            <p className="text-xs text-white font-medium">Delivery Detail</p>

            </div>
          <div className='w-full flex px-2 justify-between'>
                  <p className='w-[35%] py-2 text-xs font-medium text-[#111537]'>Customer </p>
                  <p className='w-[65%] py-2  text-xs  text-[#606279] truncate'> : {data.customerName}</p>
                </div>
          <div className='w-full flex px-2 justify-between'>
                  <p className='w-[35%] py-2 text-xs font-medium text-[#111537]'>Sales Order</p>
                  <p className='w-[65%] py-2  text-xs  text-[#606279] truncate'> : {data.soRef}</p>
                </div> 
              
                <div className="w-full flex px-2 justify-between">
              <p className="w-[35%] py-2 text-xs font-medium text-[#111537]"> 
                YPR
              </p>
              <p className="w-[65%]  py-2 text-xs  text-[#606279] truncate">
               : {data.prRef}
              </p>
            </div>
           </div>
           <div className="w-full grid  border border-gary mt-4 rounded-lg ">
           <p className="text-xs text-white font-medium bg-baseColor-primary  p-2 rounded-t-lg">Documents </p>
           <div className=" w-full flex flex-wrap overflow-y-scroll h-16">

           {/* <div onClick={handlePdfIconClick} className="cursor-pointer m-2 ">
          <svg
            width="30"
            height="30"
            fill="#048178"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 144-208 0c-35.3 0-64 28.7-64 64l0 144-48 0c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z" />
          </svg>
        </div> */}

<div onClick={handlePdfIconClick} className="cursor-pointer m-1 w-auto h-[25px] flex items-center  justify-between  rounded p-[2px] border ">
          <svg
            width="20"
            height="20"
            fill="#048178"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 144-208 0c-35.3 0-64 28.7-64 64l0 144-48 0c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z" />
          </svg>
          <p className=' w-auto text-[10px]'>{data?.ref}</p>
        </div>

        </div>
        </div>
        </div>
        </div>
   
<Modal show={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <img src={selectedImage?.file} alt={selectedImage?.file} />
      </Modal>
      {showPdfComponent && (
   <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
   <div className="animate-scale-in  relative w-[99%] h-[99%] bg-white py-2 px-4 rounded-md shadow-lg overflow-y-scroll ">
     <button
               className="absolute top-2 right-2 text-white bg-red-600"
               onClick={() => setShowPdfComponent(false)}
        >
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      {/* <DNPdfOthers data={data} /> */}
      <PDFViewer pdfFile={viewPDF}/>

      {/* <DNPdfNu/> */}
      {/* <div className="flex justify-end gap-2 ">
                <CancelButton handleClick={closeFilterDialog} />
             
              </div> */}
    </div>
  </div>
)}
</div>   
      </div>        
    )
  };

export default ViewDeliveryNotes