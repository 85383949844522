import React, { useEffect, useMemo, useState } from "react";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import ModalContainer from "../../../../Components/Others/ModalContainer";
import NuAmount from "../../../../Components/Utils/NuAmount";
import {
  TextMediumSM,
  TextMediumXS,
} from "../../../../Components/Text/MediumText";
import { NuInput } from "../../../../Components/Utils/Input";
import { BackButton, CreateButton } from "../../../../Components/Utils/Buttons";
import { TextXS } from "../../../../Components/Text/NormalText";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import axiosInstance from "../../../../Services/axiosCommon";
import { useFormik } from "formik";
import { useToast } from "../../../../Components/Others/ToastServices";
import UseDropDown from "../../../../Components/Hooks/useDropDown";
import * as yup from "yup";
import FormikSelectTwo, { TwoNEW } from "../../../../Components/Utils/Select";
import {
  NormalHeading,
  SecondHeading,
  SmallHeading,
  SmallsubHeading,
} from "../../../../Components/Utils/Heading";
import NuDate from "../../../../Components/Utils/NuDate";
import ViewQuotesByPr from "./quotView";
import UseDropDownPR from "../../../../Components/Hooks/UserddNewPr";

function MainQuoteComparision() {
  const [dropDownData, setDropDownData] = useState({ prId: "" });
  const [startLoading, setStartLoading] = useState(true);
  const [fullview,setFullview]= useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: dropDownData,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
  });
  const [search, setSearch] = useState("");

  const { data: PR  } = UseDropDownPR(`/customer-quotations/PR-drop-down?search=${search}`);

  const [Prlst, setPrlist] = useState();

  const getPr = (prId) => {
    if (prId !== "") {
      axiosInstance
        .get(`/PR/${prId}`)
        .then((res) => {
          let data = res.data.data;
          setPrlist(data);
        })
        .catch((err) => {
          console.log(err, "err");
        });
    }
  };

  useEffect(() => {
    if (formik.values.prId) {
      getPr(formik.values.prId);
      setStartLoading(false);
    }
  }, [formik.values.prId]);
  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between ">
        <Breadcrumb
          items={[
            {
              name: "Procurement",
            },
            {
              name: "Quote Comparison",
            },
            {
              // name: "View Quotes",
            },
          ]}
        />
        <div className="flex gap-2">
          <BackButton />
        </div>
      </div>
      <div className="w-full overflow-x-auto max-w-full bg-white rounded-md mt-4">
        <div className=" w-[calc(100vw-250px)] overflow-scroll">
          <div className="w-full flex justify-between">
            <div className="w-9/12 flex gap-1 items-center px-4 py-2">
              <SmallHeading title={"YPR"} />

              <TwoNEW
                type="text"
                //   label="YPR"
                placeholder="Choose"
                Options={PR}
                formik={formik}
                name="prId"
                setSearch={setSearch}
                  FixPosition={false}
                  PortalTarget={false}
                width="w-[45%]"
              />
            </div>
            <div className="flex justify-end items-center">
              {formik?.values?.prId &&
              // <Link to={`/procurement/quote-comparison/view/${formik?.values?.prId}`}>
              <button className=" px-3 h-8 bg-baseColor-primary text-white text-xs flex items-center rounded-md cursor-pointer"
               onClick={()=>setFullview(true)}
             >
                <p className="w-full text-center">Full Screen View</p>
              </button>
            // </Link>
              }
            </div>
          </div>
          {/* <div className=" w-full flex flex-wrap ">
        {formik.values.prId && (
          <div className=" w-full grid grid-cols-4 gap-x-8 mx-4">
        <div className='w-full flex '>
            <p className='w-[40%] lg:w-[30%] md:w-1/2 py-3 text-sm md:text-sm lg:text-xs xl:text-sm font-medium text-[#111537]'>Customer</p>
            <p className='w-auto py-3 truncate text-sm md:text-sm lg:text-xs xl:text-sm pl-5 text-[#606279]'>{Prlst?.customerName||"-"}</p>
          </div>
          <div className='w-full flex '>
            <p className='w-[50%] md:w-[50%] lg:w-[41%] py-3 text-sm md:text-sm lg:text-xs xl:text-sm font-medium text-[#111537]'>Dead Line</p>
            <p className='w-auto py-3 truncate text-sm md:text-sm lg:text-xs xl:text-sm pl-5 text-[#606279] relative group'>
              {Prlst?.deadLine ? <NuDate value={Prlst?.deadLine} format="DD-MMM-YYYY hh:mm a" /> : '-'}
              <span className="absolute left-0 top-full mb-1 hidden w-auto max-w-xs px-2 py-1 bg-gray-700 text-white text-xs rounded hover:block">
                {Prlst?.deadLine ? <NuDate value={Prlst?.deadLine} format="DD-MMM-YYYY hh:mm a" /> : '-'}
              </span>
            </p>
            <p className='w-[70%] py-3 truncate text-sm md:text-sm lg:text-xs xl:text-sm pl-5 text-[#606279]'>{Prlst?.deadLine ? <NuDate value={Prlst?.deadLine} format="DD-MMM-YYYY hh:mm a" /> : '-'}</p>
          </div>
      </div>
      </div> */}
          <div className=" h-0.5 opacity-30 bg-[#C4C2C2] my-2 ml-4"></div>

          <div className="h-[calc(100vh-250px)] overflow-y-scroll">
            {formik.values.prId && (
              <ViewQuotesByPr
                viewqoutesId={formik.values.prId}
                startLoading={startLoading}
                fullview={fullview}
                setFullView={setFullview}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainQuoteComparision;
