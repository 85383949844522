import React, { useEffect, useState } from "react";
import CustomDataTable from "../../../../Components/Others/CustomDataTable";
import {
  CancelButton,
  CreateButton,
  FilterButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import { Link, useLocation } from "react-router-dom";
import SearchBar from "../../../../Components/Utils/SearchBar";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import UseGetData from "../../../../Components/Hooks/useFetchData";
import UseGetDataSearch from "../../../../Components/Hooks/useFetchDataBySearch";
import FormikSelectTwo from "../../../../Components/Utils/Select";
import { useFormik } from "formik";
import * as yup from "yup";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import { NuDate } from "../../../../Components/Utils/Input";
import ModalContainer from "../../../../Components/Others/ModalContainer";
import MultiEditSO from "./MultiEditSO";
import { GetAccess, GetNotUseRole } from "../../../../Components/Utils/roles";
import useFetchMDIndex from "../../../../Components/Hooks/useFetchMDIndex";
import UseDropDown from "../../../../Components/Hooks/useDropDown";

function SalesOrdersIndex() {
 
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(localStorage.getItem("perPageView")||20);
  const location = useLocation();
  const state = location.state;
  const [filter, setFilter] = useState(
    state?.fromDate || state?.toDate ? true : false
  );
    const [showFilterDialog, setShowFilterDialog] = useState(false);
  const { value: ApprovalStatus, getData: refApprovalStatus } =
  useFetchMDIndex(
    `/dropdown/dropdown-details/`,
    "ApprovalStatus_SalesOrder"
  ,showFilterDialog);
// const {value:CustomerDiscount ,getData:refCustomerDiscount} = useFetchMDIndex(`/dropdown/dropdown-details/`,'CustomerDiscount_SalesOrder',showFilterDialog)
const { value: PaymentStatus, getData: refPaymentStatus } = useFetchMDIndex(
  `/dropdown/dropdown-details/`,
  "PaymentStatus_SalesOrder"
,showFilterDialog);
const { value: TermsAndConditions, getData: refTermsAndConditions } =
  useFetchMDIndex(
    `/dropdown/dropdown-details/`,
    "termsAndConditions_SalesOrder"
  ,showFilterDialog);
const { value: ShippingMethod, getData: refShippingMethod } =
  useFetchMDIndex(
    `/dropdown/dropdown-details/`,
    "shippingMethod_SalesOrder"
  ,showFilterDialog);
  const { value: status } = useFetchMDIndex(`/dropdown/dropdown-details/`, 'Status_SalesOrder',showFilterDialog);
  const { data: RFQAssignee } = UseDropDown('/user/procurement/drop-down');
  const { data: Salesman } = UseDropDown('/user/salesman/drop-down');

  const [filterProps, setFilterProps] = useState({
    paymentStatus: "",
    status: "",
    shippingMethod: "",
    fromDate:"",
    toDate: "",
    rfqAssignee:"",
    salesPerson:"",
    approvalStatus:"",
    termsAndConditions: "",
    
  });
  const [params, setParams] = useState({
    page: page,
    per_page: pageCount,
    search: "",
    status: "",
    paymentStatus: "",
    shippingMethod: "",
    fromDate:  state?.fromDate ? state.fromDate :"",
    toDate: state?.toDate ? state.toDate :"",
    rfqAssignee:"",
    salesPerson:"",
    approvalStatus:"",
    termsAndConditions: "",
  });

  const clearFilter = () => {
    setParams({
      ...params,
      division: "",
      status: "",
      paymentStatus: "",
      shippingMethod: "",
      fromDate: "",
      toDate: "",
      rfqAssignee:"",
      salesPerson:"",
      approvalStatus:"",
      termsAndConditions: "",
    });
    formik.values.status = "";
    formik.values.paymentStatus = "";
    formik.values.shippingMethod = "";
    formik.values.fromDate = "";
    formik.values.toDate = "";
    formik.values.rfqAssignee = "";
    formik.values.salesPerson = "";
    formik.values.approvalStatus = "";

    formik.values.termsAndConditions = "";

    setFilter(false);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterProps,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      console.log("ffff", value);
      setParams({
        ...params,
        paymentStatus: value.paymentStatus,
        shippingMethod: value.shippingMethod,
        status: value.status,
        fromDate: value.fromDate,
        toDate: value.toDate,
        rfqAssignee: value.rfqAssignee,
        salesPerson: value.salesPerson,
        approvalStatus: value.approvalStatus,

        termsAndConditions: value.termsAndConditions,
      });
      closeFilterDialog();
      setFilter(true);
      setFilterProps({
        paymentStatus: "",
        status: "",
        shippingMethod: "",
        fromDate: "",
        toDate: "",
        rfqAssignee:"",
        salesPerson:"",
        approvalStatus:"",
        termsAndConditions: "",
      });
    },
  });

  const { data, totalCount, getData,dataLoading } = UseGetDataSearch(
    "/sales-order/",
    params
  );
  const defaultHeader = [
    // Uncomment this section if the ID column is required.
    // {
    //   title: "ID",
    //   width: "5%",
    //   field: "id",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    {
      title: "YPR",
      width: "10%",
      isLink: true,
      id: "id",
      link: '/sales/sales-orders/view/',
      field: "prRef",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "SO No.",
      width: "10%",
      isLink: true,
      id: "id",
      link: '/sales/sales-orders/view/',
      field: "ref",
      textAlign: "center",
      rowAlign: "center",
    },
    // Conditionally include the "Customer" column.
    ...(GetNotUseRole("procurement")
      ? [
          {
            title: "Customer",
            width: "10%",
            field: "customerName",
            textAlign: "center",
            rowAlign: "center",
          },
        ]
      : []),
    {
      title: "Purchasers",
      width: "10%",
      field: "purchasers",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Busy SO No.",
      width: "10%",
      field: "busySoNo",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Payment Status",
      width: "10%",
      field: "paymentStatus",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Salesman",
      width: "10%",
      field: "salesPersonName",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Status",
      width: "10%",
      field: "status",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Action",
      width: "5%",
      field: "action",
      textAlign: "center",
      rowAlign: "center",
      contents: {
        // Add your fetcher function here if needed.
        id: "id",
        removeValue: "ref",
        url: `/sales-order/`,
        moduleName: "Sales-Orders",
        from: `/sales/sales-orders`,
        editLink: "/sales/sales-orders/edit/",
        viewLink: "/sales/sales-orders/view/",
      },
    },
  ];
  
  const availableHeaders = [
    // {
    //   title: "ID",
    //   width: "5%",
    //   field: "id",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    {
      title: "YPR",
      width: "10%",
      isLink: true,
      id: "id",
      link: '/sales/sales-orders/view/',
      field: "prRef",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "SO No.",
      width: "10%",
      isLink: true,
      id: "id",
      link: '/sales/sales-orders/view/',
      field: "ref",
      textAlign: "center",
      rowAlign: "center",
    },
    ...(GetNotUseRole("procurement")
    ? [
        {
          title: "Customer",
          width: "10%",
          field: "customerName",
          textAlign: "center",
          rowAlign: "center",
        },
      ]
    : []),
    {
      title: "Purchasers",
      width: "10%",
          field: "purchasers",
      textAlign: "center",
      rowAlign: "center",
    },
    {

      title: "Busy SO No.",
      width: "10%",     
      field: "busySoNo",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Payment Status",
      width: "10%",
      field: "paymentStatus",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Salesman",
      width: "10%",
      field: "salesPersonName",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Status ",
      width: "10%",
      field: "status",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Customer Quote ",
      width: "10%",
      field: "CQRef",
      textAlign: "center",
      rowAlign: "center",
    },
   
    {
      title: "Customer Order ",
      width: "10%",
      field: "cusOrderRef",
      textAlign: "center",
      rowAlign: "center",
    },
    
    
    {
      title: "Received On  ",
      width: "10%",
      type:"date",
      field: "orderReceivedOn",
      textAlign: "center",
      rowAlign: "center",
    },
   
   
   
    {
      title: "Shipping Method",
      width: "10%",
      field: "shippingMethod",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Terms and Conditions",
      width: "10%",
      field: "termsAndConditions",
      textAlign: "center",
      rowAlign: "center",
    },
    // {
    //   title: "Sub-Total",
    //   width: "10%",
    //   field: "subTotal",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
   
    {
      title: "Requested Delivery",
      width: "10%",
      field: "requestedDeliveryDate",
      type:"date",
      textAlign: "center",
      rowAlign: "center",
    },

    {
      title: "Delivery Address",
      width: "10%",
      field: "deliveryAddress",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Billing Address ",
      width: "10%",
      field: "billingAddress",
      textAlign: "center",
      rowAlign: "center",
    },
   
    // {
    //   title: "Approval By",
    //   width: "10%",
    //   field: "approvedByName",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    // {
    //   title: "Approval Status",
    //   width: "10%",
    //   field: "approvalStatus",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    // {
    //   title: "Requested By",
    //   width: "10%",
    //   field: "requestedByName",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
   
   
    // {
    //   title: "Shipping Instruction",
    //   width: "10%",
    //   field: "shippingInstruction",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
  

   

    {
      title: "Action",
      width: "5%",
      field: "action",
      textAlign: "center",
      rowAlign: "center",
      contents: {
        // fetcher: getData,
        id: "id",
        removeValue: "ref",
        url: `/sales-order/`,
        moduleName: "Sales-Orders",
        from: `/sales/sales-orders`,
        editLink: "/sales/sales-orders/edit/",
        viewLink: "/sales/sales-orders/view/",
      },
    },
  ];
  useEffect(() => {
    setParams({...params,page:page,per_page:pageCount})
  }, [page, pageCount]);

  const closeFilterDialog = () => {
    setShowFilterDialog(false);
  };

  const [showEdit, setShowEdit] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [listofNumbers, setListofNumbers] = useState([])
  const [fullList, setFullList] = useState([])

  const selectOneFunction = (value) =>{ 
    let NewId;
    if(listofNumbers && listofNumbers.some(Item=>Item ===value)){
      NewId = listofNumbers.filter(Temp=> Temp !== value);
      setListofNumbers(NewId)
    }else{
      setListofNumbers([...listofNumbers,value])
    }
  }

  const selectMultipleFunction = (e) =>{ 
console.log(e.target.checked);
if(e.target.checked){
  setListofNumbers(fullList)
}else{
  setListofNumbers([])
}

  }

  useEffect(()=>{
    let tempArr = []
    data && data.forEach(i=>{
      console.log("I",i?.id);
      tempArr.push(i?.id)
    });
    setFullList(tempArr)
  },[data])


  return (
    <div className="w-full h-full px-4 py-2">
      <div className="w-full flex justify-between items-center">
        <Breadcrumb
          items={[
            {
              name: "Sales",
            },
            {
              name: "Sales Order",
            },
          ]}
        />
        <div className=" flex gap-2">
        <SearchBar
              handleChange={(e) =>{
                setParams({ ...params, search: e.target.value, page:1 });
                setPage(1)
              }}
            />
          <FilterButton handleClick={() => setShowFilterDialog(true)} />
          {filter && (
            <FilterButton handleClick={() => clearFilter()} label="Clear" />
          )}

          {/* <CreateButton label="Import" /> */}
          {GetAccess("sales_order_create") &&  <Link to="/sales/sales-orders/create/">
            <CreateButton label="Sales-Order" />
          </Link>}
        </div>
      </div>
      <CustomDataTable
        fetcher={getData}
        data={data}
        availableHeaders={availableHeaders}
        defaultHeader={defaultHeader}
        bodyHeight="max-h-[calc(100vh-260px)]"
        height="h-[calc(100vh-145px)]"
        total={totalCount}
        pageIndex={page}
        setPageIndex={setPage}
        dataCount={pageCount}
        setDataCount={setPageCount}
        title="Sales Orders List"
        storageName="SalesOrdersTable"
        selectOneFunction={selectOneFunction}
        setShowDelete={setShowDelete}
        showDelete={showDelete}
        setShowEdit={setShowEdit}
        showEdit={showEdit}
        listofNumbers={listofNumbers}
        hasMultiEdit={true}
        selectMultipleFunction={selectMultipleFunction}
        fullList={fullList}
        getById="id"
        editAccess="sales_order_edit"      
        deteleAccess="sales_order_delete" 
        dataLoading={dataLoading}
      />
      {showFilterDialog && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
          <div className="animate-scale-in min-w-[40%] max-w-[80%] max-h-[80vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
            <h2 className="text-xl mb-4">Filter Sales Orders</h2>
            <form onSubmit={formik.handleSubmit}>
              <div className="flex flex-col mb-4">
                <p className="py-2 px-6 text-m">Date</p>
                <div className="flex justify-between  w-full ">
                  <NuDate
                    label="From "
                    placeholder="dd/mm/yyyy"
                    formik={formik}
                    name="fromDate"
                    width="w-full "
                  />
                  <NuDate
                    label="To "
                    placeholder="dd/mm/yyyy"
                    formik={formik}
                    name="toDate"
                    width="w-full "
                  />
                </div>
                <FormikSelectTwo
                  label="Payment Status"
                  placeholder="Choose"
                  formik={formik}
                  Options={PaymentStatus}
                  name="paymentStatus"
                  width="w-full "
                />
                 <FormikSelectTwo
              // type="text"
              label="RFQ Assignee"
              placeholder="RFQ Assignee"
              Options={RFQAssignee}
              formik={formik}
              name="rfqAssignee"
              width="w-full "
            />
             <FormikSelectTwo
                type="text"
                label="Sales Person"
                placeholder="Choose"
                Options={Salesman}
                formik={formik}
                name="salesPerson"
                width="w-full"
              />
               
                <FormikSelectTwo
                  type="text"
                  label="Shipping Method"
                  placeholder="Choose"
                  Options={ShippingMethod}
                  formik={formik}
                  name="shippingMethod"
                  width="w-full"
                />
                <FormikSelectTwo
                  type="text"
                  label="Terms And Conditions"
                  placeholder="Choose"
                  Options={TermsAndConditions}
                  formik={formik}
                  name="termsAndConditions"
                  width="w-full"
                />
                 <FormikSelectTwo
                  type="text"
                  label="Status"
                  placeholder="Choose"
                  Options={status}
                  formik={formik}
                  name="status"
                  width="w-full "
                />
                 {/* <FormikSelectTwo
              type="text"
              label="Approval Status"
              placeholder="Approval Status"
              Options={[
                {
                  value: "Pending",
                  label: "Pending",
                },
                {
                  value: "Verified",
                  label: "Verified",
                },
                {
                  value: "Approved",
                  label: "Approved",
                },
                                         

              ]}
              formik={formik}
              name="approvalStatus"
              width="w-full "
            /> */}
              </div>
              <div className="flex justify-end gap-2">
                <CancelButton handleClick={closeFilterDialog} />
                <SubmitButton name="Apply" />
              </div>
            </form>
          </div>
        </div>
      )}
                         {showEdit && (<MultiEditSO data={listofNumbers} getData={getData} onClose={()=>setShowEdit(false)}/>)}
        <ModalContainer
                visiable={showDelete}
                title="Warning"
                content={`Are you sure, All selected datas will be removed from YPR List!`}
                handleClick={() =>{}}
                closeModal={() => setShowDelete(false)}
            />
    </div>
  );
}
export default SalesOrdersIndex;
