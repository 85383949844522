import React from 'react'
import Breadcrumb from '../../../../Components/Utils/Breadcrumb'
import { BackButton } from '../../../../Components/Utils/Buttons'
import PurchaseRequestPermission from './PurchaseRequestPermission'
import RFQPermission from './RFQPermission'
import SupplierQuotesPermission from './SupplierQuotes'
import CustomerQuotesPermission from './CustomerQuotesPermission'
import CustomerOrderPermmision from './CustomerOrderPermmision'
import PurchaseOrderPermission from './PurchaseOrderPermission'
import InvoiceOutPermission from './InvoiceOutPermission'
import DeliveryNotesPermmision from './DeliveryNotesPermmision'
import InvoiceInPermission from './InvoiceInPermission'
import GRNPermission from './GRNPermmision'
import SupplierPermission from './SupplierPermission'

function MainStatusPermission() {
    return(
        <div className='w-full h-full px-4 py-2'>
          <div className='w-full flex justify-between items-center'>
          <Breadcrumb
            items={[
              {
                name: "Settings",
              },
              {
                name: "Approval Permission"
              }
            ]}
          />
          <BackButton/>
          </div>
          <div className="w-full h-[calc(100vh-145px)] bg-white mt-3 mx-auto rounded-md overflow-y-scroll">
          <div className="p-3">
            <PurchaseRequestPermission/>
          </div>
          <div className="p-3">
            {/* <RFQPermission/> */}
            <PurchaseOrderPermission/>
          </div>
          <div className="p-3">
            <CustomerQuotesPermission/>
          </div>
          {/* <div className="p-3">
            <SupplierQuotesPermission/>
          </div> */}
          {/* <div className="p-3">
            <CustomerOrderPermmision/>
          </div> */}
          <div className="p-3">
            <GRNPermission/>
          </div>
          <div className="p-3">
            <DeliveryNotesPermmision/>
          </div>
          <div className="p-3">
            <InvoiceInPermission/>
          </div>
          <div className="p-3">
            <InvoiceOutPermission/>
          </div>
          <div className="p-3">
            <SupplierPermission/>
          </div>
          </div>
          </div>
      )
}

export default MainStatusPermission