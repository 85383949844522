import React, { useEffect, useState } from 'react';
import DataTable from '../../../../Components/Others/DataTable';
import { CancelButton, CreateButton, FilterButton, ImportButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { Link, useLocation } from 'react-router-dom';
import SearchBar from '../../../../Components/Utils/SearchBar';
import UseGetData from '../../../../Components/Hooks/useFetchData';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import MoreOptions from '../../../../Components/Utils/MoreOptions';
import CustomDataTable from '../../../../Components/Others/CustomDataTable';
import List from '../../../../Components/Icons/list';
import Card from '../../../../Components/Icons/card';
import CustomerCard from './CustomerCard';
import UseGetDataSearch from '../../../../Components/Hooks/useFetchDataBySearch';
import Pagination from '../../../../Components/Utils/Pagenation';
import FormikSelectTwo from '../../../../Components/Utils/Select';
import { useFormik } from 'formik';
import * as yup from 'yup';
import useFetchMetaStar from '../../../../Components/Hooks/useFetchMetaStar';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
import { NuInput } from '../../../../Components/Utils/Input';
import { CountryMIN } from '../../../../assets/CountryMini';
import Importdoc from './Importdoc';
import { GetAccess, GetRole } from '../../../../Components/Utils/roles';
import useFetchMDIndex from '../../../../Components/Hooks/useFetchMDIndex';
import useFetchMetaStarIndex from '../../../../Components/Hooks/useFetchMetaStarIndex';

function CustomersIndex() {
   const [open, setOpen] = useState(false);
   const location = useLocation();
   const state = location.state;
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(localStorage.getItem("perPageView")||20)
  const [filter, setFilter] = useState(state?.industry?true:false);
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const {value:customerRating} = useFetchMetaStarIndex(`/dropdown/dropdown-details/`,'customerRating_Customer',showFilterDialog)
  const {value:Status} = useFetchMDIndex(`/dropdown/dropdown-details/`,'Status_Customer',showFilterDialog)
  const {value:customerType} = useFetchMDIndex(`/dropdown/dropdown-details/`,'customerType_Customer',showFilterDialog)
  const {value:Industry} = useFetchMDIndex(`/dropdown/dropdown-details/`,'Industry_Customer',showFilterDialog)
  const {value:Category} = useFetchMDIndex(`/dropdown/dropdown-details/`,'category_Customer',showFilterDialog)

  const [filterProps, setFilterProps] = useState({
    country:"",
    category:"",
    paymentTerm:"",
    ratings:"",
    status:"",
    industry:"",
    customerType:"",
    approvalStatus:"",

  });
  const [params, setParams] = useState({
    page: page,
    per_page: pageCount,
    search: "",
    searchBy: "",
    country:"",
    // category:state?.category? state.category :"",

    category:"",
    paymentTerm:"",
    ratings:"",
    status:"",
    industry:state?.industry? state.industry :"",
    customerType:"",
    approvalStatus:"",

  });
  const clearFilter = ()=> {
    setParams({...params, country:"",
    category:"",
    paymentTerm:"",
    ratings:"",
    status:"",
    industry:"",
    approvalStatus:"",

    customerType:""})
    formik.values.status=""
    formik.values.customerType=""
    formik.values.category=""
    formik.values.paymentTerm=""
    formik.values.industry=""
    formik.values.country=""
    formik.values.ratings=""
    formik.values.approvalStatus=""



      setFilter(false)
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterProps,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      console.log("ffff",value);
      setParams({...params,country:value.country, industry:value.industry,paymentTerm:value.paymentTerm,category:value.category,ratings:value.ratings,status:value.status,customerType:value.customerType,approvalStatus:value.approvalStatus})
      closeFilterDialog();
      setFilter(true)
      setFilterProps({
        country:"",
        category:"",
        paymentTerm:"",
        ratings:"",
        status:"",
        industry:"",
        customerType:"",
        approvalStatus:""
      });
    }
  })

  const { data, totalCount, getData,dataLoading } = UseGetDataSearch( "/customers/", params );
  const [viewMode, setViewMode] = useState(localStorage.getItem("viewMode") || "card");
  const defaultHeader = [
    {
      title: "ID",
      width: "5%",
      field: "customerId",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Company Name",
      width: "10%",
      isLink: true,
      id: "customerId",
      link: '/sales/customers/view/',
      field: "companyName",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Industry ",
      width: "10%",
      field: "industry",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Location ",
      width: "10%",
      field: "billing_city",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Contact Person",
      width: "10%",
      field: "cpName",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Mobile ",
      width: "10%",
      field: "phoneWithCC",
      // subField: "display",
      textAlign: "center",
      rowAlign: "center",
    },
   
    {
      title: "Action",
      width: "5%",
      field: "action",
      textAlign: "center",
      rowAlign: "center",
      contents:{
        // fetcher:getData,
        id:"customerId",
        removeValue:"companyName",
        url:`/customers/`,
        moduleName:"Customers",
        from:`/sales/customers`,
        editLink:"/sales/customers/edit/",
        viewLink:"/sales/customers/view/"

      }
    },
  ];
  const availableHeaders = [
    {
      title: "ID",
      width: "5%",
      field: "customerId",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Company Name",
      width: "10%",
      isLink: true,
      id: "customerId",
      link: '/sales/customers/view/',
      field: "companyName",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Industry ",
      width: "10%",
      field: "industry",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Location ",
      width: "10%",
      field: "city",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Contact Person",
      width: "10%",
      field: "cpName",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Mobile ",
      width: "10%",
      field: "phoneWithCC",
      // subField: "display",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Customer Type ",
      width: "10%",
      field: "customerType",
      textAlign: "center",
      rowAlign: "center",
    },
   
    {
      title: "Email",
      width: "10%",
      field: "email",
      textAlign: "center",
      rowAlign: "center",
    },
   
    {
      title: "Website",
      width: "10%",
      field: "website",
      textAlign: "center",
      rowAlign: "center",
    },
   
    // {
    //   title: "Billing Address ",
    //   width: "10%",
    //   field: "billingAddress",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    // {
    //   title: "Shipping Address ",
    //   width: "10%",
    //   field: "shippingAddress",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    {
      title: "CR Num ",
      width: "10%",
      field: "crNum",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Vat Num ",
      width: "10%",
      field: "vatNum",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Ratings ",
      width: "10%",
      field: "ratings",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Payment Term ",
      width: "10%",
      field: "paymentTerm",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Credit Limit ",
      width: "10%",
      field: "creditLimit",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Designation ",
      width: "10%",
      field: "designation",
      textAlign: "center",
      rowAlign: "center",
    },
    // {
    //   title: "Comments ",
    //   width: "10%",
    //   field: "comments",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    {
      title: "Country ",
      width: "10%",
      field: "country",
      textAlign: "center",
      rowAlign: "center",
    },
   
    {
      title: "State ",
      width: "10%",
      field: "state",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "District ",
      width: "10%",
      field: "district",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Postal Code",
      width: "10%",
      field: "postalCode",
      textAlign: "center",
      rowAlign: "center",
    },
   
   
    {
      title: "Contact Person Email ",
      width: "10%",
      field: "cpEmail",
      textAlign: "center",
      rowAlign: "center",
    },
     
    {
      title: "Contact Person No.",
      width: "10%",
      field: "cpPhoneWithCC",
      textAlign: "center",
      rowAlign: "center",
    },
   
    {
      title: "Action",
      width: "5%",
      field: "action",
      textAlign: "center",
      rowAlign: "center",
      contents:{
        // fetcher:getData,
        id:"customerId",
        removeValue:"companyName",
        url:`/customers/`,
        moduleName:"Customers",
        from:`/sales/customers`,
        editLink:"/sales/customers/edit/",
        viewLink:"/sales/customers/view/"

      }
    },
  ];
  useEffect(()=>{
    localStorage.setItem("viewMode", viewMode);
     setParams({...params,page:page,per_page:pageCount})
 },[page,pageCount,viewMode])

 const closeFilterDialog = () => {
  setShowFilterDialog(false);
};
const [showImportModal, setShowImportModal] = useState(false);

  const handleImportClick = () => {
    setShowImportModal(true);
  };

  const closeModal = () => {
    setShowImportModal(false);
  };

  return (
    <div className='w-full h-full px-4 py-2'>
      <div className='w-full flex justify-between items-center'>
        <Breadcrumb items={[
          {
            name: "Sales",

          },
          {
            name: "Customer"
          }

          ]} />
        <div className=' flex gap-2'>
        <SearchBar
              handleChange={(e) =>{
                setParams({ ...params, search: e.target.value, page:1 });
                setPage(1)
              }}
            />
          <div className='flex gap-2'>
            <div onClick={() => setViewMode("list")} className={viewMode === "list" ? "bg-baseColor-primary h-8 w-10 rounded-md flex justify-center items-center text-white" : "bg-white h-8 w-10 rounded-md flex justify-center items-center cursor-pointer"}><List/></div>
            <div onClick={() => setViewMode("card")} className={viewMode === "card" ? "bg-baseColor-primary h-8 w-10 rounded-md flex justify-center items-center text-white" : "bg-white h-8 w-10 rounded-md flex justify-center items-center cursor-pointer"}><Card/></div>
          </div>
          <FilterButton  handleClick={()=>setShowFilterDialog(true)} />
          {filter && <FilterButton  handleClick={()=>clearFilter()} label='Clear'/>}
        
          {GetRole() &&   <ImportButton handleClick={handleImportClick} />}
          {GetAccess("customers_create") &&    <Link to="/sales/customers/create/"><CreateButton label='Customer' /></Link>}

        </div>
      </div>
      {viewMode === "list" ? (
      <CustomDataTable 
        fetcher={getData}
        data={data} 
        availableHeaders={availableHeaders} 
        defaultHeader={defaultHeader}
        bodyHeight='max-h-[calc(100vh-260px)]'
        height='h-[calc(100vh-145px)]'
        total={totalCount}
        pageIndex={params.page}
        setPageIndex={setPage}
        dataCount={params.per_page}
        setDataCount={setPageCount}
        title='Customers List'
        storageName="CustomersTable"
        editAccess="customers_edit"      
        deteleAccess="customers_delete" 
        dataLoading={dataLoading}
        />
      ) : (
        <>
        <div className='w-full h-[calc(100vh-145px)] rounded-md bg-white p-3 mt-4 relative overflow-y-scroll'>
       <div className='flex gap-40'>
      
        <p className='text-xs font-semibold text-[#111537] text-left'>Customers : {totalCount}</p>
        {/* <p className='text-sm'>Credit : {}</p>
         <p className='text-sm'>Advance : {}</p>
         */}

       
        </div>
          <div className='w-full flex justify-start flex-wrap md:gap-x-5 lg:gap-x-6 gap-y-6 pt-4'>
            
            {data && data.map((item, idx) => (
              <CustomerCard fetcher={getData} item={item} key={idx} />
            ))}
          </div>
        </div>
        <div className="w-full h-[40px] rounded-b-md bg-white">
          <Pagination
            total={totalCount}
            pageIndex={page}
            setPageIndex={setPage}
            dataCount={pageCount}
            setDataCount={setPageCount}
          />
        </div>
        </>
      )}
       {showFilterDialog && (
             
             
             <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
               <div className="animate-scale-in min-w-[40%] max-w-[80%] max-h-[80vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
                 <h2 className="text-xl mb-4">Filter Customer</h2>
                 <form onSubmit={formik.handleSubmit}> 
                    <div className="flex flex-col mb-4">
                    <FormikSelectTwo
              type="text"
              label="Customer Type"
              placeholder="Customer Type"
              Options={customerType}
              formik={formik}
              name="customerType"
              width="w-full "
            />
             <FormikSelectTwo
              type="text"
              label="Country"
              placeholder="Country"
              Options={CountryMIN}
              formik={formik}
              name="country"
              width="w-full "
            />
            
             <FormikSelectTwo
                type="text"
                label="Industry"
                placeholder="Choose"
                Options={Industry}
                formik={formik}
                name="industry"
                width="w-full "
              />
               <FormikSelectTwo
              label="Ratings"
              placeholder="Ratings"
              Options={customerRating}
              formik={formik}
              name="ratings"
              width="w-full "
            />
            <NuInput
              type="text"
              label="Payment Term"
              placeholder="payment Term"
              formik={formik}
              name="paymentTerm"
              width="w-full "
            />
             <FormikSelectTwo
                type="text"
                label="Category"
                placeholder="Choose"
                Options={Category}
                formik={formik}
                name="category"
                width="w-full "
              />
             <FormikSelectTwo
            type="text"
            label="Status"
            placeholder="Status"
            Options={Status}
            formik={formik}
            name="status"
            width="w-full "
          />
                     
                     
                    </div>
                   <div className="flex justify-end gap-2">
                     <CancelButton handleClick={closeFilterDialog} />
                     <SubmitButton name='Apply' />
                   </div>
                  </form> 
               </div>
             </div>
           )}
          
          {showImportModal && (<Importdoc show={showImportModal} onClose={closeModal} getData={getData} />)}
          </div>
  )
}
 
export default CustomersIndex