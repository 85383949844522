import React, { useEffect, useMemo, useState } from "react";
import {
  BackButton,
  CancelButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import DeleteIcon from "../../../../Components/Icons/Delete";
import {
  NuDataTime,
  NuDate,
  NuInput,
  NuTextArea,
  NuUpload,
} from "../../../../Components/Utils/Input";
import FormikSelectTwo from "../../../../Components/Utils/Select";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import { useFormik } from "formik";
import useEditFormData from "../../../../Components/Hooks/useEditFormData";
import UseDropDown from "../../../../Components/Hooks/useDropDown";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import axiosInstance from "../../../../Services/axiosCommon";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import { TextMediumBase } from "../../../../Components/Text/MediumText";
import NuAmount from "../../../../Components/Utils/NuAmount";
import EditIcon from "../../../../Components/Icons/Edit";
import DropDownAddMetaData from "../../../../Components/Utils/selectAddMetaData";
import { useCurrency } from "../../../../Context/CurrencyContext";
import Attachement, { videotype } from "../../../../Components/Utils/CustomAttrachmentupload";
import CloseIcon from "../../../../Components/Icons/Close";
import Video from "../../../../Components/Icons/Video";
import Doc from "../../../../Components/Icons/Doc";
import Xls from "../../../../Components/Icons/Xls";
import Txt from "../../../../Components/Icons/Txt";
import Pdf from "../../../../Components/Icons/Pdf";
import { useToast } from "../../../../Components/Others/ToastServices";
import Modal from "../../../../Components/Utils/AttachmentView";
const filetype = [
  "docx",
  "doc",
  "xlsx",
  "xls",
  "pdf",
  "txt",
  "pptx",
  "mp4",
  "mov",
  "wmv",
  "avi",
  "flv",
  "mkv",
];

function EditSalesOrders() {
  const [image, setImage] = useState([]);
  const [fileRemoveList, setFileRemoveList] = useState([]);
  const [imagepo, setImagepo] = useState([]);

  const [fileRemoveListpo, setFileRemoveListpo] = useState([]);

  const { currencySymbol } = useCurrency();
  const { data: Salesman } = UseDropDown("/user/salesman/drop-down");
  const { value: PaymentStatus } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "PaymentStatus_SalesOrder"
  );
  const { value: TermsAndConditions } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "termsAndConditions_SalesOrder"
  );
  const { value: ShippingMethod } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "shippingMethod_SalesOrder"
  );
  const { value: status, getData: refstatus } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "Status_SalesOrder"
  );
  const { data: PR } = UseDropDown("/PR/drop-down");
  const location = useLocation();
  const data = location.state.data;
  const from = location.state.from;
  const [dataa, setData] = useState(data);
  const { Oncancel, editFormData, contextHolder, isLoading } =
    useEditFormData();
  const [remove, setRemove] = useState([]);
  const [isEditQua, setIsEditQua] = useState(false);
  const [createNew, setCreateNew] = useState({
    type: data?.type,
    busySoNo: data?.busySoNo,
    orderReceivedOn: data?.orderReceivedOn || "",
    assignee: data?.assignee,
    salesPerson: data?.salesPerson,
    deadLine: data?.deadLine,
    notesFromCus: data?.notesFromCus,
    prId: data.prId,
    ref: data.ref,
    date: data.date,
    cusOrderRef: data.cusOrderRef,
    customerId: data.customerId,
    comments: data.comments,
    cusDiscount: data.cusDiscount,
    shippingInstruction: data.shippingInstruction,
    shippingMethod: data.shippingMethod || "",
    billingAddress: data.billingAddress || "",
    deliveryAddress: data.deliveryAddress,
    termsAndConditions: data.termsAndConditions,
    status: data.status,
    approvedBy: data.approvedBy,
    subTotal: data?.subTotal,
    paymentStatus: data.paymentStatus,
    requestedDeliveryDate: data.requestedDeliveryDate,
    requestedBy: data.requestedBy,
    products: data.products,
    quoteProductId: data.quoteProductId,
    approvalStatus: data.approvalStatus,
    receivedOn: data.receivedOn,
    productId: data.productId,
    quoteQty: data.quoteQty,
    removedProducts: [],
    filesToRemove: [],
    poFilesToRemove: [],

    product_name: "",
    CQRef: data.CQRef,
    customerName: data?.customerName,
  });
  const [deletedItem, setDeletedItem] = useState([]);
  const [deletedItemDropDown, setDeletedItemDropDown] = useState([]);
  const handleDelete = (productId) => {
    const updatedquoteProducts = dataa?.products.filter(
      (item) => item.productId !== productId
    ); //quoteProductId

    const deletedProduct = dataa?.products.find(
      (item) => item.productId === productId
    );

    if (deletedProduct && deletedProduct.quantity) {
      const priceToRemove = deletedProduct.price || 0;
      const quantityToRemove = deletedProduct.quantity || 0;
      const reduction = priceToRemove * quantityToRemove;
      const newsubTotal =
        updatedquoteProducts.length > 0
          ? formik.values.subTotal - reduction
          : 0;
      setData((prevState) => ({
        ...prevState,
        products: updatedquoteProducts,
      }));
      formik.setFieldValue("subTotal", newsubTotal);

      setRemove([...remove, productId]);
      setDeletedItem((prevDeletedItems) => [
        ...prevDeletedItems,
        deletedProduct,
      ]);
    }
  };

  useEffect(() => {
    let tempData = [];
    deletedItem.forEach((item) => {
      let tempObject = { value: item.productId, label: item.product_name };
      tempData.push(tempObject);
    });
    setDeletedItemDropDown(tempData);
  }, [deletedItem]);

  const handleAddDelPro = () => {
    const selectedProductId = formik.values.productId;

    // Find the selected product's details from the deletedItemDropDown (or deletedItem array)
    const selectedProduct = deletedItem.find(
      (item) => item.productId === selectedProductId
    );

    if (selectedProduct) {
      // Add the selected product to prList
      setData((prevState) => ({
        ...prevState,
        products: [...prevState.products, selectedProduct],
      }));

      // Optionally, remove the selected product from the dropdown after adding it to prList
      setDeletedItem((prevDeletedItems) =>
        prevDeletedItems.filter((item) => item.productId !== selectedProductId)
      );
    }
  };

  const [error, setError] = useState("");
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      prId: yup.string().required("YPR is requred!"),
      cusOrderRef: yup.string().required("Customer Order is requred!"),
    }),

    onSubmit: async (value, { resetForm }) => {
      // setLoading(true);
      if (dataa.products.length === 0) {
        setError("Product table is empty");
        return;
      }
      let formData = new FormData();

      const valueWithItems = {
        ...value,
        products: dataa.products,
        removedProducts: remove,
        filesToRemove: fileRemoveList,
        poFilesToRemove: fileRemoveListpo,

      };
      formData.append("json_data", JSON.stringify(valueWithItems));
      if (selectedFile !== null) {
        selectedFile.forEach((file) => {
          formData.append("attachments", file);
        });
      } else {
        formData.append("attachments", []);
      }
      if (selectedFilepo !== null) {
        selectedFilepo.forEach((file) => {
          formData.append("poAttachments", file);
        });
      } else {
        formData.append("poAttachments", []);
      }

      editFormData(
        `/sales-order/edit-sales-order/${data.id}`,
        formData,
        resetForm,
        from
      );
      // setLoading(false)
    },
  });

  const [products, setProducts] = useState([]);

  useMemo(() => {
    if (formik.values.quoteProductId !== "") {
      let tem_product = products.find(
        (i) => i.id == formik.values.quoteProductId
      );
      formik.values.price = tem_product && tem_product.price;
      formik.values.productId = tem_product && tem_product.productId;
      formik.values.quoteQty = tem_product && tem_product.quoteQty;
      formik.values.product_name = tem_product && tem_product.name;
      // console.log(tem_product,"oo");
    }
  }, [formik.values.quoteProductId]);
  const [customerQuotation, setCustomerQuotation] = useState([]);

  const getCQByPR = () => {
    axiosInstance
      .get(`/customer-quotations/drop-down/${formik?.values?.prId}`)
      .then((res) => {
        console.log("v", res?.data);
        let data = res.data;
        console.log(data, "dddd");
        let tempData = [];
        data.forEach((item) => {
          let tempObject = { value: item.id, label: item.name };
          tempData.push(tempObject);
        });
        res.data && setCustomerQuotation(tempData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function getProduct(num) {
    axiosInstance
      .get(`/sales-order/drop-down/by-PR/${num}`)
      .then((response) => {
        if (response.data) {
          let x = response.data;
          setProducts(x);
        }
      })
      .catch((error) => {});
  }

  useMemo(() => {
    if (formik.values.prId) {
      getCQByPR();
      getProduct(formik.values.prId);
    }
  }, [formik.values.prId]);

  const [finalList, setFinalList] = useState(dataa?.products);

  useEffect(() => {
    const subTotal = finalList.reduce((total, item) => {
      return total + parseFloat(item.price) * parseFloat(item.quantity || 0);
    }, 0);
    formik.setFieldValue("subTotal", subTotal);
  }, [finalList]);

  useEffect(() => {
    if (dataa.products.length > 0) {
      setError("");
    }
  }, [dataa.products]);

  useEffect(() => {
    formik.setFieldValue("subTotal", data?.subTotal);
  }, [data]);

  const handleQuantityChange = (e, index) => {
    const updatedItems = [...finalList];
    updatedItems[index].quantity = e.target.value;
    setFinalList(updatedItems);
    console.log("shsd", finalList);
  };

  const handlePriceChange = (e, index) => {
    const updatedItems = [...finalList];
    updatedItems[index].price = e.target.value;
    setFinalList(updatedItems);
    console.log("shsd", finalList);
  };

  const [selectedFile, setSelectedFile] = useState(null);
  const [filePathList, setFilePathList] = useState([]);
  const [removefilePathList, setRemoveFilePathList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const toast = useToast();

  function handleImageChange(e) {
    const maxFileSize = 200000000; // 200 MB
    const files = Array.from(e.target.files);
    let fileArr = [];
    files.map((item) => {
      if (item.size < maxFileSize) {
        fileArr.push(item);
      } else {
        toast.open({
          type: "warning",
          message: `File Size to big`,
          description: `${item.name} is Removed and not Uploaded`,
          // placement: "bottomRight",
        });
      }
    });
    let tempArr = [];
    setSelectedFile(fileArr);
    files.map((item) => {
      if (item.size < maxFileSize) {
        let fileName = item?.name;
        console.log("fname", fileName);
        let fileExtention = fileName.split(".").reverse()[0];
        if (filetype?.includes(fileExtention)) {
          tempArr.push(fileName);
        } else {
          let x = URL.createObjectURL(item);
          tempArr.push(x);
        }
      }
    });
    console.log(tempArr, "temp");
    setImage(tempArr);
  }

  const removeImages = (value) => {
    console.log("value", value);
    let List = selectedFile.filter((item) => item?.name !== value);
    console.log("LIst", List);
    setSelectedFile([...List]);
    let tempArr = [];
    List.map((item) => {
      let fileName = item?.name;
      // console.log('fname',fileName);
      // let fileExtention =  fileName.split(".").reverse()[0]
      // if(filetype?.includes(fileExtention)){
      tempArr.push(fileName);
      // }else{
      //   let x = URL.createObjectURL(item)
      //   tempArr.push(x)
      // }
    });
    setImage(tempArr);
  };
 

  useEffect(() => {
    if (
      data?.attachments !== null &&
      data?.attachments !== "None" &&
      data?.attachments !== "[]"
    ) {
      console.log(data.attachments, "ghjkllgd");
      setFilePathList(data?.attachments);
     
      setRemoveFilePathList(data?.attachments);
     
    }
  }, [data]);

  function UpdateAttachmentList(item) {
    let filterList = filePathList.filter((itemList) => itemList.file !== item);
    let removeFile = filePathList.find((itemList) => itemList.file === item);
    console.log("llll", filterList);
    setFileRemoveList([...fileRemoveList, removeFile.file]);
    setFilePathList(filterList);
  }

  function handleImageClick(img) {
    setSelectedImage(img);
    setIsModalOpen(true);
  }
   const [selectedFilepo, setSelectedFilepo] = useState(null);
  const [filePathListpo, setFilePathListpo] = useState([]);
  const [removefilePathListpo, setRemoveFilePathListpo] = useState([]);
  const [isModalOpenpo, setIsModalOpenpo] = useState(false);
  const [selectedImagepo, setSelectedImagepo] = useState(null);

  function handleImageChangepo(e) {
    const maxFileSize = 200000000; // 200 MB
    const files = Array.from(e.target.files);
    let fileArr = [];
    files.map((item) => {
      if (item.size < maxFileSize) {
        fileArr.push(item);
      } else {
        toast.open({
          type: "warning",
          message: `File Size to big`,
          description: `${item.name} is Removed and not Uploaded`,
          // placement: "bottomRight",
        });
      }
    });
    let tempArr = [];
    setSelectedFilepo(fileArr);
    files.map((item) => {
      if (item.size < maxFileSize) {
        let fileName = item?.name;
        console.log("fname", fileName);
        let fileExtention = fileName.split(".").reverse()[0];
        if (filetype?.includes(fileExtention)) {
          tempArr.push(fileName);
        } else {
          let x = URL.createObjectURL(item);
          tempArr.push(x);
        }
      }
    });
    console.log(tempArr, "temp");
    setImagepo(tempArr);
  }

  const removeImagespo = (value) => {
    console.log("value", value);
    let List = selectedFilepo.filter((item) => item?.name !== value);
    console.log("LIst", List);
    setSelectedFilepo([...List]);
    let tempArr = [];
    List.map((item) => {
      let fileName = item?.name;
      // console.log('fname',fileName);
      // let fileExtention =  fileName.split(".").reverse()[0]
      // if(filetype?.includes(fileExtention)){
      tempArr.push(fileName);
      // }else{
      //   let x = URL.createObjectURL(item)
      //   tempArr.push(x)
      // }
    });
    setImagepo(tempArr);
  };

  useEffect(() => {
    if (
      data?.poAttachments !== null &&
      data?.poAttachments !== "None" &&
      data?.poAttachments !== "[]"
    ) {
      console.log(data.poAttachments, "ghjkllgd");
      setFilePathListpo(data?.poAttachments);
     
      setRemoveFilePathListpo(data?.poAttachments);
     
    }
  }, [data]);

  function UpdateAttachmentListpo(item) {
    let filterList = filePathListpo.filter((itemList) => itemList.file !== item);
    let removeFile = filePathListpo.find((itemList) => itemList.file === item);
    console.log("llll", filterList);
    setFileRemoveListpo([...fileRemoveListpo, removeFile.file]);
    setFilePathListpo(filterList);
  }

  function handleImageClickpo(img) {
    setSelectedImagepo(img);
    setIsModalOpenpo(true);
  }


  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between ">
        <Breadcrumb
          items={[
            {
              name: "Sales Order",
              link: "/sales/sales-orders",
            },
            { name: "Edit" },
          ]}
        />
        <div className=" flex gap-2">
          <BackButton />
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-145px)] mt-4 bg-white rounded-md overflow-hidden">
          <div className=" w-full h-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto">
            <FormikSelectTwo
              type="text"
              label="YPR "
              placeholder="YPR "
              Options={PR}
              formik={formik}
              name="prId"
              disabled={true}
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
            />

            <NuInput
              type="text"
              label="Customer Quotation"
              placeholder="Customer Quotation"
              formik={formik}
              name="CQRef"
              disabled={true}
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
            />
            <NuInput
              type="text"
              label="Busy So No"
              placeholder="Busy So No"
              formik={formik}
              name="busySoNo"
              width="w-full md:w-1/2 lg:w-1/3"
              // isRequired={true}
            />
            {data?.customerId && (
              <>
                <NuInput
                  type="text"
                  label="Customer"
                  placeholder="Customer"
                  formik={formik}
                  name="customerName"
                  width="w-full md:w-1/2 lg:w-1/3"
                  disabled={true}
                  // isRequired={true}
                />
              </>
            )}
            <NuInput
              type="text"
              label="Customer Order Reference"
              placeholder="Customer Order  "
              formik={formik}
              name="cusOrderRef"
              width="w-full md:w-1/2 lg:w-1/3"
              disabled={true}
            />
            <NuDate
              label="Order Received On"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="orderReceivedOn"
              width="w-full md:w-1/2 lg:w-1/3"
            />

            <FormikSelectTwo
              type="text"
              label="SO Type"
              placeholder="SO Type "
              Options={[
                { value: "International", label: "International" },
                { value: "Local", label: "Local" },
                { value: "Ex-Stock", label: "Ex-Stock" },
              ]}
              formik={formik}
              name="type"
              width="w-full md:w-1/2 lg:w-1/3"
              FixPosition={false}
              PortalTarget={false}
              // isRequired={true}
            />

            <FormikSelectTwo
              type="text"
              label="Sales Person"
              placeholder="Choose"
              Options={Salesman}
              formik={formik}
              name="salesPerson"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
              FixPosition={false}
              PortalTarget={false}
            />

            <FormikSelectTwo
              type="text"
              label="Shipping Method"
              placeholder="Choose"
              Options={ShippingMethod}
              formik={formik}
              name="shippingMethod"
              width="w-full md:w-1/2 lg:w-1/3"
              FixPosition={false}
              PortalTarget={false}
            />

            <FormikSelectTwo
              type="text"
              label="Terms And Conditions"
              placeholder="Terms And Conditions"
              Options={TermsAndConditions}
              formik={formik}
              name="termsAndConditions"
              width="w-full md:w-1/2 lg:w-1/3"
              FixPosition={false}
              PortalTarget={false}
            />
            <FormikSelectTwo
              type="text"
              label="Payment Status"
              placeholder="Payment Status"
              Options={PaymentStatus}
              formik={formik}
              name="paymentStatus"
              width="w-full md:w-1/2 lg:w-1/3"
              FixPosition={false}
              PortalTarget={false}
            />
            <NuDate
              label="Requested Delivery Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="requestedDeliveryDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />

            <DropDownAddMetaData
              label="Status"
              placeholder="Choose"
              Options={status}
              formik={formik}
              name="status"
              width="w-full md:w-1/2 lg:w-1/3"
              getFunc={refstatus}
              displayProperty={"Status"}
              propertyName={"Status_SalesOrder"}
            />

            <NuTextArea
              type="text"
              label="Billing Address"
              placeholder="Billing Address"
              formik={formik}
              name="billingAddress"
              width="w-full md:w-1/2 lg:w-1/3"
              new_width="w-full md:w-1/2 lg:w-2/3"

            />
            <NuTextArea
              type="text"
              label="Delivery Address"
              placeholder="Delivery Address"
              formik={formik}
              name="deliveryAddress"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            {/* <NuTextArea
              label="Notes From Customer"
              type="text"
              formik={formik}
              placeholder="Notes From Customer"
              name="notesFromCus"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuTextArea
              label="Comments"
              type="text"
              formik={formik}
              placeholder="Comments"
              name="comments"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}

<div className="w-full">
  <p className='pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4'>
    SO Attachement :
  </p></div> 
            <div className="md:flex justify-between mt-5 px-3 w-full">
              <div className="w-full flex gap-2 items-center flex-wrap">
              {data?.attachments &&
                  filePathList?.map((imgs, idx) => {
                    console.log("img", imgs);
                    let Ext = imgs?.file_name.split(".").reverse()[0];
                    return (
                      <div
                        className=" w-20 h-9 my-2 relative flex justify-center items-center rounded-lg"
                        key={idx}
                        style={{
                          border: "1px solid gray",
                          borderColor:
                            "rgb(148 163 184 / var(--tw-bg-opacity))",
                        }}
                      >
                        <div
                          className=" w-4 h-4 rounded bg-slate-400 absolute text-white cursor-pointer font-medium flex justify-center items-center  -top-2 -right-2 z-10"
                          onClick={() => UpdateAttachmentList(imgs?.file)}
                        >
                          <CloseIcon color="white" height="8" width="8" />
                        </div>
                        <div
                          className=" w-20 h-9 flex justify-center items-center rounded-lg overflow-hidden cursor-pointer"
                          onClick={() => handleImageClick(imgs)}
                        >
                          
                          <div className=" w-8 h-8">
                  {videotype.includes(Ext) ? <Video /> : null}

                  {Ext === ("docx" || "doc"||"txt") ? (
                    <Doc />
                  ) : Ext === ("xlsx" || "xls") ? (
                    <Xls />
                  ) : Ext === "txt" ? (
                    <Txt />
                  ) : Ext === "pdf" ? (
                    <Pdf />
                  ) : Ext === "pptx" ? (
                    <>No file</>
                  ) : 
                  <img src={imgs?.file} alt="#" width="120%" />

                  }
                  </div>
                  <p className=" w-[50px] text-[11px] truncate">{imgs?.file_name}</p>
                        </div>
                      </div>
                    );
                  })}

                <Attachement
                  handleChange={handleImageChange}
                  preview={image || ""}
                  width="w-full md:w-1/2"
                  label="Attach"
                  multiple={true}
                removeImages={removeImages}

                />
              </div>
            </div>
            <div className="w-full">
  <p className='pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4'>
    PO Attachement :
  </p></div> 
            <div className="md:flex justify-between mt-5 px-3 w-full">
              <div className="w-full flex gap-2 items-center flex-wrap">
              {data?.poAttachments &&
                  filePathListpo?.map((imgs, idx) => {
                    console.log("img", imgs);
                    let Ext = imgs?.file_name.split(".").reverse()[0];
                    return (
                      <div
                        className=" w-20 h-9 my-2 relative flex justify-center items-center rounded-lg"
                        key={idx}
                        style={{
                          border: "1px solid gray",
                          borderColor:
                            "rgb(148 163 184 / var(--tw-bg-opacity))",
                        }}
                      >
                        <div
                          className=" w-4 h-4 rounded bg-slate-400 absolute text-white cursor-pointer font-medium flex justify-center items-center  -top-2 -right-2 z-10"
                          onClick={() => UpdateAttachmentListpo(imgs?.file)}
                        >
                          <CloseIcon color="white" height="8" width="8" />
                        </div>
                        <div
                          className=" w-20 h-9 flex justify-center items-center rounded-lg overflow-hidden cursor-pointer"
                          onClick={() => handleImageClickpo(imgs)}
                        >
                          
                          <div className=" w-8 h-8">
                  {videotype.includes(Ext) ? <Video /> : null}

                  {Ext === ("docx" || "doc"||"txt") ? (
                    <Doc />
                  ) : Ext === ("xlsx" || "xls") ? (
                    <Xls />
                  ) : Ext === "txt" ? (
                    <Txt />
                  ) : Ext === "pdf" ? (
                    <Pdf />
                  ) : Ext === "pptx" ? (
                    <>No file</>
                  ) : 
                  <img src={imgs?.file} alt="#" width="120%" />

                  }
                  </div>
                  <p className=" w-[50px] text-[11px] truncate">{imgs?.file_name}</p>
                        </div>
                      </div>
                    );
                  })}

                <Attachement
                  handleChange={handleImageChangepo}
                  preview={imagepo|| ""}
                  width="w-full md:w-1/2"
                  label="Attach"
                  multiple={true}
                removeImages={removeImagespo}

                />
              </div>
            </div>
            <div className="w-full h-0.5 opacity-30 bg-[#C4C2C2] my-6 mx-auto"></div>
            {deletedItemDropDown.length > 0 ? (
              <div className="w-full flex justify-start gap-2">
                <FormikSelectTwo
                  type="text"
                  label="Product"
                  placeholder="Product"
                  Options={deletedItemDropDown}
                  formik={formik}
                  name="productId"
                  width="w-full md:w-1/2 lg:w-[67%]"
                  FixPosition={false}
                  PortalTarget={false}
                />
                <button
                  onClick={handleAddDelPro}
                  type="button"
                  className=" p-1 mt-[44px] m-1 text-center h-8 cursor-pointer bg-[#048178] border border-solid border-white  text-xs font-medium  text-white  rounded-lg "
                >
                  Add
                </button>
              </div>
            ) : null}
            <div className="w-full bg-white overflow-y-scroll mt-5">
              {error && (
                <p className="text-xs font-semibold text-red-500">{error}</p>
              )}
              <div className="w-full py-3 bg-[#048178] flex justify-between rounded-lg px-2">
                <p className="w-[5%]  text-left  text-white text-xs font-medium">
                  S.N.{" "}
                </p>

                <p className=" w-[50%] text-left text-white text-xs font-medium">
                  {" "}
                  Product
                </p>
                <p className=" w-[10%] text-center text-white text-xs font-medium">
                  Unit
                </p>
                <p className=" w-[10%] text-center text-white text-xs font-medium">
                  Quantity
                </p>
                <p className=" w-[10%] text-center text-white text-xs font-medium">
                  Price
                </p>
                <p className=" w-[10%] text-center text-white text-xs font-medium">
                  Total
                </p>
                <p className=" w-[5%] text-center text-white text-xs font-medium flex justify-center">
                  Action
                  <span
                    className="pl-1 cursor-pointer"
                    onClick={() => {
                      setIsEditQua((prv) => !prv);
                    }}
                  >
                    {isEditQua === true && (
                      <EditIcon width={"14"} height={"14"} color="yellow" />
                    )}
                    {isEditQua === false && (
                      <EditIcon width={"14"} height={"14"} />
                    )}
                  </span>
                </p>
              </div>
              {dataa?.products?.length > 0 ? (
                <div className="h-[calc(100vh-300px)] overflow-y-scroll">
                  {dataa?.products.map((List, index) => {
                    return (
                      <div
                        key={index}
                        className="w-full py-3 flex justify-between px-2 odd:bg-white even:bg-slate-100"
                      >
                        <p className="w-[5%]  text-left   text-xs font-medium text-[#111537] py-1">
                          {index + 1}
                        </p>
                        <p className=" w-[50%] text-left  text-xs font-medium text-[#111537]">
                          {List?.product_name}
                        </p>
                        <p className=" w-[10%] text-center  text-xs font-medium text-[#111537]">
                          {List?.unit}
                        </p>

                        <p className=" w-[10%] text-center  text-xs font-medium text-[#111537]">
                          <input
                            type="number"
                            className="w-11/12 mx-auto outline-none text-center"
                            name="quantity"
                            formik={formik}
                            value={List?.quantity || ""}
                            disabled={!isEditQua}
                            onChange={(e) => handleQuantityChange(e, index)}
                          />
                        </p>
                        <p className=" w-[10%] text-center  text-xs font-medium text-[#111537]">
                          <input
                            type="number"
                            className="w-10/12 mx-auto outline-none text-center"
                            name="quantity"
                            formik={formik}
                            value={List?.price || ""}
                            disabled={!isEditQua}
                            onChange={(e) => handlePriceChange(e, index)}
                          />
                          {/* {List?.price} */}
                        </p>
                        <p className=" w-[10%] text-center  text-xs font-medium text-[#111537]">
                          {(List.price * List.quantity).toLocaleString(
                            undefined,
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )}
                        </p>
                        <div className="w-[5%] text-center">
                          <button
                            onClick={() => handleDelete(List?.productId)}
                            type="button"
                            className="text-red-500 cursor-pointer ml-4 border-none"
                          >
                            <DeleteIcon color="red" height={4} />
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="w-full text-center text-[#93949f]">
                  No Items here
                </div>
              )}
            </div>
            <div className="w-full my-6 flex justify-end items-center gap-4">
              <TextMediumBase content={"Total Price : "} />
              <p className="text-sm"> {currencySymbol} </p>
              <p className="w-64 h-8 border rounded-md text-xs pl-2 py-2">
                <NuAmount
                  defaultCurrency={false}
                  value={formik?.values?.subTotal || 0}
                />
              </p>
            </div>
            <div className="w-full my-4 mt-8 ">
              <div className="flex justify-end gap-4">
                <CancelButton
                  handleClick={() => {
                    Oncancel(-1);
                  }}
                />
                <SubmitButton isLoading={isLoading} />
              </div>
            </div>
          </div>
        </div>
      </form>
      <Modal show={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <img src={selectedImage?.file} alt={selectedImage?.file} />
      </Modal>
      <Modal show={isModalOpenpo} onClose={() => setIsModalOpenpo(false)}>
        <img src={selectedImagepo?.file} alt={selectedImagepo?.file} />
      </Modal>
    </div>
  );
}
export default EditSalesOrders;
