import React, { useEffect, useMemo, useState } from 'react'
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { NuDataTime, NuDate, NuInput, NuInputNew, NuTextArea, NuTime } from '../../../../Components/Utils/Input';
import FormikSelectTwo from '../../../../Components/Utils/Select';
import { Link, Outlet, useLocation, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import UseEditData from '../../../../Components/Hooks/useEditData';
import * as yup from 'yup';
import Dashboard from '../../../../Components/Icons/Dasboard';
import useEditFormData from '../../../../Components/Hooks/useEditFormData';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import Delete from '../../../../Components/Icons/Delete';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import axiosInstance from '../../../../Services/axiosCommon';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
import { TextMediumBase } from '../../../../Components/Text/MediumText';
import EditIcon from '../../../../Components/Icons/Edit';
import Select from 'react-select';

function EditCustomerQuotes() {
  // const {value:ApprovalStatus} = useFetchMataData(`/dropdown/dropdown-details/`,'ApprovalStatus_CQ')

  // const {value:Availablity} = useFetchMataData(`/dropdown/dropdown-details/`,'Availablity_CustomerQuotes')
  // const {value:DeliveryTerm} = useFetchMataData(`/dropdown/dropdown-details/`,'DeliveryTerm_CustomerQuotes')
  // const {value:PaymentTerms} = useFetchMataData(`/dropdown/dropdown-details/`,'PaymentTerms_CustomerQuotes')
  // const {value:PaymentMethod} = useFetchMataData(`/dropdown/dropdown-details/`,'PaymentMethod_CustomerQuotes')
  // const {value:Validity} = useFetchMataData(`/dropdown/dropdown-details/`,'Validity_CustomerQuotes')
  // const {value:status} = useFetchMataData(`/dropdown/dropdown-details/`,'Status_customerQuotes')
  // const {value:leadTime} = useFetchMataData(`/dropdown/dropdown-details/`,'leadTime_customerQuotes')
  // const { data: customers } = UseDropDown('/customers/drop-down');

  // const [image, setImage] = useState({ preview: "", raw: "" });
  const location = useLocation();
  const data = location.state.data;
  console.log(data,"dd");
  
  const from = location.state.from;
  let pathFile = location.pathname;
  const { quotesEditId } = useParams();


  
 
  const { data: PR } = UseDropDown('/PR/drop-down');

  const formik = useFormik
    ({
      enableReinitialize: true,
      initialValues: {prId: data?.prId,prRef:data?.prRef },
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
        // email: yup.string().required('Email is required!'),
      }),
      onSubmit: async (value, { resetForm }) => {
      }
    });
  
  return (
    <div className="w-full h-full py-2 px-4 ">
    <div className="flex justify-between ">
     <Breadcrumb
          items={
            [           
            // { name: "Sales",
            // link: "/sales/quotes" },
            { 
                name: "Customer Quotation",
                link: "/sales/quotes"
            },
            {
              name: "Edit Customer Quotation"
            }]
          }
        />
       
          <BackButton />
       
      </div>
          <NuInputNew
              label="YPR"
              placeholder="Choose"
              formik={formik}
              name="prRef"
              disabled={true}
              width="w-full md:w-1/2 lg:w-1/3"
             
            />
           <div className=" w-full h-8 flex items-center px-1 ">
           <>
          <Link
            to={`/sales/quotes/edit/${quotesEditId}`}
            state={{data:{prId:formik.values.prId}}}
            className={`mx-3 pb-1 text-xs 2xl:text-sm font-medium text-baseColor-primary ${
              `/sales/quotes/edit/${quotesEditId}` == pathFile
                ? "border-b-2 border-baseColor-primary"
                : "border-b-2 border-transparent"
            }   `}
          >
            {"Edit Quote"}
          </Link>
        </>
           <>
          <Link
            to={`/sales/quotes/edit/${quotesEditId}/viewqoute`}
            state={{data:{prId:formik.values.prId}}}
            className={`mx-3 pb-1 text-xs 2xl:text-sm font-medium text-baseColor-primary ${
              `/sales/quotes/edit/${quotesEditId}/viewqoute` == pathFile
                ? "border-b-2 border-baseColor-primary"
                : ""
            }   `}
          >
            {"Quote Comparison"}
          </Link>
        </>
      
       
      </div>
      <div className=' w-full h-[calc(100vh-250px)] '>
        <Outlet context={{ prId: formik.values.prId }} />
      </div>  
                 
    </div>
  );
}


export default EditCustomerQuotes;