import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { TextMediumSM } from "../../../../Components/Text/MediumText";
import UseGetDashBoardData from "../../../../Components/Hooks/useDashboardData";
import NuAmount from "../../../../Components/Utils/NuAmount";
import { Cell, Label, Pie, PieChart, ResponsiveContainer } from "recharts";
import { LoadOverall } from "./PurchaseRequestDashborad";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function PurchaseOrdersfromCustomers() {
  const { data, loading } = UseGetDashBoardData(
    `/dashboard/customerOrderStats`
  );



  const chartData = data?.customer_category_wise?.map(
    (item) => ({
      name: `${
        item.category
          ? `${
              item.category.charAt(0).toUpperCase() +
              item.category.slice(1).toLowerCase()
            }`
          : "Unknown"
      }`,
      value: item?.count || 0,
    })
    // item.category ? `${item.category.charAt(0).toUpperCase() + item.category.slice(1).toLowerCase()}` : "Unknown"
  );

  let COLORS = ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF"];

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className=" max-w-[250px] bg-white shadow-[rgba(13,_38,_76,_0.19)_0px_9px_20px] rounded-lg p-[5px]">
          <p className=" text-xs font-medium">{`Category  ${payload[0].name} : ${payload[0].value}`}</p>
        </div>
      );
    }

    return null;
  };

  const [sumOfChartData, setSumOfChartData] = useState(0);

  useEffect(() => {
    setSumOfChartData(
      data?.customer_category_wise?.reduce(
        (acc, item) => acc + item?.count,
        0
      ) || 0
    );
  }, [data?.customer_category_wise]);

  return (
    <div className="bg-white h-[40vh] p-2 pb-10 rounded-lg shadow-lg overflow-hidden mb-4">
      <div className="w-full px-1 py-[2px] rounded-md flex justify-between items-center p-4">
        <TextMediumSM content="PO from Customers" color="text-[#4F4768]" />
        <div className="bg-[#95D2B3] px-2 py-0.5 rounded-md">
          <p className="text-[#333333] text-[9px] font-semibold">
            <NuAmount value={data?.total_price || 0} />
          </p>
        </div>
      </div>

      <div className="border-t border-[1px] border-slate-200/60 border-solid w-full mt-2"></div>

      {loading ? (
        <LoadOverall/>
      ) : (
        <div className="h-[96%] overflow-y-scroll">
          <div className="flex gap-2 w-full">
            {data?.customer_category_wise?.length > 0 ? (
              <>
                {sumOfChartData > 0 ? (
                  <div className=" w-[40%] ">
                    {/* <Doughnut data={chartData} /> */}
                    <div className="w-full flex justify-center items-center">
                      <ResponsiveContainer width="100%" height={150}>
                        <PieChart>
                          <Pie
                            data={chartData}
                            cx="50%"
                            cy="50%"
                            innerRadius={35}
                            outerRadius={60}
                            fill="#8884d8"
                            dataKey="value"
                            // paddingAngle={0}
                          >
                            {chartData.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={COLORS[index % COLORS.length]}
                                dataKey="name"
  onFocus={(e) => e.target.blur()} 

                              />
                            ))}
                            <Label
                              value={`${data?.total_count}`}
                              position="center"
                              className="text-center"
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            />
                          </Pie>
                          <Tooltip content={<CustomTooltip />} />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                ) : (
                  <NODataChart />
                )}
              </>
            ) : (
              <NODataChart />
            )}
            <div className="w-[58%]">
              <table className=" w-full">
                <tbody>
                  {data?.customer_category_wise?.map((item, index) => (
                    <tr key={index} className="">
                      <td className=" w-[10%]">
                        <div
                          style={{ background: COLORS[index % COLORS.length] }}
                          className={`w-3 h-3 rounded-full mb-3`}
                        ></div>
                      </td>
                      <td className=" w-[55%] py-1">
                        <p className="text-xs 2xl:text-sm truncate">
                          Category{" "}
                          {item.category
                            ? `${
                                item.category.charAt(0).toUpperCase() +
                                item.category.slice(1).toLowerCase()
                              }`
                            : ""}
                        </p>
                        <p className="text-[#048178] text-[10px] text-start">
                          {item.difference || ""}
                        </p>
                      </td>
                      <td className=" w-[35%] py-1">
                        <p className="text-[#333333] text-base 2xl:text-[14px] font-semibold text-center">
                          {`${item.count || 0}`}
                        </p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export function NODataChart() {
  return (
    <div className=" w-[40%]">
      {/* <Doughnut data={chartData} /> */}
      <div className="w-full flex justify-center">
        <ResponsiveContainer width="100%" height={150}>
          <PieChart>
            <Pie
              data={[
                {
                  name: "No Data",
                  value: 100,
                },
              ]}
              cx="50%"
              cy="50%"
              innerRadius={30}
              outerRadius={60}
              fill="#E8E8E8"
              dataKey="value"
              // paddingAngle={0}
            >
              <Label
                value={0}
                position="center"
                className="text-center"
                style={{ fontSize: "12px", fontWeight: "bold" }}
              />
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
