import { TextMediumSM } from "../../../../../Components/Text/MediumText"
import Brand from "./Brand";
import Category from "./Category";
import Manufacture from "./Manufacture";
import SubCategory from "./SubCategory";
import Unit from "./Unit";

const Items = () =>{
    return(
        <>
        <TextMediumSM content="Product" />
        <div className='w-full flex flex-wrap justify-center md:justify-start  items-center overflow-y-scroll'>
         {/* <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <Category/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <SubCategory/>
         </div> */}
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <Unit/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <Brand/>
         </div>
         {/* <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <Manufacture/>
         </div> */}
         </div>
         <Category/>
         </>
    )
}

export default Items;