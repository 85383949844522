import React from "react";

export  function TextMediumXS({
  color = "text-gray-800",
  truncate,
  wrap = "wrap",
  whitespace = "normal",
  content,
  align,
  otherStyles = ""
}) {
  return (
    <p
      className={` text-xs text-${align} font-medium ${color} text-${wrap} whitespace-${whitespace} ${
        truncate ? "truncate" : ""
      } ${otherStyles}`}
    >
      {content}
    </p>
  );
}

export  function TextMediumdS({
  color = "text-gray-800",
  truncate,
  wrap = "wrap",
  whitespace = "normal",
  content,
  align,
  otherStyles = ""
}) {
  return (
    <p
      className={` text-[11px] text-${align} font-extralight ${color} text-${wrap} whitespace-${whitespace} ${
        truncate ? "truncate" : ""
      } ${otherStyles}`}
    >
      {content}
    </p>
  );
}

export  function TextMediumSM({
    color = "text-gray-800",
    truncate,
    wrap = "wrap",
    whitespace = "normal",
    content,
    align,
    otherStyles = ""
  }) {
    return (
      <p
        className={` text-xs 2xl:text-sm  text-${align} font-medium ${color} text-${wrap} whitespace-${whitespace} ${
          truncate ? "truncate" : ""
        } ${otherStyles}`}
      >
        {content}
      </p>
    );
  }

  export  function TextMediumBase({
    color = "text-gray-800",
    truncate,
    wrap = "wrap",
    whitespace = "normal",
    content,
    align="left",
    otherStyles = ""
  }) {
    return (
      <p
        className={` text-sm 2xl:text-base text-${align} font-medium ${color} text-${wrap} whitespace-${whitespace} ${
          truncate ? "truncate" : ""
        } ${otherStyles}`}
      >
        {content}
      </p>
    );
  }

  export  function TextMediumXL({
    color = "gray-800",
    truncate,
    wrap = "wrap",
    whitespace = "normal",
    content,
    align="left",
    otherStyles = ""
  }) {
    return (
      <p
        className={` text-base 2xl:text-xl text-${align} font-medium ${color} text-${wrap} whitespace-${whitespace} ${
          truncate ? "truncate" : ""
        } ${otherStyles}`}
      >
        {content}
      </p>
    );
  }

  export  function TextMediumXXL({
    color = "gray-800",
    truncate,
    wrap = "wrap",
    whitespace = "normal",
    content,
    align="left",
    otherStyles = ""
  }) {
    return (
      <p
        className={` text-xl 2xl:text-2xl text-${align} font-medium ${color} text-${wrap} whitespace-${whitespace} ${
          truncate ? "truncate" : ""
        } ${otherStyles}`}
      >
        {content}
      </p>
    );
  }
