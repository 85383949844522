import React, { useState, useEffect } from "react";
import axiosInstance from "../../../../Services/axiosCommon";
import EditIcon from "../../../../Components/Icons/Edit";

const NotesComponent = () => {
  const [notesData, setNotesData] = useState([]); 
  const [editingModule, setEditingModule] = useState(null);
  const [editedNotes, setEditedNotes] = useState("");
  const [showSaveButtons, setShowSaveButtons] = useState({});

  const modules = [
    { id: 1, module: "purchase_request" },
    { id: 2, module: "rfq" },
    { id: 3, module: "supplier_quote" },
    { id: 4, module: "customer_quote" },
    { id: 5, module: "sales_order" },
    { id: 6, module: "purchase_order" },
    { id: 7, module: "shipping" },
    { id: 8, module: "invoice_in" },
    { id: 9, module: "invoice_out" },
    { id: 10, module: "grn" },
    { id: 11, module: "delivery_note" },
  ];

  useEffect(() => {
    const fetchNotesData = async () => {
      try {
        const promises = modules.map((module) =>
          axiosInstance.get(`/pdf-notes/${module.id}`)
        );
        const responses = await Promise.all(promises);

        const dataWithNotes = modules.map((module, index) => ({
          ...module,
          notes: responses[index]?.data?.data?.notes || "", 
        }));

        setNotesData(dataWithNotes);
        console.log("Updated Notes Data:", dataWithNotes); 
      } catch (error) {
        console.error("Error fetching notes data:", error);
      }
    };

    fetchNotesData();
  }, []);

  const saveNotes = async (id) => {
    try {
      const moduleToUpdate = notesData.find((module) => module.id === id);

      if (!moduleToUpdate) {
        console.error(`Module with ID ${id} not found.`);
        return;
      }

      const payload = {
        module: moduleToUpdate.module, 
        notes: editedNotes,
      };

      await axiosInstance.put(`/pdf-notes/${id}`, payload);

      setNotesData((prev) =>
        prev.map((module) =>
          module.id === id ? { ...module, notes: editedNotes } : module
        )
      );

      setEditingModule(null);
      setShowSaveButtons((prev) => ({ ...prev, [id]: false }));
    } catch (error) {
      console.error(`Error saving notes for module ID ${id}:`, error);
    }
  };

  const handleNotesChange = (id, value) => {
    setEditedNotes(value);
    setShowSaveButtons((prev) => ({
      ...prev,
      [id]: value !== notesData.find((module) => module.id === id).notes,
    }));
  };

  const enableEditMode = (id) => {
    setEditingModule(id);
    const foundModule = notesData.find((module) => module.id === id);
    setEditedNotes(foundModule?.notes || ""); 
    setShowSaveButtons((prev) => ({ ...prev, [id]: false }));
  };

  const cancelEditMode = (id) => {
    setEditingModule(null);
    setEditedNotes(""); 
    setShowSaveButtons((prev) => ({ ...prev, [id]: false }));
  };

  return (
    <div className="p-4">
      <h1 className="text-[16px] font-semibold mb-4">PDF Notes</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {notesData.map((module) => (
          <div
            key={module.id}
            className="p-4 border rounded-lg shadow-md bg-gray-50"
          >
            <div className="flex items-center justify-between mb-2">
              <h2 className="text-sm font-semibold capitalize">
                {module.module.replace("_", " ")}
              </h2>
              {editingModule !== module.id && (
                <button
                  className="text-blue-500 hover:underline"
                  onClick={() => enableEditMode(module.id)}
                >
                  <EditIcon />
                </button>
              )}
            </div>

            <div>
              {editingModule === module.id ? (
                <textarea
                  className="p-1.5 border rounded-lg shadow-md bg-white h-32 overflow-y-scroll text-xs leading-normal tracking-wide w-full"
                  value={editedNotes}
                  onChange={(e) => handleNotesChange(module.id, e.target.value)}
                  autoFocus
                />
              ) : (
                <p className="p-1.5 border rounded-lg shadow-md bg-white h-32 overflow-y-scroll text-xs leading-normal tracking-wide">
                  {module.notes || "No notes available."}
                </p>
              )}
            </div>

            {editingModule === module.id && (
              <div className="flex items-center space-x-4 mt-2">
                {showSaveButtons[module.id] && (
                  <button
                    className="px-2 h-6 cursor-pointer text-xs text-green-500 bg-white rounded"
                    onClick={() => saveNotes(module.id)}
                  >
                    Save
                  </button>
                )}
                <button
                  className="px-2 h-6 cursor-pointer text-xs text-red-500 bg-white rounded"
                  onClick={() => cancelEditMode(module.id)}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default NotesComponent;


