import React, { useEffect, useState } from 'react'
import UseGetDashBoardData from '../../../../../Components/Hooks/useDashboardData';
import { Cell, Label, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import axiosInstance from '../../../../../Services/axiosCommon';
import { LoadSaleApproval } from './PrFromCustomer';
import { useNavigate } from 'react-router-dom';

function QuotationToCustomer() {
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const [loading,setLoading] = useState(false);  async function getData() {
    setLoading(true);
    try {
      await axiosInstance.get('/dashboard/CusQts-pending').then(res=>{
        setLoading(false);
        setData(res?.data || []) ;
      });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }
  useEffect(() => {
    getData();
  }, []);
  const COLORS_Two = ['#FF6347','#32CD32','#048178'];
  const chartData = [
    { name: 'Pending', value: data?.pending || 0 ,status: 'Pending'},
    { name: 'Verified', value: data?.verified || 0 ,status: 'Verified'},

    { name: 'Approved', value: data?.approved || 0 ,status: 'Approved'},
  ];

  const handleSliceClick = (status) => {
        const path = '/sales/quotes';
    console.log('Status',status);
    
    navigate(path , {state: { approvalStatus : status } });
  };

  const CustomTooltip = ({ active, payload, }) => {
    
    if (active && payload && payload.length) {
      return (
        <div className=" max-w-[250px] bg-white shadow-[rgba(13,_38,_76,_0.19)_0px_9px_20px] rounded-lg p-[5px]">
          <p className=" text-xs font-medium">{`${payload[0].name} : ${payload[0].value}`}</p>
        </div>
      );
    }
  
    return null;
  };


  let SumValue = chartData.reduce((sum, value)=>{
    return sum + value?.value;
  },0)

  
  return (
    <div className="w-full md:w-[50%]  px-2">
        <div className="flex items-center" >
          <div className="pl-1 py-1">
            <p className="text-[#4F4768] text-xs 2xl:text-sm font-normal">Quotation to Customer</p>
          </div>
        </div>
        {loading ? (
          <LoadSaleApproval/>
        ) : (
          <>
          <div className="grid grid-rows-2 h-[90%]  justify-center items-center">
            <div className="w-full flex justify-center items-center">
            {
                SumValue > 0 ? (
                  <ResponsiveContainer  width={100} height={90}>
                  <PieChart>
                    <Pie
                      data={chartData}
                      cx="50%"
                      cy="50%"
                      innerRadius={25}
                      outerRadius={40}
                      fill="#8884d8"
                      dataKey="value"
                      paddingAngle={0}
                      style={{ cursor: 'pointer' }}
                    onClick={(e) => handleSliceClick(e.status)}

                    >
                      {chartData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS_Two[index % COLORS_Two.length]} 
  onFocus={(e) => e.target.blur()} 
  />
                      ))}
                      <Label
                        value={`${data?.total}`}
                        position="center"
                        className="text-center"
                        style={{ fontSize: '12px', fontWeight: 'bold', }}
                      />
                    </Pie>
                    <Tooltip content={<CustomTooltip />} />
                  </PieChart>
                </ResponsiveContainer>
                ):(
                  <ResponsiveContainer  width={100} height={90}>
                  <PieChart>
                    <Pie
                      data={[{
                        name:"No Data",
                        value:100
                      }]}
                      cx="50%"
                      cy="50%"
                      innerRadius={25}
                      outerRadius={40}
                      fill="#E8E8E8"
                      dataKey="value"
                      paddingAngle={0}
                    >
                      <Label
                        value={0}
                        position="center"
                        className="text-center"
                        style={{ fontSize: '12px', fontWeight: 'bold', }}
                      />
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
                )
              }
            </div>
            <div className="w-full h-full flex flex-col ">
              {chartData.map((item, index) => (
                <div key={index} className=" w-full flex items-center text-xs mb-1">
                  <table className=' w-full border-collapse '>
                    <tr>
                      <td className='w-[10px]'>
                      <span
                    className="inline-block w-2 h-2 rounded-full mr-2"
                    style={{ backgroundColor: COLORS_Two[index % COLORS_Two.length] }}
                  ></span>
                      </td>
                      <td className=' w-[62px]'>
                      {item.name}
                      </td>
                      <td>: <span className=' font-semibold'>{item.value}</span></td>
                    </tr>
                  </table>
                </div>
              ))}
            </div>
          </div>
          </>
        )}
        {/* <p className="text-sm font-semibold mt-1">Total: {data.total}</p> */}
      </div>
  )
}

export default QuotationToCustomer