import { useFormik } from "formik";
import {
  BackButton,
  CancelButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import * as yup from "yup";
import useAddFormData from "../../../../Components/Hooks/useAddFormData";
import { useEffect, useState } from "react";
import { NuDate, } from "../../../../Components/Utils/Input";
import { FormikSelectWithoutAdd } from "../../../../Components/Utils/Select";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import Delete from "../../../../Components/Icons/Delete";
import axiosInstance from "../../../../Services/axiosCommon";
import { useToast } from "../../../../Components/Others/ToastServices";
import Attachement from "../../../../Components/Utils/CustomAttrachmentupload";
import UseSearchDropDownNew from "../../../../Components/Hooks/UseSearchDropDown";
const filetype = ["docx", "doc", "xlsx", "xls", "pdf", "txt", "pptx","mp4", "mov", "wmv", "avi", "flv", "mkv"];

const CreatePackingList = () => {
  const currentDate = new Date().toISOString().split('T')[0];
  const [search, setSearch] = useState("")  
  const { data:deliveryNotes } = UseSearchDropDownNew('/delivery-notes/drop-down',search);  
  const { Oncancel, addFormData, isLoading } = useAddFormData ();
 const [items, setItems] = useState([]);
 const [image, setImage] = useState([]);
  const toast = useToast();
 const [newItemData, setNewItemData] = useState({
  orderedQty:"",
  packedQty: "",
  productId:"",
  product_name: "",
  product_unit: "",
  remainingQty:"",
  });
  console.log(items,"jj");
    const [createNew, setCreateNew] = useState({
       
     date: currentDate || "",
      dnId:"",
      products:""
          })
   

  const handleDeleteItem = (indexToDelete) => {
    const updatedItems = dnlist.filter((_, index) => index !== indexToDelete);
    setDnlist(updatedItems);
    // localStorage.setItem("items", JSON.stringify(updatedItems));
  };

  const [dnlist, setDnlist] = useState([]);
  const [error,setError] = useState('')
    const formik = useFormik
    ({
        enableReinitialize: true,
        initialValues: createNew,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: yup.object({
          dnId: yup.string().required('Delivary Notes is required!'),
        }),
        onSubmit: async (value, { resetForm }) => {
          // setLoading(true); 
          if (dnlist.length === 0) {
            setError('Product table is empty');
            return;
          }
          const valueWithItems = {
            ...value,
            products: dnlist,
          };
          let formData = new FormData();
          formData.append('json_data', JSON.stringify(valueWithItems));
          // formData.append('itemImage', image.raw);
          if(selectedFile !== null){
            selectedFile.forEach((file) => {
              formData.append("attachments", file);
            });
          }else{
            formData.append("attachments",[])
          } 
    
          addFormData('/packing-list/new-packing-list',formData, resetForm)
          // setLoading(false)
       
     }
   });

   useEffect(() => {
    if (dnlist.length > 0) {
      setError('');
    }
  }, [dnlist]);

   const [selectedFile, setSelectedFile] = useState(null);

    function handleImageChange(e) {
      const maxFileSize = 200000000; // 200 MB
      const files = Array.from(e.target.files);
      let fileArr = []
      files.map(item=>{
        if (item.size < maxFileSize ){
          fileArr.push(item)
        }else{
          toast.open({
            type:"warning",
            message: `File Size to big`,
            description: `${item.name} is Removed and not Uploaded`,
            // placement: "bottomRight",
          });
        }
      })    
      let tempArr = []
      setSelectedFile(fileArr);
      files.map(item=>{
        if (item.size < maxFileSize ){
        let fileName = item?.name
        console.log('fname',fileName);
        let fileExtention =  fileName.split(".").reverse()[0]
        if(filetype?.includes(fileExtention)){
          tempArr.push(fileName)
        }else{
          let x = URL.createObjectURL(item)
          tempArr.push(x)
        }
      }
      })
      console.log(tempArr,'temp');
      setImage(tempArr)
    }
  


  const getPr = (dnId) => {
    if (dnId !== "") {
      axiosInstance
        .get(`/packing-list/order-products/${dnId}`)
        .then(res => {
          let data = res?.data?.products;
          setDnlist(data);
        })
        .catch(err => {
          console.log(err, "err");
        });
    }
  };

  useEffect(() => {
    if (formik.values.dnId) {
      getPr(formik.values.dnId);
    }
  }, [formik.values.dnId]);

  const handleQuantityChange = (index, newpackedQty) => {
    const updatedItems = [...dnlist];
    if (newpackedQty <= updatedItems[index].orderedQty) {
      updatedItems[index].packedQty = newpackedQty;
      updatedItems[index].remainingQty = updatedItems[index].orderedQty - newpackedQty;
    }
    setDnlist(updatedItems);
  };

  const handleIncrement = (index) => {
    const currentpackedQty = dnlist[index].packedQty;
    handleQuantityChange(index, Math.min(dnlist[index].orderedQty, currentpackedQty+ 1));
  };

  const handleDecrement = (index) => {
    const currentpackedQty = dnlist[index].packedQty;
    handleQuantityChange(index, Math.max(0, currentpackedQty - 1));
  };

  const handleInputChange = (event, index) => {
    const value = Math.max(0, Math.min(Number(event.target.value), dnlist[index].orderedQty));
    handleQuantityChange(index, value);
  };
  const removeImages = (value) => {
    console.log("value", value);
    let List = selectedFile.filter((item) => item?.name !== value);
    console.log("LIst", List);
    setSelectedFile([...List]);
    let tempArr = []
    List.map((item) =>{
      let fileName = item?.name
        tempArr.push(fileName)
    })
    setImage(tempArr)
  };

  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between ">
      <Breadcrumb
          items={
            [{
              name: "Packing List",
              link: "/store/packing-list"
            },
            {
              name: "Create Packing List"
            }]
          }
        />
        <BackButton />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-145px)] mt-4 bg-white rounded-md overflow-hidden ">
          <div className=" w-full h-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto">
          <NuDate
              label="Date "
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="date"
              width="w-full md:w-1/2 lg:w-1/3"
            />
           
        
          <FormikSelectWithoutAdd
                   type="text"
                   label="Delivery Notes"
                   placeholder="Delivery Notes"
                   Options={deliveryNotes}
                   formik={formik}
                   name="dnId"
                   width="w-full md:w-1/2 lg:w-1/3"
                   isRequired={true}
                   setSearch={setSearch}
                   FixPosition={false}
                   PortalTarget={false}
                 />
                   <div className="w-full">
  <p className='pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4'>
  Packing-List Attachement :
  </p></div>
                <Attachement
                  handleChange={handleImageChange}
                  preview={image||''}
                  width="w-full"
                  label="Attach"
                  multiple={true}
                  removeImages={removeImages}

                />
               {/* </div> */}
          <div className="w-full h-0.5 opacity-30 bg-[#C4C2C2] my-6 mx-auto"></div>
         <div className='w-full bg-white overflow-y-scroll mt-5'>
         {error && <p className="text-xs font-semibold text-red-500">{error}</p>}
              <div className='w-full py-2  bg-[#048178] flex justify-between rounded-lg px-6'>
              <p className="w-[5%]  text-left pl-2 text-white text-xs font-medium">S.N. </p>

              <p className=" w-[30%] text-left  text-white text-xs font-medium">
              Product
              </p>
              <p className=" w-[10%] text-center  text-white text-xs font-medium">
              Unit
              </p>
              <p className=" w-[20%] text-center text-white text-xs font-medium">
              Ordered Quantity
              </p>
              <p className=" w-[20%] text-center text-white text-xs font-medium">
              Packed Quantity
              </p>
              <p className=" w-[20%] text-center text-white text-xs font-medium">
              Remaining Quantity
              </p>
              <p className=" w-[5%] text-center text-white text-xs font-medium">
              Action
              </p>
              </div>
              {dnlist?.length>0?
              <div className="h-[calc(100vh-300px)] overflow-y-scroll">

              {dnlist?.map((List, index) => {
                // console.log(List,'lissttt ');
              return (
                <div key={index} className="w-full py-2 flex justify-between px-6">
                 <p className="w-[5%]  text-left pl-2   text-xs font-light py-1">
                                    {index + 1}
                                </p>
                  <p className=" w-[30%] text-left   text-xs font-light ">
                    {List?.product_name}
                  </p>
                  <p className=" w-[10%] text-center  text-xs font-light">
                    {List?.product_unit}
                  </p>
                  <p className=" w-[25%] text-center  text-xs font-light">
                    {List?.orderedQty}
                  </p>
                  <div className="w-[20%] text-center  text-xs font-light">
                    <button
                      type="button"
                      className="border rounded-full p-1 "
                      onClick={() => handleDecrement(index)}
                    >
                      -
                    </button>
                    <input
                      type="number"
                      className="w-[20%] text-center border  text-xs font-light"
                      name="packedQty"
                      value={List.packedQty}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                    <button
                      type="button"
                      className="border rounded-full p-1 "
                      onClick={() => handleIncrement(index)}
                    >
                      +
                    </button>
                  </div>
                  <p className=" w-[20%] text-center  text-xs font-light">
                    {List?.remainingQty}
                  </p>
                 
                  <div className='w-[5%] text-center'> 
                   <button
                    onClick={() => handleDeleteItem(index)}
                    type="button"
                    className="text-red-500 cursor-pointer bg-white ml-4 border-none"
                  >
                    <Delete
                     color='red'
                     height={4}
                    />
                  </button>
                  </div>
                </div>
              );
            })}
            </div>:<div className="w-full text-center text-[#93949f]">No Items here</div>}

          </div>
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton handleClick={()=>{Oncancel(-1)}} />
                <SubmitButton isLoading={isLoading}/>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

  )
}
 

export default CreatePackingList;
