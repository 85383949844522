import React, { useEffect, useState } from 'react'
import { BackButton, EditButton } from '../../../../Components/Utils/Buttons';
import { Link,useLocation ,useParams} from 'react-router-dom';
import { NormalHeading } from '../../../../Components/Utils/Heading';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import { GetRole } from '../../../../Components/Utils/roles';
import axiosInstance from '../../../../Services/axiosCommon';
import ViewIcon from '../../../../Components/Icons/ViewIcon';

function ViewSuppliers() {
    const { suppliersViewId} = useParams();
  const location = useLocation();
  const data = location.state.data;
  const [store,setStore]=useState(data)
  const initialProducts = data?.products ? data.products.split(", ").map((p) => p.trim()) : [];
  const [productList, setProductList] = useState(initialProducts);
  const initialEmails = data?.emails ? data.emails.split(", ").map((p) => p.trim()) : [];
  const [emailsList, setEmailsList] = useState(initialEmails);
  const initialServices = data?.services ? data.services.split(", ").map((p) => p.trim()) : [];
  const [servicesList, setServicesList] = useState(initialServices);

  const [approval, setApproval] = useState(false);
  const [approvedStatus, setApprovedStatus] = useState(""); 
  const [showPopup, setShowPopup] = useState(false); 
const [reason, setReason] = useState("");   
 
  function getView() {
    axiosInstance
      .get(`/vendors/can-approve`)
      .then((res) => {
        setApproval(res.data?.data || false);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  
 
  function updateApprovalStatus(approvalStatus, rejectionReason = "") {
    const payload = { approvalStatus };
    if (rejectionReason) {
      payload.reason = rejectionReason;
    }
  
    axiosInstance
      .put(`/vendors/edit-approval/${suppliersViewId}`, payload)
      .then((res) => {
        if(res.data.status === true){
          setStore({...store,approvalStatus:approvalStatus,reason:res.data.data.reason})

        }
        setApprovedStatus(approvalStatus); 
        setShowPopup(false); 
        setReason(""); 
      })
      .catch((error) => {
        console.error(error);
      });
  }
  
  useEffect(() => {
    getView();
  }, []);
  
  useEffect(() => {
    if (suppliersViewId) {
      setApprovedStatus(data?.approvalStatus || "");
    }
  }, [suppliersViewId, data]);

  
return (
  <div className="w-full h-full py-2 px-4 ">
    <div className="flex justify-between ">
      <Breadcrumb
        items={[
          {
            name: "Suppliers",
            link: "/procurement/suppliers",
          },
          {
            name: "View Suppliers",
          },
        ]}
      />
      <div className=" flex gap-2">
      
 

        <Link to={"/procurement/suppliers"}>
          <BackButton link={true} />
        </Link>
        <Link
          to={`/procurement/suppliers/edit/${suppliersViewId}`}
          state={{
            data: data,
            from: `/procurement/suppliers/view/${suppliersViewId}`,
          }}
        >
          <EditButton height="8" />
        </Link>
      </div>
    </div>
    <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md mt-4 overflow-y-scroll p-3">
 <div className='flex justify-between'>

      <NormalHeading title={data.companyName} />
<div className='flex gap-2' >
{GetRole() && (
  <>
      <div
      className={` bg-white flex gap-1  text-xs 2xl:text-sm border rounded-md ${
        store?.approvalStatus === "pending"
          ? "border-yellow-500"
          : store?.approvalStatus === "approved"
          ? "border-green-500"
          : store?.approvalStatus === "rejected"
          ? "border-red-500"
          : "border-gray-500"
      }`}
    >
      <div className="flex items-center">
        <p className="bg-white text-black p-1 rounded-l-md flex gap-1">
          Status{" "}
          {store?.approvalStatus === "rejected" && (
      <div className="relative group text-xs ">
        <ViewIcon color="red" />
        <div className="absolute right-0 transform -translate-y-2 border bg-white min-w-[8rem] max-w-[12rem] border-gray-300 text-gray-700 text-xs px-3 py-1 rounded-md opacity-0 group-hover:opacity-100 transition-opacity duration-200">
          {store?.reason || "No reason provided"}
        </div>
      </div>
    )}
        </p>
        <p
          className={`${
            store?.approvalStatus === "pending"
              ? "bg-yellow-500 text-white"
              : store?.approvalStatus === "approved"
              ? "bg-green-500 text-white"
              : store?.approvalStatus === "rejected"
              ? "bg-red-500 text-white"
              : "bg-gray-500 text-white"
          } p-1 items-center rounded-r-md`}
        >
           {store?.approvalStatus?.charAt(0).toUpperCase() + store?.approvalStatus?.slice(1)}

        </p>
      </div>
     
      
    </div>
    
    </>
    )}

  
    {approval && store?.approvalStatus === "pending" && (
      <div className="flex justify-between items-center gap-x-2">
        <button
          className="text-xs 2xl:text-sm text-white bg-baseColor-primary rounded-md py-[6px] px-3"
          onClick={() => updateApprovalStatus("approved")}
        >
          Approve
        </button>
        <button
          className="text-xs 2xl:text-sm text-white bg-red-400 rounded-md py-[6px] px-3"
          onClick={() => setShowPopup(true)} 
        >
          Reject
        </button>
      </div>
    )}

    {approval && approvedStatus === "approved" && (
      <div className="flex justify-between items-center gap-x-2">
        <button
          className="text-xs 2xl:text-sm text-white bg-red-400 rounded-md py-[6px] px-3"
          onClick={() => setShowPopup(true)} 
        >
          Reject
        </button>
      </div>
    )}

    {approval && approvedStatus === "rejected" && (
      <div className="flex justify-between items-center gap-x-2">
        <button
          className="text-xs 2xl:text-sm text-white bg-baseColor-primary rounded-md py-[6px] px-3"
          onClick={() => updateApprovalStatus("approved")}
        >
          Approve
        </button>
      </div>
    )}
 
</div>
      </div>
      <div className="flex">
        <div className="w-full  flex  ">
          <div className="w-full rounded-[20px]  bg-white py-4  flex  flex-col  ">
           
            <div className="odd:bg-white even:bg-slate-50 w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                Category
              </p>
              <p className="w-1/2 py-2 text-xs  text-[#606279]">
                {data.category}
              </p>
            </div>

            <div className="odd:bg-white even:bg-slate-50 w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                Type
              </p>
              <p className="w-1/2 py-2 text-xs  text-[#606279]">
                {data.vendorType}
              </p>
            </div>

            <div className="odd:bg-white even:bg-slate-50 w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                Phone
              </p>
              <p className="w-1/2 py-2 text-xs  text-[#606279]">
                {data.phoneWithCC}
              </p>
            </div>
            <div className="odd:bg-white even:bg-slate-50 w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                Email
              </p>
              <p className="w-1/2 py-2 text-xs  text-[#606279]">{data.email}</p>
            </div>
            <div className="odd:bg-white even:bg-slate-50 w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                Website
              </p>
              <p className="w-1/2 py-2 text-xs  text-[#606279]">
                {data.website}
              </p>
            </div>
            <div className="odd:bg-white even:bg-slate-50 w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                Is General?
              </p>
              <p className="w-1/2 py-2 text-xs  text-[#606279]">
                {" "}
                {data?.isGeneral == true ? "true" : "false"}
              </p>
            </div>
            <div className="odd:bg-white even:bg-slate-50 w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                TaxId
              </p>
              <p className="w-1/2 py-2 text-xs  text-[#606279]">{data.taxId}</p>
            </div>

            <div className="odd:bg-white even:bg-slate-50 w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                Contact Person{" "}
              </p>
              <p className="w-1/2 py-2 text-xs  text-[#606279]">
                {data?.contactPerson}
              </p>
            </div>

            <div className="odd:bg-white even:bg-slate-50 w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                Designation
              </p>
              <p className="w-1/2 py-2 text-xs  text-[#606279]">
                {data.designation}
              </p>
            </div>

            <div className="odd:bg-white even:bg-slate-50 w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                Contact Person Email
              </p>
              <p className="w-1/2 py-2 text-xs  text-[#606279]">
                {data.contactPersonEmail}
              </p>
            </div>
            <div className="odd:bg-white even:bg-slate-50 w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                Contact Person Mobile
              </p>
              <p className="w-1/2 py-2 text-xs  text-[#606279]">
                {data?.cPMobileWithCC}
              </p>
            </div>
            <div className="odd:bg-white even:bg-slate-50 w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                Performance Ratings
              </p>
              <p className="w-1/2 py-2 text-xs  text-[#606279]">
                {data.performanceRating}
              </p>
            </div>
          </div>
        </div>
        <div className="border-r border-[1px] border-slate-200/60 border-solid" />

        <div className="w-full  flex gap-3 ">
          <div className="w-full rounded-[20px]  bg-white py-4  flex  flex-col  ">
            <div className="odd:bg-white even:bg-slate-50 w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                Payment Terms
              </p>
              <p className="w-1/2 py-2 text-xs  text-[#606279]">
                {data.paymentTerms}
              </p>
            </div>
            <div className="odd:bg-white even:bg-slate-50 w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                Delivery Terms
              </p>
              <p className="w-1/2 py-2 text-xs  text-[#606279]">
                {data.deliverTerms}
              </p>
            </div>

            <div className="odd:bg-white even:bg-slate-50 w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                Street Address Line 1
              </p>
              <p className="w-1/2 py-2 text-xs  text-[#606279]">
                {data?.address1 || ""}
              </p>
            </div>
            <div className="odd:bg-white even:bg-slate-50 w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                Street Address Line 1
              </p>
              <p className="w-1/2 py-2 text-xs  text-[#606279]">
                {data?.address2 || ""}
              </p>
            </div>
            <div className="odd:bg-white even:bg-slate-50 w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                City
              </p>
              <p className="w-1/2 py-2 text-xs  text-[#606279]">{data.city}</p>
            </div>

            <div className="odd:bg-white even:bg-slate-50 w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                State / Province
              </p>
              <p className="w-1/2 py-2 text-xs  text-[#606279]">{data.state}</p>
            </div>

            <div className="odd:bg-white even:bg-slate-50 w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                Postal / Zip Code
              </p>
              <p className="w-1/2 py-2 text-xs  text-[#606279]">
                {data.postalCode}
              </p>
            </div>
            <div className="odd:bg-white even:bg-slate-50 w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                Country
              </p>
              <p className="w-1/2 py-2 text-xs  text-[#606279]">
                {data.country}
              </p>
            </div>
            {/* <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Status</p>
                <p className='w-1/2 py-2 text-xs  text-[#606279]'>{data.vendorStatus}</p>
              </div> */}
            <div className="odd:bg-white even:bg-slate-50 w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                Comments
              </p>
              <p className="w-1/2 py-2 text-xs  text-[#606279]">
                {data.comments}
              </p>
            </div>
          </div>
        </div>
      </div>
      <p className="w-full  pb-2 pt-4 px-4 text-sm font-medium text-[#111537]">
        Products , Services & Emails
      </p>
      <div className="border-t border-[1px] border-slate-200/60 border-solid w-full"></div>

      <div className="flex flex-col md:flex-row justify-start gap-2">
        <div className="w-full flex flex-col ">
          {/* <div className="flex justify-start">
              <SupplierNuInput
                type="text"
                label="Products"
                placeholder="seprate products with ','"
                formik={formik}
                name="products"
                width="w-11/12"
              />
              <button
                onClick={handleAddProducts}
                  type="button"
                 className="mt-10 w-10 h-10 text-center cursor-pointer bg-[#048178] border border-solid border-white  text-white font-semibold rounded-md "
                   >
                +
               </button>
               </div> */}
          <p className="w-full  pb-2 pt-4 px-4 text-xs font-medium text-[#111537]">
            Products
          </p>
          <div className="w-11/12 h-32 ml-4 rounded-md p-1 flex flex-col border border-solid overflow-y-scroll">
            {productList.map((word, index) => (
              <span
                key={index}
                className="m-1  flex justify-between items-center text-xs bg-[#B3EBD7] rounded text-main-title py-2 px-2 hover:bg-baseColor-secondary hover:bg-opacity-50"
              >
                {word}
                {/* <span
                onClick={() => {
                  handleDeleteProduct(index);
                }}
                className=" text-blue-900 cursor-pointer"
              >
                x
              </span> */}
              </span>
            ))}
          </div>
        </div>
        <div className="w-full flex flex-col ">
          {/* <div className="flex justify-start">
              <SupplierNuInput
                type="text"
                label="Products"
                placeholder="seprate products with ','"
                formik={formik}
                name="products"
                width="w-11/12"
              />
              <button
                onClick={handleAddProducts}
                  type="button"
                 className="mt-10 w-10 h-10 text-center cursor-pointer bg-[#048178] border border-solid border-white  text-white font-semibold rounded-md "
                   >
                +
               </button>
               </div> */}
          <p className="w-full  pb-2 pt-4 px-4 text-xs font-medium text-[#111537]">
            Services
          </p>
          <div className="w-11/12 h-32 ml-4 rounded-md p-1 flex flex-col border border-solid overflow-y-scroll">
            {servicesList.map((word, index) => (
              <span
                key={index}
                className="m-1  flex justify-between items-center text-xs bg-[#B3EBD7] rounded text-main-title py-2 px-2 hover:bg-baseColor-secondary hover:bg-opacity-50"
              >
                {word}
                {/* <span
                onClick={() => {
                  handleDeleteProduct(index);
                }}
                className=" text-blue-900 cursor-pointer"
              >
                x
              </span> */}
              </span>
            ))}
          </div>
        </div>
        <div className="w-full flex flex-col ">
          {/* <div className="flex justify-start">
              <SupplierNuInput
                type="text"
                label="Products"
                placeholder="seprate products with ','"
                formik={formik}
                name="products"
                width="w-11/12"
              />
              <button
                onClick={handleAddProducts}
                  type="button"
                 className="mt-10 w-10 h-10 text-center cursor-pointer bg-[#048178] border border-solid border-white  text-white font-semibold rounded-md "
                   >
                +
               </button>
               </div> */}
          <p className="w-full  pb-2 pt-4 px-4 text-xs font-medium text-[#111537]">
            Emails
          </p>
          <div className="w-11/12 h-32 ml-4 rounded-md p-1 flex flex-col border border-solid overflow-y-scroll">
            {emailsList.map((word, index) => (
              <span
                key={index}
                className="m-1  flex justify-between items-center text-xs bg-[#B3EBD7] rounded text-main-title py-2 px-2 hover:bg-baseColor-secondary hover:bg-opacity-50"
              >
                {word}
                {/* <span
                onClick={() => {
                  handleDeleteProduct(index);
                }}
                className=" text-blue-900 cursor-pointer"
              >
                x
              </span> */}
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
    {showPopup && (
  <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
    <div className="animate-scale-in min-w-[40%] max-w-[80%] max-h-[80vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
      <h2 className="text-sm font-medium mb-3">Reject Action</h2>
      <p className="text-xs mb-4">
        Are you sure you want to <strong className="text-red-500">Reject</strong>?
      </p>
      <label htmlFor="reason" className="text-sm font-medium mb-2 block">
        Reason: <span className="text-red-500">*</span>
      </label>
      <textarea
        id="reason"
        className="w-full border rounded-md p-2 mb-3 text-xs"
        rows="4"
        value={reason}
        onChange={(e) => setReason(e.target.value)}
        placeholder="Please provide a reason"
      ></textarea>
      <div className="flex justify-end gap-x-2">
        <button
          className="px-5 py-[9px] cursor-pointer text-xs 2xl:text-sm bg-[#E6F2F2] hover:bg-gray-300 rounded-md flex items-center mr-2 text-textColor-main"
          onClick={() => setShowPopup(false)} 
        >
          Cancel
        </button>
        <button
          className="bg-[#048178] px-5 py-[9px] cursor-pointer outline-none border-none text-white rounded-md text-xs 2xl:text-sm flex items-center"
          onClick={() => {
            if (reason.trim()) {
              updateApprovalStatus("rejected", reason); 
            } else {
              alert("Reason is required!"); 
            }
          }}
        >
          Submit
        </button>
      </div>
    </div>
  </div>
)}
  </div>
);
};
export default ViewSuppliers