import React from 'react';

function Xlss({ color = "#54A03B" }) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 25H6.33333C6.15824 25 5.98486 24.9655 5.82309 24.8985C5.66132 24.8315 5.51434 24.7333 5.39052 24.6095C5.14048 24.3594 5 24.0203 5 23.6667V6.33333C5 5.97971 5.14048 5.64057 5.39052 5.39052C5.64057 5.14048 5.97971 5 6.33333 5H15.7813C16.1347 5.00008 16.4736 5.14043 16.7236 5.39022L21.9431 10.6098C22.1929 10.8597 22.3333 11.1986 22.3333 11.552V15.6667"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.3333 11.6667H17C16.6463 11.6667 16.3072 11.5262 16.0572 11.2761C15.8071 11.0261 15.6666 10.687 15.6666 10.3333V5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6666 18.333L14.3333 24.9997"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3333 18.333L11.6666 24.9997"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 18.333V23.6663C17 24.02 17.1405 24.3591 17.3905 24.6092C17.6406 24.8592 17.9797 24.9997 18.3333 24.9997H19.6667"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25 18.333H23.6667C23.3131 18.333 22.9739 18.4735 22.7239 18.7235C22.4739 18.9736 22.3334 19.3127 22.3334 19.6663C22.3334 21.6663 25 21.6663 25 23.6663C25 24.02 24.8596 24.3591 24.6095 24.6092C24.3595 24.8592 24.0203 24.9997 23.6667 24.9997H22.3334"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Xlss;
