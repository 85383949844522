import React from 'react';
import { TextMediumSM } from '../../../../../Components/Text/MediumText';
import PieCharts from '../../../../../Components/Charts/PieChart';
import colorCodes from '../../../../../Components/Utils/ColorCode';
import UseGetDashBoardData from '../../../../../Components/Hooks/useDashboardData';
import capitalizeFirstLetter from '../../../../../Components/Utils/Funcs';

function OrdersByRegionProDash() {
  const { data, loading } = UseGetDashBoardData('/dashboard/suppliers-by-region');
  
  const status = {   
    proTeam: data?.suppliers_by_country?.map(item => ({
      name: item.country || 'None',
      value: item.count || 0,
    })) || [],
  };

  const totalRequests = status.proTeam.reduce(
    (total, item) => total + item.value,
    0
  );

  return (
    <div className="col-span-2 bg-white h-[40vh] w-full p-4  rounded-lg drop-shadow-lg mb-4">
    <TextMediumSM content={"Order by Region"} color='text-[#4F4768]' />
      {loading ? (
        <div className="flex justify-center items-center h-full">
          <div className="animate-pulse space-y-4 w-full">
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
          </div>
        </div>
      ) : (
        <div className='w-full flex h-[89%]'>
          <div className="w-1/2 flex justify-center items-center">
            <PieCharts
              data={status.proTeam}
              label={true}
              labelValue={totalRequests }
              COLORS={colorCodes.slice(0, status.proTeam.length)}
              height={140}
            />
          </div>
          <div className="w-1/2 overflow-y-scroll">
          <div className='w-full sticky top-0 bg-white shadow-sm flex justify-start gap-2 pt-2  text-xs 2xl:text-sm'>
<p className='w-1/2 font-medium'>Country</p>
<p className='w-1/2 font-medium text-center'>Count</p>

          </div>
            {status.proTeam.map((data, idx) => {
              const color = colorCodes[idx];
              return (
                <div key={idx} className='w-full flex justify-start gap-2 mt-2 text-xs'>
                  <div 
                    className='w-2 h-2 rounded-full mt-1'
                    style={{ backgroundColor: color }}
                  />
                  <p className='w-1/2 font-thin'>{capitalizeFirstLetter(data.name ||'None') }</p>
                  <p className='w-1/2 font-thin text-center'>  {data.value ||'0'}</p>
                </div>
               
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default OrdersByRegionProDash;
