import React, { useEffect, useRef, useState } from 'react'
import Video from '../Icons/Video';
import Doc from '../Icons/Doc';
import Xls from '../Icons/Xls';
import Txt from '../Icons/Txt';
import Pdf from '../Icons/Pdf';
import { TextMediumBase } from '../Text/MediumText';
import ModalContainer from '../Others/ModalContainer';
const videotype = ["mp4", "mov", "wmv", "avi", "flv", "mkv"];


function FilesView({fileView}) {
    const modalRef = useRef();
    const [show,setShow] = useState(false)
    let fileName = fileView.file.split(".").reverse()[0]
console.log("File type",fileName);

    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            setShow(false);
        }
      };
    
      useEffect(() => {
        if (show) {
          document.addEventListener('mousedown', handleClickOutside);
        } else {
          document.removeEventListener('mousedown', handleClickOutside);
        }
    
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [show]);
  return (
    <>
            <div className="flex-wrap flex items-center gap-2">

                <div
                  className=" w-24 h-9 flex cursor-pointer  items-center  border  rounded overflow-hidden"
                  onClick={()=>setShow(true)}
                >
                  {videotype.includes(fileName) ? <Video /> : null}

                  {fileName === ("docx" || "doc"||"txt") ? (
                    <Doc />
                  ) : fileName === ("xlsx" || "xls") ? (
                    <Xls />
                  ) : fileName === "txt" ? (
                    <Txt />
                  ) : fileName === "pdf" ? (
                    <Pdf />
                  ) : fileName === "PDF" ? (
                    <Pdf />
                  ) : fileName === "pptx" ? (
                    <>No file</>
                  ) : 
                  <img src={fileView.file} alt="#" width="30px" />

                  }
                  <div>
                    <p className=' ml-1 w-16 truncate text-[10px]'>{fileView.file_name||"-"}</p>
                    <p className=' ml-1 w-16 truncate text-[9px]'>{ Number.parseFloat(fileView?.file_size).toFixed(2)||"00"} kB</p>
                  </div>
                </div>
           
            
        </div>

      <ModalContainer
        visiable={show}
        title=""
        hasButton={false}
        content={<>
        <div className=" w-[95vw] relative h-[92vh]">
        <div className="w-full -mt-3 mb-2 flex justify-between items-center">
            <p className=" font-semibold text-sm">File View</p>
            <button
             className="absolute top-0 right-0 text-white bg-red-600"
             onClick={() => setShow(false)}
      >
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
          </div>
          <div className=' w-full h-full'>
          {fileName === ("docx" || "doc"||"txt") ? (
                          <iframe
                          src={fileView.file} // PDF URL or Blob URL
                          style={{ width: '100%', height: '100%' }}
                          frameBorder="0"
                        />
                  ) : fileName === ("xlsx" || "xls") ? (
                    <iframe
                          src={fileView.file} // PDF URL or Blob URL
                          style={{ width: '100%', height: '100%' }}
                          frameBorder="0"
                        />
                  ) : fileName === "txt" ? (
                    <iframe
                          src={fileView.file} // PDF URL or Blob URL
                          style={{ width: '100%', height: '100%' }}
                          frameBorder="0"
                        />
                  ) : fileName === "pdf" ? (
                    <iframe
                          src={fileView.file} // PDF URL or Blob URL
                          style={{ width: '100%', height: '100%' }}
                          frameBorder="0"
                        />
                  ): fileName === "PDF" ? (
                    <iframe
                          src={fileView.file} // PDF URL or Blob URL
                          style={{ width: '100%', height: '100%' }}
                          frameBorder="0"
                        />
                  )
                   : fileName === "pptx" ? (
                    <>No file</>
                  ) : 
                  <img src={fileView.file} alt="#" width="100%" />

                  }
          </div>
        </div>
        </>}
        closeModal={()=>setShow(false)}
        />      
    </>
  )
}

export default FilesView