import React, { useEffect, useMemo, useState } from "react";
import { NormalHeading } from "../../../../Components/Utils/Heading";
import {
  BackButton,
  CancelButton,
  CreateButton,
  EditButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import { Link, useLocation, useParams } from "react-router-dom";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import ModalContainer from "../../../../Components/Others/ModalContainer";
import {
  TextMediumSM,
  TextMediumXS,
} from "../../../../Components/Text/MediumText";
import { TextXS } from "../../../../Components/Text/NormalText";
import axiosInstance from "../../../../Services/axiosCommon";
import NuAmount from "../../../../Components/Utils/NuAmount";
import { useFormik } from "formik";
import * as yup from "yup";
import { useToast } from "../../../../Components/Others/ToastServices";
import { NuInput } from "../../../../Components/Utils/Input";

function ViewQuotes(startLoading=true) {
  const location = useLocation();

  const { viewqoutesId } = useParams();

  console.log(viewqoutesId);
  const toast = useToast();
  const [isLoading,setIsLoading]=useState(true)

  const [land, setland] = useState(0.0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMarginModalOpen, setIsMarginModalOpen] = useState(false);
  const [marginValue, setMarginValue] = useState(0);
  const [marginChange, setMarginChange] = useState("");
  const [createNew, setCreateNew] = useState({
    currencyId: 0,
    duttyCost: 0,
    saberCost: 0,
    tptCost: 0,
    landedCost: 0,
    otherCost: 0,
    itemCount: 0,
  });

  const [duty, setDuty] = useState(0);
  const [tpt, setTpt] = useState(0);
  const [saber, setSaber] = useState(0);
  const [other, setOther] = useState(0);
  const [total, setTotal] = useState(0);
  const [dutyPerProduct, setDutyPerProduct] = useState(0);
  const [tptPerProduct, setTptPerProduct] = useState(0);
  const [saberPerProduct, setSaberPerProduct] = useState(0);
  const [otherPerProduct, setOtherPerProduct] = useState(0);
  const [totalPerProduct, setTotalPerProduct] = useState(0);
  const [productCount, setProductCount] = useState(0);
  const [vendorValue, setVendorValue] = useState()
  const [finalProId,setFfinalProId]=useState([])

  const [dynamicHeaderCount, setDynamicHeaderCount] = useState(3);
  const [data, setData] = useState([]);

  const [stateValue, setStateValue] = useState([]);

  const [supplierList, setSuppliersList] = useState([]);

  const dynamicHeaders = Array.from(
    { length: dynamicHeaderCount },
    (_, index) => (
      <React.Fragment key={`dynamic-header-${index}`}>
        <th className="w-[200px] py-2 capitalize">
          {supplierList[index]?.name || `Supplier ${index + 1}`}
        </th>
        <th className="w-[80px] py-2">Currency</th>
        <th className="w-[100px] py-2">Unit Price</th>
        <th className="w-[150px] py-2">
          <div
            className=" w-full h-full cursor-pointer"
            onClick={async () => {
              await getData(viewqoutesId,supplierList[index]?.id)
              setVendorValue(supplierList[index]?.id)
              setIsModalOpen(true)
            }}
          >
           Landing Cost
          </div>
        </th>
        <th className="w-[100px] py-2">Quantity</th>
        <th className="w-[100px] py-2">VAT</th>
        <th className="w-[80px] py-2">Gross</th>
        <th className="w-[150px] py-2">Total Price</th>
      </React.Fragment>
    )
  );

  function handleChange(e, supId, proId) {
    // console.log("SID - xxx", supId);
    // console.log("e - xxx", e.target.checked);
    // console.log("PID - xxx", proId);
    let List = [...stateValue];
    let value = List.find((i) => i.vendorId == supId && i.productId == proId);
    console.log("value", value);
    value.isSelected = e.target.checked;
    // setFfinalProId([...finalProId,value.id])
    setStateValue(List);
    console.log("final pro",finalProId);
    FinalProductList(value.id)
  }

  function FinalProductList(id){
    if(finalProId.some(i=>i==id)==true){
      let tempValue = finalProId.filter((i) => i != id);
      console.log("tem",tempValue);
      setFfinalProId(tempValue)
    }else{
      setFfinalProId([...finalProId,id])
    }
  }

  function MarginCompleted() {
    setIsMarginModalOpen(false);
    setMarginValue(0);
    setMarginChange("");
  }

  function getViewQuotes() {
    axiosInstance
      .get(`/supplier-quotations/quot-compare/${viewqoutesId}`)
      .then((res) => {
        console.log(res.data);
        if (res.data.status) {
          setData(res.data?.data?.products);
          setProductCount(res?.data?.data?.products?.length || 0);
          setDynamicHeaderCount(res.data?.data?.vendorNames?.length);
          setSuppliersList(res.data?.data?.vendorNames);
          setStateValue(res.data?.data?.vendors);
          let vendorsItem =res.data?.data?.vendors||[]
          let TempValue =[]
          vendorsItem.forEach(i=>{
            if(i.isSelected){
              TempValue.push(i.id)
            }
          })
          setFfinalProId(TempValue)
        }
      });
  }

  useEffect(() => {
    getViewQuotes();
  }, []);

  useEffect(() => {
    setTotalPerProduct(total / setCreateNew.itemCount);
  }, [total]);

  function AddPrice(x, y, z) {
    let UP = x || 0;
    let M = y || 0;
    let PP = z || 0;
    console.log(UP, M, PP);
    return UP + PP;
  }

  function AddTotalPrice(q, x) {
    let Q = q || 0;
    let UP = x || 0;
    return Q * UP;
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      value = { ...value, landedCost: land };
      axiosInstance
        .put(`/supplier-quotations/edit-calculation/${viewqoutesId}/${vendorValue}`, value)
        .then((res) => {
          console.log(res);
          // getData();
          getViewQuotes();
          if (res.data?.status === true) {
            // res.data?.data && setData();
            toast.open({
              type: "success",
              message: "Success",
              description: res.data?.msg || "Created Successfully.",
            });
          } else if (res.data?.status === false && res.data?.error) {
            toast.open({
              type: "error",
              message: "Error",
              description: res.data.error || "Network error.",
            });
          } else {
            toast.open({
              type: "warning",
              message: "Warning",
              description: res.data?.msg || "Warning.",
            });
          }
        })
        .catch((err) => {
          console.log(err);
          toast.open({
            type: "error",
            message: "Error",
            description: "Network error.",
          });
        });
    },
  });

  useMemo(() => {
    let land =
      formik.values.tptCost +
      formik.values.duttyCost +
      formik.values.otherCost +
      formik.values.saberCost;
    setland(land);
    let itemCount = createNew.itemCount || 1;
    setDutyPerProduct(formik.values.duttyCost / itemCount);
    setTptPerProduct(formik.values.tptCost / itemCount);
    setSaberPerProduct(formik.values.saberCost / itemCount);
    setOtherPerProduct(formik.values.otherCost / itemCount);
    setTotalPerProduct(land / itemCount);
  }, [formik.values]);

  function getData(prId,vendorId) {
console.log("vendor",vendorId);
    axiosInstance.get(`/supplier-quotations/calculation/${prId}/${vendorId}`).then((res) => {
      setCreateNew({
        duttyCost: res?.data?.data?.duttyCost || 0,
        tptCost: res?.data?.data?.tptCost || 0,
        landedCost: res?.data?.data?.landedCost || 0,
        otherCost: res?.data?.data?.otherCost || 0,
        saberCost: res?.data?.data?.saberCost || 0,
        itemCount: res?.data?.data?.itemCount || 0,
      });
    });
  }
  // useEffect(() => {
  //   getData();
  // }, []);


  function saveProducts(){
    axiosInstance.put(`/supplier-quotations/quot-compare/select-quotes/${viewqoutesId}`,{'products':finalProId}).then(res=>{
      if (res.data?.status === true) {
        getViewQuotes()
        toast.open({
          type: "success",
          message: "Success",
          description: res.data?.msg || "Created Successfully.",
        });
      } else if (res.data?.status === false && res.data?.error) {
        toast.open({
          type: "error",
          message: "Error",
          description: res.data.error || "Network error.",
        });
      } else {
        toast.open({
          type: "warning",
          message: "Warning",
          description: res.data?.msg || "Warning.",
        });
      }
    }).catch(err=>{
      toast.open({
        type: "error",
        message: "Error",
        description: "Network error.",
      });
    })
    console.log("final",finalProId);
  }

  return (
    <div className="w-full h-full px-4 py-2 overflow-hidden">
      <div className="w-full h-[46px] flex justify-between items-center">
        <Breadcrumb
          items={[
            // {
            //     name: "Procurement"
            // },
            {
              name: "YPR",
              link: "/procurement/purchase-requests/",
            },
            {
              name: "View Quotes",
            },
          ]}
        />
        <div className="flex gap-2">
          <BackButton />
        </div>
      </div>
      <div className="w-full overflow-x-auto max-w-full bg-white h-[calc(100vh-140px)] rounded-lg">
        <div className=" w-[calc(100vw-250px)] h-[calc(100vh-200px)] overflow-x-scroll">
   
      {
     (isLoading==true  || startLoading==true)?(
      // <div className="w-full h-full">
 <>
         <div className="space-y-4 w-full">
            {[...Array(5)].map((_, index) => (
              <div key={index} className="animate-pulse flex space-x-4">
                <div className="rounded-full bg-slate-200 h-10 w-10"></div>
                <div className="flex-1 space-y-6 py-1">
                  <div className="h-4 bg-slate-200 rounded"></div>
                  <div className="space-y-3">
                    <div className="grid grid-cols-3 gap-4">
                      <div className="h-4 bg-slate-200 rounded col-span-2"></div>
                      <div className="h-4 bg-slate-200 rounded col-span-1"></div>
                    </div>
                    <div className="h-4 bg-slate-200 rounded"></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
        // </div>
     )
     :
     (
            <div className={ supplierList?.length>1?`w-[calc((100vw-100px)*2)]`:`w-[calc(100vw-250px)]`}>
          <table className="table-auto relative w-full border-collapse border border-slate-300">
            <thead className="bg-baseColor-primary sticky top-0 text-white text-xs 2xl:text-sm">
              <tr>
                <th className="w-[80px] py-2">Sl. No.</th>
                <th className="w-[300px] text-left py-2">Product Name</th>
                <th className="w-[150px] py-2">Unit</th>
                <th className="w-[150px] py-2">Quantity</th>
                {dynamicHeaders}
              </tr>
            </thead>
            <tbody className="">
              {data.map((item, index) => (
                <tr
                  key={index}
                  className="odd:bg-white even:bg-slate-50  text-xs 2xl:text-sm border-collapse border border-slate-300"
                >
                  <td className="py-2 text-center">{index + 1}</td>
                  <td className="py-2 text-left">{item.name}</td>
                  <td className="py-2 text-center">{item.unit}</td>
                  <td className="py-2 text-center">{item.requestedQty}</td>

                  {supplierList.map((sub, index) => {
                    const supplier = stateValue.find(
                      (i) => i.productId === item.id && i.vendorId === sub.id
                    );
                    if (supplier) {
                      return (
                        <React.Fragment key={`dynamic-cell-${index}-${index}`}>
                          <td
                            className={`py-2 text-center border-collapse border  border-slate-300`}
                          >
                            <input
                              type="checkbox"
                              checked={supplier.isSelected}
                              onChange={(e) =>
                                handleChange(
                                  e,
                                  supplier.vendorId,
                                  supplier.productId
                                )
                              }
                            />
                          </td>
                          <td
                            className={`py-2 text-center border-collapse border  border-slate-300`}
                          >
                            {supplier.currencyLabel || "-"}
                          </td>
                          <td
                            className={`py-2 text-center border-collapse border border-slate-300`}
                          >
                            {    <NuAmount
                                value={
                                  supplier?.unitPrice||0
                                }
                              /> || "-"}
                          </td>

                          <td
                            className={`py-2 text-center border-collapse border ${
                              supplier.isLow == true && supplier.isHigh == true
                                ? "bg-green-100"
                                : supplier.isHigh == true
                                ? "bg-red-100"
                                : supplier.isLow == true
                                ? "bg-green-100"
                                : ""
                            } border-slate-300`}
                          >
                            <div
                              className=" w-full h-full"
                              // onClick={() => setIsModalOpen(true)}
                            >
                              <NuAmount
                                value={
                                  supplier?.sellingPrice||0
                                }
                              />
                            </div>
                          </td>
                          <td
                            className={`py-2 text-center border-collapse border  border-slate-300`}
                          >
                            <div
                              className=" w-full h-full"
                              // onClick={() => setIsModalOpen(true)}
                            >
                              {supplier.quantity || 0}
                            </div>
                          </td>
                          <td
                            className={`py-2 text-center border-collapse border  border-slate-300`}
                          >
                            <div
                              className=" w-full h-full"
                              // onClick={() => setIsModalOpen(true)}
                            >
                              <NuAmount value={supplier?.vat || 0} />
                            </div>
                          </td>
                          <td
                            className={`py-2 text-center border-collapse border  border-slate-300`}
                          >
                            <div
                              className=" w-full h-full cursor-pointer"
                              onClick={() => {
                                setMarginValue(supplier.margin);
                                setMarginChange(supplier.margin);
                                setIsMarginModalOpen(true);
                              }}
                            >
                              {<NuAmount value={supplier.gross || 0} /> || "-"}
                            </div>
                          </td>
                          <td
                            className={`py-2 text-center border-collapse border  border-slate-300`}
                          >
                            <div
                              className=" w-full h-full"
                              // onClick={() => setIsModalOpen(true)}
                            >
                              <NuAmount
                                value={
                                  AddTotalPrice(
                                    supplier.quantity,
                                    supplier.sellingPrice,
                                  ) || 0
                                }
                              />
                            </div>
                          </td>
                        </React.Fragment>
                      );
                    } else {
                      return (
                        <React.Fragment key={`dynamic-cell-${index}-${index}`}>
                          <td className="border-collapse border border-slate-300"></td>
                          <td className="border-collapse border border-slate-300"></td>
                          <td className="border-collapse border border-slate-300"></td>
                          <td className="border-collapse border border-slate-300"></td>
                          <td className="border-collapse border border-slate-300"></td>
                          <td className="border-collapse border border-slate-300"></td>
                          <td className="border-collapse border border-slate-300"></td>
                          <td className="border-collapse border border-slate-300"></td>
                        </React.Fragment>
                      );
                    }
                  })}
                </tr>
              ))}
            </tbody>
          </table>
          </div>
     )}
        </div>
        <div className=" w-full flex items-center px-4 h-[60px]">
          <div className=" w-[calc(84vw-128px)] flex items-center gap-2">
            <TextMediumXS content="Notes : " />
            <div className=" flex gap-3 flex-wrap">
              {/* <TextXS content="You can edit Margin by Clicking Margin Column ," /> */}
              <TextXS content="You can edit Landing Cost by Clicking  Landing Cost Column , " />
              <span className=" flex gap-1 items-center">
                <div className=" w-4 h-4 rounded-full bg-green-100"></div>
                <TextXS content=" color is show the Lowest Price , " />
              </span>

              <span className=" flex gap-1 items-center">
                <div className=" w-4 h-4 rounded-full bg-red-100"></div>
                <TextXS content=" color is show the Highest Price. " />
              </span>
            </div>
          </div>
          <div className=" w-56 flex gap-3 ">
            <CreateButton label="Save" handleClick={saveProducts} svg={false} />
            <CreateButton label="Create Quotes" svg={false} />
          </div>
        </div>
      </div>
      <ModalContainer
        visiable={isModalOpen}
        title="Added Cost"
        content={
          <>
            <div className=" w-[40vw]">
              <div className=" px-2 my-2 w-full flex justify-center">
                {/* <div className=" w-2/5">
                  <TextMediumSM content={"Duty  : "} />
                </div> */}
                <div className=" w-3/5">
                  <NuInput
                    type="number"
                    label="Duty"
                    // value={duty}
                    formik={formik}
                    name="duttyCost"
                    // onChange={(e)=>setDuty(e.target.value)}
                    className=" w-full py-1 px-3 border rounded-lg outline-none "
                  />
                </div>
              </div>
              <div className=" px-2 my-2 w-full flex justify-center">
                {/* <div className=" w-2/5">
                  <TextMediumSM content={"TPT  : "} />
                </div> */}
                <div className=" w-3/5">
                  <NuInput
                    type="number"
                    label="TPT"
                    className=" w-full py-1 px-3 border rounded-lg outline-none "
                    // value={tpt}
                    formik={formik}
                    name="tptCost"
                    // onChange={(e)=>setTpt(e.target.value)}
                  />
                </div>
              </div>
              <div className=" px-2 my-2 w-full flex justify-center">
                {/* <div className=" w-2/5">
                  <TextMediumSM content={"SABER  : "} />
                </div> */}
                <div className=" w-3/5">
                  <NuInput
                    type="number"
                    label="SABER"
                    className=" w-full py-1 px-3 border rounded-lg outline-none "
                    // value={saber}
                    formik={formik}
                    name="saberCost"
                  />
                </div>
              </div>

              <div className=" px-2 my-2 w-full flex justify-center">
                {/* <div className=" w-2/5">
                  <TextMediumSM content={"Others  : "} />
                </div> */}
                <div className=" w-3/5">
                  <NuInput
                    type="number"
                    label="Others"
                    className=" w-full py-1 px-3 border rounded-lg outline-none "
                    // value={other}
                    formik={formik}
                    name="otherCost"
                    // onChange={(e)=>setOther(e.target.value)}
                  />
                </div>
              </div>

              <div className=" px-2 my-2 w-full flex justify-center">
                <div className=" w-2/5">
                  <TextMediumSM content={"Total LANDED COST : "} />
                </div>
                <div className=" w-3/5 flex justify-end">{land}</div>
              </div>
              <div className=" px-2 my-2 w-full flex justify-center">
                <div className=" w-2/5">
                  <TextMediumSM content={"Total Product : "} />
                </div>
                <div className=" w-3/5 flex justify-end">
                  {formik.values.itemCount}
                </div>
              </div>
              <div className=" w-[95%] mx-auto h-[1px] bg-slate-300 my-6"></div>
              <div className=" px-2 my-2 w-full flex justify-center">
                <div className=" w-2/5">
                  <TextMediumSM content={"Duty Per Product : "} />
                </div>
                <div className=" w-3/5 flex justify-end">
                  <NuAmount value={dutyPerProduct || 0} />
                </div>
              </div>
              <div className=" px-2 my-2 w-full flex justify-center">
                <div className=" w-2/5">
                  <TextMediumSM content={"TDT Per Product : "} />
                </div>
                <div className=" w-3/5 flex justify-end">
                  <NuAmount value={tptPerProduct || 0} />
                </div>
              </div>
              <div className=" px-2 my-2 w-full flex justify-center">
                <div className=" w-2/5">
                  <TextMediumSM content={"SABER Per Product : "} />
                </div>
                <div className=" w-3/5 flex justify-end">
                  <NuAmount value={saberPerProduct || 0} />
                </div>
              </div>
              <div className=" px-2 my-2 w-full flex justify-center">
                <div className=" w-2/5">
                  <TextMediumSM content={"Other Per Product : "} />
                </div>
                <div className=" w-3/5 flex justify-end">
                  <NuAmount value={otherPerProduct || 0} />
                </div>
              </div>
              <div className=" px-2 my-2 w-full flex justify-center">
                <div className=" w-2/5">
                  <TextMediumSM content={"LANDED COST Per Product : "} />
                </div>
                <div className=" w-3/5 flex justify-end">
                  <NuAmount value={totalPerProduct || 0} />
                </div>
              </div>
            </div>
          </>
        }
        handleClick={() => {
          formik.submitForm();
          setIsModalOpen(false);
        }}
        closeModal={() => setIsModalOpen(false)}
      />

      <ModalContainer
        visiable={isMarginModalOpen}
        title="Change Margin (%)"
        hasContent={true}
        content={
          <div>
            <input
              className=" border  px-3 py-1 rounded-lg w-full border-slate-400 text-xs 2xl:text-sm"
              type="number"
              value={marginChange}
              onChange={(e) => setMarginChange(e.target.value)}
            />
            {/* {marginValue} */}
          </div>
        }
        handleClick={MarginCompleted}
        closeModal={() => setIsMarginModalOpen(false)}
      />
    </div>
  );
}

export default ViewQuotes;
