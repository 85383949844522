import React, { useEffect, useState } from 'react'
import { BackButton, CancelButton, CreateButton, EditButton } from '../../../../Components/Utils/Buttons';
import { Link,useLocation ,useParams} from 'react-router-dom';
import { NormalHeading } from '../../../../Components/Utils/Heading';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import axiosInstance from '../../../../Services/axiosCommon';
import PdfLinkInComing from './Pdf/PdfLinkInComing';
import NuDate from '../../../../Components/Utils/NuDate';
import ViewPdf from './ViewPdf';
import ApprovalComponent from '../../../../Components/Utils/approvalComponent';
import InVoInPdfOthers from '../../ViewPdfTemplate/InvoInPdfOthers';
import InVoINPdfNu from '../../ViewPdfTemplate/InvoInPdfNu';
import PDFViewer from '../../../../Components/Utils/PDFViewer';
import NuAmount from '../../../../Components/Utils/NuAmount';

function ViewInvoiceIncoming() {
    const { incomingViewId } = useParams();
  const location = useLocation();
  const [data,setData] = useState(location.state.data);
  const [polist, setPolist] = useState([]);
  const [showPdfComponent, setShowPdfComponent] = useState(false);

  const getPo = () => {
    axiosInstance.
    get(`/PO/po-products/${data.poId}`)
    .then((res) => {
      if (res?.data?.data) {
        setPolist(res.data.data);
        data.productList = res.data.data;

      }
    }).catch((err)=>{
      console.log(err);
    })
  };

  useEffect(() => {
    getPo();
  }, [data.poId]);

  const handlePdfIconClick = () => {
    setShowPdfComponent(true);
  };
  const closeFilterDialog = () => {
    setShowPdfComponent(false);
  };
  
  const id = incomingViewId; 
  const getUrl = `/invoices-incoming/get-status`;
  const putUrl = `/invoices-incoming/edit-status`; 
  const statusUrl = '/invoices-incoming/all-status';

  const [taxAmount, setTaxAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);


  useEffect(() => {
    const amount = parseFloat(data?.amount || 0); // Get amount from data
    const tax = parseFloat(data?.tax || 0); // Get tax from data
    
    // Calculate tax amount and total amount
    const calculatedTaxAmount = ((amount * tax) / 100).toFixed(2);
    const calculatedTotalAmount = (parseFloat(amount) + parseFloat(calculatedTaxAmount)).toFixed(2);
    
    // Update state
    setTaxAmount(calculatedTaxAmount);
    setTotalAmount(calculatedTotalAmount);
    
  }, [data?.amount, data?.tax]); 


  const downloadPDF = async () => {
    try {
        const response = await axiosInstance.get(`/invoices-incoming/download-pdf/${incomingViewId}`, {
            responseType: 'blob' // Important to specify the response type as blob
        });

        // Create a Blob from the response
        const pdfBlob = new Blob([response.data], { type: 'application/pdf' });

        // Create a link element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(pdfBlob);
        link.download = `${data.ref}.pdf`; // The filename you want for the downloaded file

        // Append the link to the body (required for Firefox)
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);

    } catch (error) {
        console.error('Error downloading the PDF:', error);
    }
};

const [viewPDF,setViewPDF] = useState(null);

const ViewPDF = async () => {
  try {
      const response = await axiosInstance.get(`/invoices-incoming/download-pdf/${incomingViewId}`, {
          responseType: 'blob' // Important to specify the response type as blob
      });

      // Create a Blob from the response
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });

      // Create a link element
      let Viewlink = window.URL.createObjectURL(pdfBlob);
      setViewPDF(Viewlink);

  } catch (error) {
      console.error('Error downloading the PDF:', error);
  }
};

useEffect(() => {
  ViewPDF()
},[])

return (
  <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between ">
      <Breadcrumb 
        items={[
            {
                name:"Invoice-Incoming",
                link:"/sales/invoice_incoming"

            },
            {
                name:"View"
            }
        ]}
        />
      <div className=" flex gap-2">
        <Link to={'/sales/invoice_incoming'}><BackButton link={true}/></Link>
        <CreateButton svg={false} handleClick={downloadPDF} label="Download as PDF" />
        {/* <PdfLinkInComing
            data={data}
            fileName={`Invoice_InComing_${data.ref}_${NuDate({
              value: Date(),
              format: "DD-MMM-YYY",
            })}.pdf`}
          /> */}
        <Link to={`/sales/invoice_incoming/edit/${incomingViewId}`} 
        state={{data: data, from:`/sales/invoice_incoming/view/${incomingViewId}`}}
        ><EditButton height='8'/></Link>
      </div>
    </div> 
    <div className="w-full mx-auto h-[calc(100vh-125px)]  rounded-md overflow-hidden p-2   flex gap-2 text-[#111537]">
        <div className="w-[70%]  bg-white rounded-lg drop-shadow-lg py-1 overflow-hidden ">
        <div className="w-[98%]  bg-white px-2 mx-2 border border-gray py-2 rounded-lg flex justify-between items-center  z-10">  
        <div className="grid  items-center ">
        <div className="flex text-[14px] mb-1 text-[#111537] font-semibold ">
            <p className="">IN : {data.ref}</p>
            {/* <p className=""> : {data?.description || 'None'}</p> */}
            </div>  
            <div className="flex gap-4">
            <p className="text-xs text-[#111537]">Date : {data?.date ? (
    <NuDate value={data?.date} format="DD-MMM-YYYY, hh:mm A" /> 
  ) : (
    "N/A"
  )}
         </p>
         </div>
         </div>
         {/* <p className="text-xs text-[#111537]"> Created By : {data.preparedByName || 'None'}</p> */}
         {/* <p className="bg-[#579BB1] text-white items-center rounded-md text-xs p-[2px]"> {data.status || 'None'}</p> */}
         <div className="border rounded-md mr-3 border-[#579BB1] text-xs inline-flex w-auto  flex-shrink-0  justify-center">
            <p className="  bg-white  text-black  p-1 rounded-l-md  ">
              Status  </p><p className="bg-[#579BB1] text-white p-1 items-center rounded-r-md"> {data.status || 'None'}</p>
             
              </div>
        
         </div>
    {/* <div className="flex justify-between items-center">
   
    <div className='flex gap-3 items-center'>
      <NormalHeading title={"Invoice Incoming"} />  : <p className="text-xs grid pt-5"> {data.ref} 
              <span className="font-light text-[11px] ">{data?.date ? (
                  <NuDate value={data?.date} format="DD-MMM-YYYY" />
                ) : (
                  "N/A"
                )}</span> </p>   </div>
                 <p className=" p-2  bg-[#F0DE36] text-white items-center rounded-md text-xs h-8 ">
                {data.status || 'None'}
              </p></div> */}
      <div className="h-[calc(100vh-200px)] overflow-y-auto p-2 pb-8 ">

<div className=" w-full flex flex-wrap  px-2 border border-gary mt-2  rounded-lg mb-2">

          <div className="w-full grid grid-cols-2 gap-x-8 ">
            {/* <div className='w-full flex px-2'>
                <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Supplier  </p>
                <p className='w-[65%] pl-5 py-2 text-xs text-[#606279]'> : {data.supplierName}</p>
              </div> */}
              {/* <div className='w-full flex px-2'>
                <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Invoice Date</p>
                <p className='w-[65%] py-2 pl-5  text-xs text-[#606279]'> : {data.date}</p>
              </div> */}
              {/* <div className='w-full flex px-2'>
                <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Purchase Order</p>
                <p className='w-[65%] py-2 pl-5 text-xs text-[#606279]'> : {data.poref}</p>
              </div> */}
               <div className='w-full flex px-2'>
                <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Amount</p>
                <p className='w-[65%] py-2 pl-5 text-xs text-[#606279]'> : <NuAmount value={data.amount}/></p>
              </div>

               <div className='w-full flex px-2'>
                <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Tax</p>
                <p className='w-[65%] py-2 pl-5 text-xs text-[#606279]'> : {data.tax}</p>
              </div> 
              <div className='w-full flex px-2'>
        <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Tax Amount</p>
        <p className='w-[65%] py-2 pl-5 text-xs text-[#606279]'> : {taxAmount}</p>
      </div> 
      <div className='w-full flex px-2'>
        <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Total Amount</p>
        <p className='w-[65%] py-2 pl-5 text-xs text-[#606279]'> : <NuAmount value={totalAmount}/></p>
      </div>
               
               <div className='w-full flex px-2'>
                <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Payment DueDate</p>
                <p className='w-[65%] py-2 pl-5  text-xs text-[#606279]'> : {data.paymentDueDate? (
                  <NuDate value={data?.paymentDueDate} format="DD-MMM-YYYY" />
                ) : (
                  "N/A"
                )}</p>
              </div> 
               <div className='w-full flex px-2'>
                <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Payment Releasedon</p>
                <p className='w-[65%] py-2 pl-5  text-xs text-[#606279]'> : {data.paymentReleasedOn? (
                  <NuDate value={data?.paymentReleasedOn} format="DD-MMM-YYYY" />
                ) : (
                  "N/A"
                )}</p>
              </div>
              <div className='w-full flex px-2'>
                <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Mode of Payment</p>
                <p className='w-[65%] py-2 pl-5 text-xs text-[#606279]'> : {data.modeOfPayment}</p>
              </div>
                   
             {/* <div className='w-full flex px-2'>
                <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Division</p>
                <p className='w-[65%] py-2 pl-5 text-xs  text-[#606279]'> : {data.division}</p>
              </div> */}
               {/* <div className='w-full flex px-2'>
                <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Approval Status</p>
                <p className='w-[65%] py-2 pl-5 text-xs text-[#606279]'> : {data.approvalStatus}</p>
              </div>  */}
              {/* <div className='w-full flex px-2'>
                <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Status</p>
                <p className='w-[65%] py-2 pl-5 text-xs text-[#606279]'> : {data.status}</p>
              </div>  */}
               {/* <div className='w-full flex px-2'>
                <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Comments</p>
                <p className='w-[65%] py-2 pl-5 text-xs text-[#606279]'> : {data.comments}</p>
              </div>  */}
             
          </div>
         
        </div>
        <div className="flex gap-4 mt-4">
          <div className="w-[50%] ">
        <p className="  text-xs font-medium text-[#111537] p-1">
              Comments
            </p>
        <div className="  border border-gary  h-28 overflow-y-scroll rounded-lg items-center">
           
            <p className="  text-xs p-2 break-words text-[#606279]">
              {data.comments || "-"}
            </p>
          </div>
          </div>
          </div>
        {/* <div onClick={handlePdfIconClick} className="cursor-pointer m-2">
          <svg
            width="30"
            height="30"
            fill="#048178"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 144-208 0c-35.3 0-64 28.7-64 64l0 144-48 0c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z" />
          </svg>
        </div> */}
      
        {/* <div className="w-full h-0.5 opacity-30 bg-[#C4C2C2] my-6 mx-auto"></div> */}

        <div className="w-full bg-white  border border-gary  mt-4 rounded-lg ">
        <div className="w-full py-2 bg-baseColor-primary flex justify-between rounded-lg px-2">
            <p className="w-[5%]  text-left  text-white text-xs font-medium">S.N. </p>

            <p className="w-[60%]  text-left text-white text-xs font-medium">Product </p>
            <p className="w-[10%] text-center text-white text-xs font-medium">Unit</p>
   
            <p className="w-[20%] text-center text-white text-xs font-medium"> Ordered Qty</p>
            <p className="w-[15%] text-center text-white text-xs font-medium"> Price</p>
            
            <p className="w-[15%] text-center text-white text-xs font-medium"> Total</p>
          
          </div>
          {polist.map((List, index) => {
            return (
              <div key={index} className="w-full py-2 flex justify-between odd:bg-white even:bg-slate-100 px-2">
                
                 <p className="w-[5%]  text-left   text-xs font-medium text-[#111537] py-1">
                                    {index + 1}
                                </p>
                <p className="w-[60%]  text-left  text-xs font-medium text-[#111537] py-1">
                  {List?.product_name}
                </p>
                <p className="w-[10%] text-center  text-xs font-medium text-[#111537] py-1">
                  {List?.unit}
                </p>
                
               
                <p className="w-[20%] text-center  text-xs font-medium text-[#111537] py-1">
                  {List?.orderedQty}
                </p>
                <p className="w-[15%] text-center  text-xs font-medium text-[#111537] py-1">
  {Number(List?.price)?.toLocaleString(undefined, { 
    minimumFractionDigits: 2, 
    maximumFractionDigits: 2 
  }) || "0.00"}
</p>

                <p className=" w-[15%] text-center  text-xs font-medium text-[#111537] py-1">
                  {(List.price * List.orderedQty ).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} 
                  
                  </p>
              </div>
            );
          })}
        </div>
</div>
</div>
<div className="w-[30%] bg-white rounded-lg drop-shadow-lg py-1">
        <div className="h-[98%] overflow-y-scroll p-2 ">
        {/* <ApprovalComponent id={id} getUrl={getUrl} putUrl={putUrl} statusUrl={statusUrl} /> */}

         {/* <div className=" border border-gary   py-2 px-1 rounded-lg">
         <p className="text-xs text-[#111537] font-medium">Approval 
          <span>:{data.approvalStatus || 'None'}</span>
         </p>
          <div className="flex gap-4 mt-2 justify-around">
          <p className=" px-2 py-2 bg-green-500 hover:cursor-pointer text-white items-center rounded-md text-xs text-center  h-7  ">
          Verify
              </p>
              <p className=" px-2 py-2 bg-red-500 hover:cursor-pointer text-white items-center rounded-md text-xs text-center  h-7  ">
              Decline
              </p>
          </div>
         </div> */}
         <ApprovalComponent id={id} getUrl={getUrl} putUrl={putUrl} statusUrl={statusUrl} refSetData={setData} data={data} type={"IN"} preUrlName={"invoices-incoming"}/>

          <div className="w-full grid  border border-gary mt-4  rounded-lg">
          <div className="bg-baseColor-primary  p-2 rounded-t-lg">
            <p className="text-xs text-white font-medium">Incoming Detail</p>

            </div>{/* <p className="text-xs text-[#111537] font-medium">Incoming Detail</p> */}

          <div className='w-full flex px-2 justify-between '>
                <p className='w-[35%] py-2 text-xs font-medium text-[#111537]'>Supplier  </p>
                <p className='w-[65%]  py-2 text-xs text-[#606279] truncate text-wrap'>: {data.supplierName}</p>
              </div>
              <div className='w-full flex px-2 justify-between '>
                <p className='w-[35%] py-2 text-xs font-medium text-[#111537]'>Email  </p>
                <p className='w-[65%]  py-2 text-xs text-[#606279] truncate'>: {data.supplierEmail}</p>
              </div>
              <div className='w-full flex px-2 justify-between'>
                <p className='w-[35%] py-2 text-xs font-medium text-[#111537]'>PO</p>
                <p className='w-[65%] py-2  text-xs text-[#606279] truncate'>: {data.poref}</p>
              </div>
              <div className="w-full flex px-2 justify-between">
              <p className="w-[35%] py-2 text-xs font-medium text-[#111537]"> 
               YPR
              </p>
              <p className="w-[65%] py-2 text-xs  text-[#606279] truncate">: {data.prRef}
              </p>
            </div>
           </div>
        
           <div className="w-full grid border border-gary mt-4  rounded-lg ">
           <p className="text-xs text-white font-medium bg-baseColor-primary  p-2 rounded-t-lg">Documents </p>
           <div className=" w-full flex flex-wrap overflow-y-scroll h-16">
          
           {/* <div onClick={handlePdfIconClick} className="cursor-pointer m-2   ">
          <svg
            width="30"
            height="30"
            fill="#048178"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 144-208 0c-35.3 0-64 28.7-64 64l0 144-48 0c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z" />
          </svg>
        </div> */}

<div onClick={handlePdfIconClick} className="cursor-pointer m-1 w-auto h-[25px] flex items-center  justify-between  rounded p-[2px] border ">
          <svg
            width="20"
            height="20"
            fill="#048178"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 144-208 0c-35.3 0-64 28.7-64 64l0 144-48 0c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z" />
          </svg>
          <p className=' w-auto text-[10px]'>{data?.ref}</p>
        </div>

        </div>
        </div>
        
          </div>
        </div>
    </div>
    {showPdfComponent && (
  <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
  <div className="animate-scale-in relative w-[99%] h-[99%] bg-white py-2 px-4 rounded-md shadow-lg overflow-y-scroll ">
    <button
             className="absolute top-2 right-2 text-white bg-red-600"
             onClick={() => setShowPdfComponent(false)}
       >
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      <PDFViewer pdfFile={viewPDF}/>

      {/* <InVoInPdfOthers data={data} /> */}
      {/* <InVoINPdfNu/> */}
      {/* <div className="flex justify-end gap-2 ">
                <CancelButton handleClick={closeFilterDialog} />
                <SubmitButton name="Apply" /> */}
              {/* </div> */}
    </div>
  </div>
)}
  </div>
)
};
export default ViewInvoiceIncoming