import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import { CancelButton, FilterButton, SubmitButton } from "../../../../Components/Utils/Buttons";
import SearchBar from "../../../../Components/Utils/SearchBar";
import ViewIcon from "../../../../Components/Icons/ViewIcon";
import { TextBase } from "../../../../Components/Text/NormalText";
import { TextMediumBase, TextMediumSM, TextMediumXL, TextMediumXS } from "../../../../Components/Text/MediumText";
import UseGetDataSearch from "../../../../Components/Hooks/useFetchDataBySearch";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Pagination from "../../../../Components/Utils/Pagenation";
import { useFormik } from "formik";
import * as yup from 'yup';
import FormikSelectTwo from "../../../../Components/Utils/Select";
import UseCatSubCatDropDown from "../../../../Components/Hooks/useCategoryDropDown";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import NuDate from "../../../../Components/Utils/NuDate";
import NuAmount from "../../../../Components/Utils/NuAmount";
import useFetchMDIndex from "../../../../Components/Hooks/useFetchMDIndex";

const ProductComparisonIndex = () => {
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(localStorage.getItem("perPageView")||20)
  const [filter, setFilter] = useState(false);
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const [filterProps, setFilterProps] = useState({
    category: "",
    subCategory: "",
    underPromotion: "",
    unit: "",
    manufacturer: "",
    brand: ""
  });
  const [params, setParams] = useState({
    page: page,
    per_page: pageCount,
    search:"",
    category:"",
    subCategory:"",
    underPromotion:"",
    unit:"",
    manufacturer:"",
    brand:""
  });
  const clearFilter = ()=> {
    setParams({...params, category:"",
    subCategory:"",
    brand:"",
    manufacturer:"",
    underPromotion:"",
    unit:""
  })
  formik.values.category=""
  formik.values.subCategory=""
  formik.values.brand=""
  formik.values.manufacturer=""
  formik.values.underPromotion=""
  formik.values.unit=""


      setFilter(false)
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterProps,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      console.log("ffff",value);
      setParams({...params,category:value.category, subCategory:value.subCategory, underPromotion:value?.underPromotion, unit:value?.unit, brand:value?.brand, manufacturer:value?.manufacturer})
      closeFilterDialog();
      setFilter(true)
      setFilterProps({
        category: "",
        subCategory: "",
        underPromotion: "",
        unit: "",
        manufacturer: "",
        brand: ""
      });
    }
  })
  const closeFilterDialog = () => {
    setShowFilterDialog(false);
  };
  // const clearFilter = ()=> {
  //   setParams({...params,category:"", subCategory:"", underPromotion:"", unit:"", brand:"", manufacturer:""})
  //     setFilter(false)
  // }
  const { data, totalCount, getData } = UseGetDataSearch( '/supplier-quotations/price-listing',params);
  const {value:Unit} = useFetchMDIndex(`/dropdown/dropdown-details/`,'Unit_item',showFilterDialog)
  const {value:brand} = useFetchMDIndex(`/dropdown/dropdown-details/`,'brand_item',showFilterDialog)
  const {value:Manufacture} = useFetchMDIndex(`/dropdown/dropdown-details/`,'Manufacture_item',showFilterDialog)
  const { category: itemsCategory, subCategory: itemsSubCategory, getCategory  } = UseCatSubCatDropDown('/category/ItemCategory', "ItemCategory", formik?.values?.category);
  console.log("ddddddd",data);
  useEffect(() => {
    setParams({...params,page:page,per_page:pageCount})
  }, [page,pageCount]);

    return(
    <div className='w-full h-full px-4 py-2'>
      <div className='w-full flex justify-between items-center'>
        <Breadcrumb
          items={[
            { name: "Procurement" },

            { name: "Product Comparison" },
          ]} />
        <div className=' flex gap-2'>
          <SearchBar handleChange={(e) => setParams({...params,search:e.target.value})}/>
          <FilterButton handleClick={()=>setShowFilterDialog(true)} />
          {filter && <FilterButton  handleClick={()=>clearFilter()} label='Clear'/>}
        </div>
      </div>
      <div className="w-full h-[calc(100vh-180px)] bg-white mt-3 mx-auto  p-2 rounded-md overflow-y-scroll">
      <table className=' w-full max-h-[calc(100vh-200px)] overflow-y-auto mt-2 '>
       <thead>
       <tr className='border-b border-[#C4C2C2] text-[#4F4768]'>
          <th className=' w-[30%] text-left text-xs font-semibold py-2 px-4'>Product Name</th>
          <th className=' w-[20%] text-left text-xs font-semibold py-2 px-4'>Supplier1</th>
          <th className=' w-[20%] text-left text-xs font-semibold py-2 px-4'>Supplier2</th>
          <th className=' w-[20%] text-left text-xs font-semibold py-2 px-4'>Supplier3</th>
          <th className=' w-[10%] text-center text-xs font-semibold py-2 px-4'>More..</th>
        </tr>
       </thead>
        <tbody>
        {
          data && data.map((value, index) => {
            const prices = value?.quotes.map(sup => sup.price);
            const minPrice = Math.min(...prices);
            return (
              <tr className='hover:text-[#048178] hover:shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px] hover:scale-y-105 text-[#606279] ' >
              <td className=' w-[30%] py-2 text-left text-xs px-4'>
                {/* <TextMediumBase content={value?.productName}/> */}
                <p className="text-xs text-[#606279]"> <Link to={`/procurement/product-comparison/view/${value?.productId}`}>
                {value?.productName}</Link></p>
              </td>
              {value?.quotes == "" ?
              <>
                <td className=' w-[20%] py-2 text-xs px-4'>
                   -
                </td>
                <td className=' w-[20%] py-2 text-xs px-4'>
                  -
               </td>
               <td className=' w-[20%] py-2 text-xs px-4'>
                -
               </td>
               </>
              :
              value?.quotes?.slice(0, 3).map((sup,index)=>{
                console.log("TEST",sup);
                
                return(
                <td className=' w-[20%] py-2 text-xs px-4'>
                 <div className="w-full flex justify-start gap-2 p-1">
                    <div className="">
                    {/* <p className="text-[14px]  ">{sup?.price}<span className="pl-0.5 text-[8px] 2xl:text-[10px] text-gray-400">{`(${sup?.date && NuDate({value:sup?.date,format:"DD-MMM-YYYY"})})`}</span></p> */}
                    <p className={`text-[16px] ${sup?.price === minPrice && sup?.price !==0 ? 'text-green-600' : ''}`}>
                                  <p > 
                                    <NuAmount currency={sup?.currency} value={sup?.price||0} />
                                  {sup?.isRecent &&<span className=" text-red-600">*</span>}
                                  <span className="pl-0.5 text-[8px] 2xl:text-[10px] text-gray-400">
                                    {`(${sup?.date && NuDate({ value: sup?.date, format: "DD-MMM-YY" })})`}
                                  </span>
                                  </p>
                                </p>
                      <p className="text-xs text-gray-500">{sup?.vendorName}</p>
                      {/* <TextMediumSM content={sup?.vendorName} align="center"/> */}
                      {/* <TextMediumXS content={sup?.price} align="center"/>
                      <TextMediumXS content={sup?.date && NuDate({value:sup?.date,format:"DD-MM-YYYY"})} align="center"/> */}
                    </div>
                    <div className="flex justify-center items-center">
                      {sup?.count === 1 ?
                      null
                      :
                      <Link to={`/procurement/product-comparison/priceView/${value?.productId}/${sup?.vendorId}`}>
                        <div className="w-5 h-5 py-0.5 rounded-full bg-baseColor-primary cursor-pointer">
                           <TextMediumXS content={sup?.count} align="center" color="text-white"/>                             
                        </div>
                      </Link>
                      }
                    </div>
                 </div>
                </td>
                )
              })}
              {value?.quotes.length == 1 ? 
              <>
               <td className=' w-[20%] py-2 text-xs px-4'>
                -
               </td>
               <td className=' w-[20%] py-2 text-xs px-4'>
                -
               </td>
              </>
               :
               null
              }
              {value?.quotes.length == 2 ? 
              <td className=' w-[20%] py-2 text-xs px-4'>
                -
               </td>
               :
               null
              }
              <td className=' w-[10%] py-2 text-xs px-[4%]'>
                <Link to={`/procurement/product-comparison/view/${value?.productId}`}>
                <ViewIcon color='#048178'/>
                </Link>
              </td>
            </tr>
            )
          })
        }
       </tbody> 
      </table>
    </div>
    <div className="w-full h-[30px] rounded-b-md bg-white">
          <Pagination
            total={totalCount}
            pageIndex={page}
            setPageIndex={setPage}
            dataCount={pageCount}
            setDataCount={setPageCount}
          />
      </div>
      {showFilterDialog && (            
             <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
               <div className="animate-scale-in min-w-[40%] max-w-[80%] max-h-[80vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
                 <h2 className="text-xl mb-4">Filter product-Comparision</h2>
                 <form onSubmit={formik.handleSubmit}> 
                    <div className="flex flex-col mb-4">
                    {/* <FormikSelectTwo 
                        label="Under Promotion"
                        placeholder="Choose"
                        Options={[
                          {
                            value: "yes", label: "yes"
                          },
                          {
                            value: "no", label: "no"
                          }
          
                        ]}
                        formik={formik}
                        name="underPromotion"
                        width="w-full"
                      /> */}
                      <FormikSelectTwo 
                        label="Category"
                        placeholder="Choose"
                        Options={itemsCategory}
                        formik={formik}
                        name="category"
                        width="w-full"
                      />
                      <FormikSelectTwo 
                        label="Sub Category"
                        placeholder="Choose"
                        Options={itemsSubCategory}
                        formik={formik}
                        name="subCategory"
                        width="w-full"
                      />
                      <FormikSelectTwo 
                        label="Unit"
                        placeholder="Choose"
                        Options={Unit}
                        formik={formik}
                        name="unit"
                        width="w-full"
                      />
                      <FormikSelectTwo 
                        label="Brand"
                        placeholder="Choose"
                        Options={brand}
                        formik={formik}
                        name="brand"
                        width="w-full"
                      />
                      {/* <FormikSelectTwo 
                        label="Manufacturer"
                        placeholder="Choose"
                        Options={Manufacture}
                        formik={formik}
                        name="manufacturer"
                        width="w-full"
                      /> */}
                    </div>
                   <div className="flex justify-end gap-2">
                     <CancelButton handleClick={closeFilterDialog} />
                     <SubmitButton name='Apply' />
                   </div>
                  </form> 
               </div>
             </div>
           )}
    </div>
    )
}

export default ProductComparisonIndex;