import React, { useState, useEffect } from 'react';
import axiosInstance from '../../Services/axiosCommon';

const UseSupplierDropDownNew = (url,search) => {
    const [data, setDataSupplier] = useState([]);

    async function getData() {
        axiosInstance.get(url,{params:{search:search}}).then(res => {
            let data = res.data;
            let tempData = [];
            data.forEach((item) => {
                let tempObject = { value: item.id, label: item.name };
                tempData.push(tempObject);
            });
            res.data && setDataSupplier(tempData);
        }).catch(err => {
            console.log(err, "err");
        })
    }
    useEffect(() => {
        getData();
    }, [url,search]);

    return { data , setDataSupplier };
};

export default UseSupplierDropDownNew