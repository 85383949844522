import { TextMediumSM } from "../../../../../Components/Text/MediumText"
import ApprovalStatus from "./ApprovalStatus";
import BillingAddress from "./BillingAddress";
import DeliverTerms from "./DeliverTerms";
import DeliveryAddress from "./DeliveryAddress";
import PaymentStatus from "./PaymentStatus";
import PaymentTerms from "./PaymentTerms";
import Status from "./Status";




const PurchaseOrder = () =>{
    return(
        <>
        <TextMediumSM content="Purchase Order" />
        <div className='w-full flex flex-wrap justify-center md:justify-start  items-center overflow-y-scroll'>
         {/* <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <PaymentStatus/>
         </div> */}
         {/* <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <ApprovalStatus/>
         </div> */}
         {/* <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <BillingAddress/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <DeliveryAddress/>
         </div> */}
         {/* <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <DeliverTerms/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <PaymentTerms/>
         </div> */}
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <Status/>
         </div>
         </div>
         </>
    )
}

export default PurchaseOrder;