import React, { useEffect, useState } from "react";

export default function Pagination({
  pageIndex,
  setPageIndex,
  dataCount,
  setDataCount,
  total,
}) {
  const [totalPages, setTotalPages] = useState(0);
  const [dataPerView, setDataPerView] = useState(dataCount); // Default items per page

  useEffect(() => {
    if (total > 0 && dataCount > 0) {
      setTotalPages(Math.ceil(total / dataCount));
    }
  }, [total, dataCount]);

  const handleDataCountChange = (e) => {
    const value = parseInt(e.target.value, 10); // Convert value to an integer
    setDataCount(value);
    setDataPerView(value);
    setPageIndex(1); // Reset to the first page
    localStorage.setItem("perPageView", value);
  };

  return (
    <div className="flex items-center justify-between w-full h-full">
      {/* Items Per Page Selector */}
      <div className="w-5/12 md:w-3/12">
        <span className="mx-3 text-xs font-medium flex items-center gap-3">
          <span>View</span>
          <select
            className="text-xs border-none outline-none bg-slate-100 py-[2px] rounded-md"
            onChange={handleDataCountChange}
            value={dataPerView}
            id="page"
          >
            <option value={20}>20</option>
            <option value={40}>40</option>
            <option value={60}>60</option>
            <option value={80}>80</option>
            <option value={100}>100</option>
          </select>
          <span>: Per Page</span>
        </span>
      </div>

      {/* Pagination Controls */}
      <div className="flex justify-between items-center w-7/12 md:w-3/12 xl:w-[20%] md:ml-10 text-center text-xs">
        <p className="text-xs font-medium">
          Showing
          <span className="border border-pageborder rounded-md py-1 px-2 mx-1">
            {pageIndex || 1}
          </span>
          of {totalPages > 1 ? `${totalPages} Pages` : "1 Page"}
        </p>
        {/* Navigation Buttons */}
        <span
          className="text-sm font-bold cursor-pointer"
          onClick={() => setPageIndex(1)}
          title="First Page"
        >
          &#x226A;
        </span>
        <span
          className="text-sm font-bold cursor-pointer"
          onClick={() => setPageIndex(pageIndex > 1 ? pageIndex - 1 : 1)}
          title="Previous Page"
        >
          &#x3C;
        </span>
        <span
          className="text-sm font-bold cursor-pointer"
          onClick={() =>
            setPageIndex(
              totalPages > 0 ? (pageIndex < totalPages ? pageIndex + 1 : totalPages) : 1
            )
          }
          title="Next Page"
        >
          &#x3E;
        </span>
        <span
          className="text-sm font-bold cursor-pointer"
          onClick={() => setPageIndex(totalPages > 0 ? totalPages : 1)}
          title="Last Page"
        >
          &#x226B;
        </span>
      </div>
    </div>
  );
}
